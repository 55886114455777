<template>
    <div v-loading="loading">
        <h6>
            <a href="javascript:;" @click="updateUserSuccessful">Go to All Users</a>
        </h6>
        <el-dialog
        title="Delete User"
        :visible.sync="deleteUser"
        :width="dialogWith"
        center>
            <div style="text-align: center; display: block;">
                <div class="mb-3">Note that if you delete this user now you won'tbe able to retrieve it later</div>
            <el-button type="primary" @click.prevent="miniUpdateSubuser('delete')" class="myDialogBtn" style="margin-bottom: 10px">Yes Delete</el-button>
            <el-button @click.prevent="deleteUser = false"  class="myDialogBtn">No, Continue</el-button>
            </div>
        </el-dialog>
        <el-dialog
        :title="disableTitle"
        :visible.sync="disableUser"
        :width="dialogWith"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        center>
            <div style="text-align: center; display: block;">
                <div v-if="subuser.subuser_activated == 0" class="mb-3">Note that if you disable this user now you can still retrieve it later</div>
            <el-button type="primary" @click.prevent="miniUpdateSubuser('disable')" class="myDialogBtn" style="margin-bottom: 10px">{{ disableAccept }}</el-button>
            <el-button @click.prevent="stopDisableUserDiaolg"  class="myDialogBtn">No, Continue</el-button>
            </div>
        </el-dialog>
        <input @change.prevent="loadFileInput" ref="dp-input-ref" type="file" name="profilePicture" style="display:none">
        <div class="base-cover-inner-top">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="col-md-8 col-sm-12 col-xs-12 i-p-8" >
                    <div class="i-card">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="image-info-cover d-flex flex-wrap">
                                <div class="info-img-cover">
                                    <div class="dp-cover" @mouseover.prevent="showDPHover = true" 
                                    @mouseleave.prevent="showDPHover = false" @click.prevent="clickFileInput">
                                        <div class="i-profile-img absolute-position">
                                            <img class="i-profile-img"
                                            :src="subuser.photo_url">
                                        </div>
                                        <div v-if="showDPHover" class="i-profile-img absolute-position i-profile-img-onhover text-center">
                                            <span class="i-profile-img-onhover-icon">
                                                <i class="el-icon-picture-outline"></i>
                                                <!-- + -->
                                            </span>
                                        </div>
                                   </div>
                                    <div class="i-mt-20 disable-user-icon-cover-2">
                                        <!-- Edit user -->
                                        <i v-if="!editMode" @click.prevent="startEditMode" class="ut-menu-icon  el-icon-edit fs-20"></i> 
                                        <i v-else @click.prevent="stopEditMode" class="ut-menu-icon  el-icon-success fs-20"></i> &nbsp; 
                                        <!-- Delete user -->
                                        <i @click.prevent="deleteUser = true" class="ut-menu-icon  el-icon-delete fs-20"></i> &nbsp; 
                                        <!-- Disable user -->
                                        <el-switch
                                        :active-value="1"
                                        :inactive-value="0"
                                        active-color="#0AD688"
                                        slot="reference"
                                        v-model="subuser.subuser_activated"
                                        @change="disableUserDiaolg"
                                        ></el-switch>
                                    </div>
                                </div>
                                <div class="name-email-info-cover">
                                    <h3>{{ staticName}}</h3>
                                    <div class="i-mt-20">
                                        <p><small>Email</small> <br>
                                        <small><b>{{ staticEmail }}</b></small></p>
                                    </div>
                                </div>
                            </div>
                            <div class="user-edit-icon-cover">
                                <div class="i-mt-20 disable-user-icon-cover-1">
                                    <!-- Edit user -->
                                    <i v-if="!editMode" @click.prevent="startEditMode" class="ut-menu-icon el-icon-edit fs-20"></i>
                                    <i v-else @click.prevent="stopEditMode" class="ut-menu-icon el-icon-success fs-20"></i> &nbsp; 
                                    <!-- Delete user -->
                                    <i @click.prevent="deleteUser = true" class="ut-menu-icon el-icon-delete fs-20"></i> &nbsp; 
                                    <!-- Disable user -->
                                    <el-switch
                                    style="margin-left: 8px;"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#0AD688"
                                    slot="reference"
                                    v-model="subuser.subuser_activated"
                                    @change="disableUserDiaolg"
                                    ></el-switch>
                                </div>

                                <div class="i-mt-20">
                                    <p><small>Member Since</small> <br />
                                    <small><b>{{momentifyCreatedDate(subuza.created_at)}}</b></small></p>
                                    <div v-if="subuza.last_login.length < 1 ">
                                        <small @click.prevent="newSubuserPassword" class="smcol ut-cursor-pointer">Resend Password</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12 i-p-8">
                    <div class="lst-lst">
                        <div class="text-center last-activity">
                            <p><small>Last Activity</small> <br>
                            <small><b>{{momentifyLastActivity(subuza.last_activity)}}</b></small></p>
                        </div>
                        <div class="text-center last-login">
                            <p><small>Last Login</small> <br>
                            <small><b>{{subuza.last_login.length > 0 ? momentifyLastActivity(subuza.last_login[0].created_at) : "Hasn't logged in yet"}}</b></small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div ref="editForm" class="base-cover-inner-bottom">
            <div class="i-card">
                <h5 class="i-p-10">PROFILE INFORMATION</h5>
                <div class="d-flex flex-wrap w-100">
                    <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12 uc-input-cover">
                        <label for="">Name</label>
                        <div>
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.name">
                            <div v-else class="w-100 uc-input-info" >{{ subuser.name}}</div>
                        </div>
                        <div v-if="nameError" class="text-danger"> {{ nameError }}</div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">Last Name</label>
                        <div>
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.street_address">
                            <div v-else class="w-100 uc-input-info" >I am here nowx</div>
                        </div>
                    </div> -->
                    <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">Email</label>
                        <div>
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.email">
                            <div v-else class="w-100 uc-input-info" >{{ subuser.email}}</div>
                        </div>
                        <ul v-if="emailError.length > 0">
                            <li v-for="eErr in emailError" :key="eErr" class="text-danger">{{ eErr }}</li>
                        </ul>
                    </div>
                </div>


                <h5 class="i-p-10">CONTACT ADDRESS</h5>
                <div class="d-flex flex-wrap w-100">
                    <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12 uc-input-cover">
                        <label for="">Street Address</label>
                        <div >
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.street_address">
                            <div v-else class="w-100 uc-input-info" >{{ subuser.settings.street_address }}</div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">Apartment, Suite</label>
                        <div>
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.apartment_suite">
                            <div v-else class="w-100 uc-input-info" >{{ subuser.settings.apartment_suite }}</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">City</label>
                        <div>
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.city">
                            <div v-else class="w-100 uc-input-info" >{{ subuser.settings.city }}</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">Country</label>
                        <div>
                            <!-- <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.country"> -->

                            <country-select
                            v-if="editMode"
                            :top-country="subuser.settings.country"
                            class-name="w-100"
                            v-on:input="updateCountry"
                            ></country-select>
                            <!-- <country-select
                            v-if="editMode"
                            :top-country="subuser.settings.country"
                            class-name="w-100"
                            v-on:input=""
                            ></country-select> -->
                            <div v-else class="w-100 uc-input-info" >{{ subuser.settings.country }}</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">State/Province/Region</label>
                        <div>
                            <!-- <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.state"> -->
                            <state-select
                            v-if="editMode"
                            :country="iCountry"
                            :defaultRegion="subuser.settings.state"
                            class-name="w-100"
                            v-on:input="updateState">
                            <!-- <state-select
                            v-if="editMode"
                            :country="subuser.settings.country"
                            :defaultRegion="subuser.settings.state"
                            class-name="w-100"
                            v-on:input=""> -->
                            </state-select>
                            <div v-else class="w-100 uc-input-info" >{{ subuser.settings.state }}</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">Zip Code</label>
                        <div>
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.zip_code">
                            <div v-else class="w-100 uc-input-info" >{{ subuser.settings.zip_code }}</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">Phone Number</label>
                        <div>
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.phone" placeholder="+234 801 2345 678">
                            
                            <!-- <vue-tel-input
                            v-if="editMode"
                            :default-country="country_code"
                            :dynamic-placeholder="true"
                            :input-classes="['w-100', 'uc-input','form-control']"
                            :preferred-countries="['US']"
                            :valid-characters-only="true"
                            v-model="phone"
                            @change="updatePhone"
                            v-on:country-changed="updatePhone"
                        ></vue-tel-input> -->
                            <div v-else class="w-100 uc-input-info" >{{ subuser.phone }}</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group uc-input-cover">
                        <label for="">Company</label>
                        <div>
                            <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.company">
                            <div v-else class="w-100 uc-input-info" >{{ subuser.settings.company }}</div>
                        </div>
                    </div>
                </div>


                <h5 class="i-p-10">ACCOUNT TIMEZONE</h5>
                <p class="i-p-10">All account activities and analytics will be reported using the timezone selected below. 
                    Also, features that require time selection will use this timezone.
                </p>
                <div class="d-flex flex-wrap w-100">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 uc-input-cover" >
                        <label for="">Timezone</label>
                        <div >
                            <!-- <input v-if="editMode" class="w-100 uc-input"  type="text" v-model="subuser.settings.timezone"> -->
                            <el-select
                            v-if="editMode"
                            :value="subuser.settings.timezone"
                            class="w-100 custom_select"
                            popper-class="custom_select_pop admin_select_pop"
                            filterable
                            placeholder="Select Timezone"
                            v-model="subuser.settings.timezone">
                                <el-option
                                    :key="`timezone_${index}`"
                                    :label="timezone['name']"
                                    :value="timezone['code']"
                                    v-for="(timezone, index) in $store.state
                                        .timezones"
                                ></el-option>
                            </el-select>
                            <div v-else class="w-100 uc-input-info" >{{ subuser.settings.timezone }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="i-card i-mt-20">
                <h5 class="i-p-10">FEATURE LIMITS</h5>
                <div class="d-flex flex-wrap w-100">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                        <label for="">Total Projects Allowed</label>
                        <div v-if="editMode">
                            <input v-if="!unlimited_projects_allowed" min="0" class="uc-input"  type="number" v-model="subuser.settings.subuser_total_projects_allowed" placeholder="Number of Projects">
                            <div class="i-mt-10">
                                <el-checkbox v-model="unlimited_projects_allowed"></el-checkbox> &nbsp;
                                <span class="unlimited">
                                    Unlimited 
                                </span>
                            </div>  
                        </div>
                        <div v-else class="w-100 uc-input-info" >{{ subuser.settings.subuser_total_projects_allowed }}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                        <label for="">Total Videos Allowed</label>
                        <div v-if="editMode">
                            <input v-if="!unlimited_videos_allowed" min="0" class="uc-input"  type="number" v-model="subuser.settings.subuser_total_videos_allowed" placeholder="Number of Videos">
                            <div class="i-mt-10">
                                <el-checkbox v-model="unlimited_videos_allowed"></el-checkbox> &nbsp;
                                <span class="unlimited">
                                    Unlimited 
                                </span>
                            </div>  
                        </div>
                        <div v-else class="w-100 uc-input-info" >{{ subuser.settings.subuser_total_videos_allowed }}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                        <label for="">Player Skins Allowed</label>
                        <div v-if="editMode">
                            <div>
                                <el-select v-model="player_skins_allowed" multiple placeholder="Select Player skins">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>  
                        </div>
                        <div v-else class="w-100 uc-input-info" >
                            <span v-for="skin in player_skins_allowed" :key="skin">{{ skin }} </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                        <label for="">Access to Interaction Tools</label>
                        <div v-if="editMode">
                            <div class="i-mt-10">
                                <el-switch
                                :active-value="1"
                                :inactive-value="0"
                                active-color="#0AD688"
                                slot="reference"
                                v-model="subuser.settings.subuser_interaction_tools_access"
                                ></el-switch>
                            </div>  
                        </div>
                        <div v-else class="w-100 uc-input-info" >{{ yesOrNo(subuser.settings.subuser_interaction_tools_access) }}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                        <label for="">Access to Video Behaviors</label>
                        <div v-if="editMode">
                            <div class="i-mt-10">
                                <el-switch
                                :active-value="1"
                                :inactive-value="0"
                                active-color="#0AD688"
                                slot="reference"
                                v-model="subuser.settings.subuser_video_behaviors_access"
                                ></el-switch>
                            </div>  
                        </div>
                        <div v-else class="w-100 uc-input-info" >{{ yesOrNo(subuser.settings.subuser_video_behaviors_access) }}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                        <label for="">Access to Dynamic Funnels</label>
                        <div v-if="editMode">
                            <div class="i-mt-10">
                                <el-switch
                                :active-value="1"
                                :inactive-value="0"
                                active-color="#0AD688"
                                slot="reference"
                                v-model="subuser.settings.subuser_dynamic_funnels_access"
                                ></el-switch>
                            </div>  
                        </div>
                        <div v-else class="w-100 uc-input-info" >{{ yesOrNo(subuser.settings.subuser_dynamic_funnels_access) }}</div>
                    </div>
                </div>
            </div>
            <p v-if="nameError || emailError.length > 0" class="text-danger text-center">There is at least one error in the form</p>
            <div v-if="editMode" class="text-right mt-5">
                <button class="btn-primary i-btnpry" @click.prevent="updateSubuser">Update</button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import CountrySelect from '../Items/CountrySelect.vue'
import StateSelect from '../Items/RegionSelect.vue'
import { VueTelInput } from '../../../utils/vue-tel-input';
export default {
    name: 'UserEditView',
    props: ['subuza'],
    components: { VueTelInput, StateSelect, CountrySelect },
    data: () => ({
        options: [
            {label: 'Aero', value: 'aero'},
            {label: 'Bold', value: 'bold'},
            {label: 'Clean', value: 'clean'},
            {label: 'Flow', value: 'flow'},
            {label: 'Fluid', value: 'fluid'},
            {label: 'Frequency', value: 'frequency'},
            {label: 'Modern', value: 'modern'},
            {label: 'Oxygen', value: 'oxygen'},
            {label: 'Silk', value: 'silk'},
            {label: 'Transient', value: 'transient'},
        ],
        player_skins_allowed: [],
        unlimited_projects_allowed: false,
        unlimited_videos_allowed: false,
        showDPHover: false,
        behavior_allowed: 0,
        funnel_allowed: 0,
        subuser: {},
        usar: '',
        deleteUser: false,
        disableUser: false,
        loading: false,
        editMode: false,
        iCountry: null,
        iState: null,
        phone: null,
        phone_country_iso: null,
        country_code: null,
        nameError: '',
        emailError: [],
        staticName: null,
        staticEmail: null,
        dialogWith: '40%',
        disableTitle: '',
        disableAccept: '',
        file: null,
    }),
    watch: {
        subuza: {
            handler: function (value) {
                if(!this.staticName) {
                    this.staticName = value.name
                }
                if(!this.staticEmail) {
                    this.staticEmail = value.email
                }
                this.subuser = value;
                this.player_skins_allowed = JSON.parse(value.settings.subuser_player_skins_allowed);
                this.iCountry = this.subuser.settings.country;
                this.iState = this.subuser.settings.state;
                this.phone_country_iso = this.subuser.phone_country_iso;
                this.phone = this.subuser.phone;
                this.country_code = this.subuser.country_code;
                if(value.settings.subuser_total_projects_allowed == 'unlimited') {
                    this.unlimited_projects_allowed = true;
                }
                if(value.settings.subuser_total_videos_allowed == 'unlimited') {
                    this.unlimited_videos_allowed = true;
                }

                // this.subuser = this.subusza;
            },
            deep: true,
            immediate: true
        },
        subuser: {
            handler: function() {
                if(this.createClicked) {
                    this.verifyForm()
                }
            },
            deep: true,
            immediately: true,
        },
    },
    methods: {
        loadFileInput(event) {
            console.log(event);
            console.log(event.target.files[0]);
            this.updateProfilePicture(event.target.files[0]);
        },
        clickFileInput() {
            let imgRef = this.$refs['dp-input-ref'];
            imgRef.click();
        },
        momentifyCreatedDate(date) {
            return moment(date).format('lll');
        },
        momentifyLastActivity(date) {
            return moment(date).startOf().fromNow();
        },
        startEditMode() {
            this.editMode = true;
            this.$refs.editForm.scrollIntoView({behavior : "smooth"});
        },
        stopEditMode() {
            this.editMode = false;
        },
        yesOrNo(item) {
            return item == true || item == 1  ? 'Yes' : 'No';
        },
        updateCountry(e) {
            this.iCountry = e;
            console.log(e);
        },
        updateState(e) {
            this.iState = e;
            console.log(e);
        },
        updatePhone(e) {
            this.phone_country_iso = e.iso2
            console.log(e.iso2)
            console.log(e.dialCode)
            this.country_code = e.dialCode;
        },
        verifyForm() {
            this.nameError = ''
            this.emailError = []
            if(this.subuser.name.length < 1) {
                this.nameError = 'First name is required'
            }
            if(this.subuser.email.length < 1) {
                this.emailError.push('Email is required');
            }
            else if(!this.validEmail(this.subuser.email)) {
                this.emailError.push('Invalid email');
            }
            this.createClicked = true;
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        async updateProfilePicture(avatar) {
            this.loading = true;
            const dpData = new FormData();
            dpData.append("id", this.subuser.id);
            dpData.append("avatar", avatar);
            this.axios.post('/settings/avatar-upload', dpData)
            .then(response => {
                this.loading = false;
                if (response.status === 200) {
                    this.$message({
                        message: response.data.message,
                        type: 'success',
                    });
                    this.updateUserSuccessful();
                } else if(response.status === 401) {
                    // if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    this.$store.state.userData = null;
                    this.$message({
                        message: response.data.message,
                        type: 'error',
                    });
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } else if(response.status === 403) {
                    // if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    window.location.replace("/settings");
                    this.$message({
                        message: response.data.message,
                        type: 'error',
                    });
                } 
                else {
                    // if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    this.$message.error(response.data.message);
                }
            })
            .catch(error => {
                // if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                this.loading = false;
                if(error.response.status === 403) {
                    this.$message.error(error.response.data.message);
                    window.location.replace("/settings");
                } else if(error.response.status === 401) {
                    this.$message.error(error.response.data.message);
                    this.$store.state.userData = null;
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } 
                this.$message.error('Error! Something went wrong');
            });
        },
        async newSubuserPassword() {
            this.loading = true;
            const subuserMiniUpdate = {
                subuser_id : this.subuser.id,
                email: this.subuser.email,
                user_id: this.subuser.parent_id
            };
            this.axios.post('/minew-pwd', subuserMiniUpdate)
            .then(response => {
                this.loading = false;
                if (response.status === 200) {
                    this.$message({
                        message: response.data.message,
                        type: 'success',
                    });
                    this.updateUserSuccessful();
                } else if(response.status === 401) {
                    if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    this.$store.state.userData = null;
                    this.$message({
                        message: response.data.message,
                        type: 'error',
                    });
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } else if(response.status === 403) {
                    if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    window.location.replace("/settings");
                    this.$message({
                        message: response.data.message,
                        type: 'error',
                    });
                } 
                else {
                    if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    this.$message.error(response.data.message);
                }
            })
            .catch(error => {
                if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                this.loading = false;
                this.$message.error(error.response ? error.response.data.message : 'Error! Something went wrong');
                if(error.response.status === 403) {
                    window.location.replace("/settings");
                } else if(error.response.status === 401) {
                    this.$store.state.userData = null;
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } 
            });
        },
        async miniUpdateSubuser(type) {
            this.loading = true;
            this.deleteUser = false;
            this.disableUser = false;
            const subuserMiniUpdate = {
                subuser_id : [this.subuser.id],
                typa: type,
                able_disable: this.subuser.subuser_activated
            };
            this.axios.post('/minup-subuser', subuserMiniUpdate)
            .then(response => {
                this.loading = false;
                if (response.status === 200) {
                    this.$message({
                        message: response.data.message,
                        type: 'success',
                    });
                    this.updateUserSuccessful();
                } else if(response.status === 401) {
                    if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    this.$store.state.userData = null;
                    this.$message({
                        message: response.data.message,
                        type: 'error',
                    });
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } else if(response.status === 403) {
                    if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    window.location.replace("/settings");
                    this.$message({
                        message: response.data.message,
                        type: 'error',
                    });
                } 
                else {
                    if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                    this.$message.error(response.data.message);
                }
            })
            .catch(error => {
                if(type == 'disable' ) this.subuser.subuser_activated = this.subuser.subuser_activated == 1 ? 0 : 1;
                this.loading = false;
                this.$message.error(error.response ? error.response.data.message : 'Error! Something went wrong');
                if(error.response.status === 403) {
                    window.location.replace("/settings");
                } else if(error.response.status === 401) {
                    this.$store.state.userData = null;
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } 
            });
        },
        async updateSubuser() {
            await this.verifyForm();
            if(this.nameError.length > 0 || this.emailError.length > 0) {
                return false;
            }
            this.loading = true;
            const subuserUpdate = {
                name: this.subuser.name,
                email: this.subuser.email,
                country_code: this.country_code,
                id: this.subuser.id,
                phone: this.subuser.phone,
                user_id: this.subuza.parent_id,
                // phone_country_iso: this.phone_country_iso,
                settings: {
                    id: this.subuser.settings.id ,
                    apartment_suite: this.subuser.settings.apartment_suite ,
                    city: this.subuser.settings.city ,
                    company: this.subuser.settings.company ,
                    country: this.iCountry,
                    state: this.iState,
                    street_address: this.subuser.settings.street_address ,
                    subuser_interaction_tools_access: this.subuser.settings.subuser_interaction_tools_access,
                    subuser_player_skins_allowed: JSON.stringify(this.player_skins_allowed),
                    subuser_total_projects_allowed: this.unlimited_projects_allowed == true ? 'unlimited' : this.subuser.settings.subuser_total_projects_allowed,
                    subuser_total_videos_allowed: this.unlimited_videos_allowed == true ? 'unlimited' : this.subuser.settings.subuser_total_videos_allowed,
                    subuser_video_behaviors_access: this.subuser.settings.subuser_video_behaviors_access,
                    subuser_dynamic_funnels_access: this.subuser.settings.subuser_dynamic_funnels_access,
                    timezone: this.subuser.settings.timezone,
                    zip_code: this.subuser.settings.zip_code,
                }
            };
            this.axios.post('/maxup-sub-reg', subuserUpdate)
            .then(response => {
                this.loading = false;
                if (response.status === 201) {
                    this.$message({
                        message: response.data.message,
                        type: 'success',
                    });
                    this.updateUserSuccessful();
                } else if(response.status === 401) {
                    this.$message.error(response.data.message);
                    this.$store.state.userData = null;
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } else if(response.status === 403) {
                    this.$message.error(response.data.message);
                    window.location.replace("/settings");
                }
                else {
                    this.$message.error(response.data.message);
                }
            })
            .catch(error => {
                console.log(error)
                this.loading = false;
                this.$message.error(error.response ? error.response.data.message : 'Error! Something went wrong');
                if(error.response.status === 403) {
                    window.location.replace("/settings");
                } else if(error.response.status === 401) {
                    this.$store.state.userData = null;
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } 
            });
        },
        updateUserSuccessful() {
            this.$emit('userCreated');
        },
        disableUserDiaolg() {
            if(this.subuser.subuser_activated == 0) {
                this.disableTitle = 'Disable User'
                this.disableAccept = 'Yes disable'
                this.disableUser = true
            }
            else {
                this.disableTitle = 'Enable User'
                this.disableAccept = 'Yes activate'
                this.disableUser = true
            }
        },
        stopDisableUserDiaolg() {
            if(this.subuser.subuser_activated == 0) {
                this.subuser.subuser_activated = 1
            }
            else if(this.subuser.subuser_activated == 1) {
                this.subuser.subuser_activated = 0
            }
            this.disableUser = false;
        },
        onResize() {
            if (window.innerWidth <= 1000) this.dialogWith = "45%";
            if (window.innerWidth <= 800) this.dialogWith = "65%";
            return true;
        },
    },
    async mounted() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
}
</script>

<style>
    .base-cover-inner-top {
        margin: 20px 0; 
        padding: 0 15px;
    }
    .base-cover-inner-bottom {
        margin: 10px 0; 
        padding: 8px 23px;
    }
    .user-edit-icon-cover {
        text-align: right !important;
    }
    .info-img-cover {
        padding: 10px 10px 0 0;
    }
    .disable-user-icon-cover-2 {
        display: none;
    }
    .i-card {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; 
        padding: 2%;
    }
    .i-profile-img {
        width: 100px; 
        height: 100px; 
        border-radius: 50%;
    }
    .i-profile-img-onhover {
        background-color: #f6f9fab8; 
        border: 1px solid rgb(0, 172, 220);
    }
    .i-profile-img-onhover-icon {
        font-size: 45px;
        color: rgb(0 172 220 / 52%);
        margin-top: 17px;
        display: inline-block;
    }
    .i-mt-20 {
        margin-top: 20px
    }
    .i-p-8 {
        padding:8px;
    }
    .last-activity {
        margin-top: 8px; 
        border-bottom: 1px solid rgb(153 150 150 / 20%);
    }
    .last-login {
        margin-top: 8px;
    }
    .i-p-10 {
        padding: 10px;
    }
    .lst-lst {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; 
        padding: 4%
    }
    .uc-input-cover {
        color: #828484; 
        padding: 10px;
    }
    .uc-input {
        padding: 7px; 
        border: 1px solid #bab3b3; 
        border-radius: 5px;
    }
    .uc-input-info {
        padding: 7px; 
        font-weight: 500 !important; 
        color: var(--color-content) !important; 
        margin-left: -7px;
    }
    .i-mt-10 {
        margin-top: 10px;
    }
    .unlimited {
        font-weight: 500 !important; 
        color: var(--color-content) !important;
    }

    
    .fs-20 {
        font-size: 20px;
    }

    .dp-cover {
        position: relative; 
        width: 100px; 
        height:100px; 
        margin:0 auto; 
        cursor:pointer;
    }
    .absolute-position {
        position:absolute;
    }


    @media screen and (max-width: 600px) {
        .image-info-cover {
            width: 100%;
        }
        .info-img-cover {
            width: 100% !important;
            text-align: center;
        }
        .user-edit-icon-cover {
            text-align: center !important;
            width: 100% !important;
        }
        .disable-user-icon-cover-1 {
            display: none;
        }
        .disable-user-icon-cover-2 {
            display: block;
            width: 105px !important;
            margin: 0 auto;
            margin-top: 10px;
        }
        .name-email-info-cover {
            width: 100%;
            text-align: center;
        }
        
    }
    @media screen and (max-width: 400px) {
        .base-cover-inner-top {
            padding: 0;
        }
        .base-cover-inner-bottom {
            margin: 10px 0; 
            padding: 8px;
        }
    }
</style>