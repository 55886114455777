<template>
  <el-container>
    <billing-information
      :billingInformation="billingInformation"
      :subscriptionData="subscriptionData"
      :userInfo="userInfo"
      :mobileCheck="mobileCheck"
    />
    <!-- <billing-preferences /> -->
    <billing-history
      :billingInformation="billingInformation"
      :billingStatus="billingStatus"
      :subscriptionData="subscriptionData"
      :billingInvoices="billingInvoices"
    />
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import IssuedToBilling from "./Shared/IssuedToBilling";
import BillingInformation from "../../../Shared/BillingInformation/BillingInformation";
import BillingPreferences from "./BillingPreferences";
import BillingHistory from "../../../Shared/BillingInformation/BillingHistory";
import PaypalIcon from "../../Icons/PaypalIcon";

export default {
  name: "BillingInformationPage",
  components: {
    BillingInformation,
    IssuedToBilling,
    BillingPreferences,
    BillingHistory,
    PaypalIcon
  },
  props: {},
  computed: {
    errorsForm: {
      get() {
        return this.$store.state.errorsForm;
      }
    },

    ...mapState({
      billingInformation: state => state.billingInformation,
      billingStatus: state => state.billingStatus,
      subscriptionData: state => state.subscription,
      billingInvoices: state => state.billingInvoices,
      userInfo: state => state.userData,
      mobileCheck: state => state.isMobile,
      billingEstimate: state => state.billingEstimate,
      invoiceDetails: state => state.invoiceDetails
    })
  },
  data: function() {
    return {
      isMobile: false
    };
  },
  mounted() {
    this.getBillingInformation();
    this.getBillingInvoicesData();
  },
  created() {},
  beforeDestroy() {},
  methods: {
    async getBillingInformation() {
      await this.$store.dispatch("getBillingInformationData");
    },

    async getBillingInvoicesData() {
      await this.$store.dispatch("getBillingInvoicesData");
    }
  }
};
</script>

<style lang="less" scoped>
</style>

