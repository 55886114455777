<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :top="mobileCheck ? `10vh` : `20vh`"
        :visible.sync="trigger"
        :width="mobileCheck ? `100%` : `600px`"
        @closed="$emit('close', true)"
        center
        custom-class="pages-custom-dialog"
        title="CROP IMAGE"
    >
        <el-container>
            <div class="m-top-3">
                <cropper
                    :minHeight="200"
                    :minWidth="200"
                    :restrictions="pixelsRestriction"
                    :src="imageUrl"
                    :stencilProps="{previewClassname: 'logo-preview'}"
                    class="upload-example-cropper"
                    ref="logo_cropper"
                    stencilComponent="circle-stencil"
                />
            </div>
        </el-container>
        <span class="dialog-footer" slot="footer">
            <el-button @click="uploadCropImage(imageUrl)" :loading="imageLoading" round type="info">Skip Cropping</el-button>
            <el-button @click="$emit('close', false)" round type="info" :loading="imageLoading">Cancel</el-button>
            <el-button @click="crop" round type="primary" :loading="imageLoading">Apply</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {Cropper} from "vue-advanced-cropper";
export default {
    name: "ImageCropper",
    components: {
        Cropper
    },
    props: ['mobileCheck', 'imageUrl', 'trigger', 'action', 'folder'],
    data: () => ({
        imageLoading: false
    }),
    methods: {
        pixelsRestriction({
            minWidth,
            minHeight,
            maxWidth,
            maxHeight,
            imageWidth,
            imageHeight
        }) {
            return {
                minWidth: minWidth,
                minHeight: minHeight,
                maxWidth: Math.min(imageWidth, maxWidth),
                maxHeight: Math.min(imageHeight, maxHeight)
            };
        },
        uploadCropImage(imageUrl) {
            this.imageLoading = true;
            let folder = this.$props.folder;
            this.axios.post(this.$props.action, {
                file: imageUrl, // Base64 url gets decoded server side.
                folder: folder
            }).then(r => {
                this.imageLoading = false;
                if (r.data.success) {
                    this.$emit('imageLink', r.data.logo);
                } else {
                    window.vEvent.fire(
                        "stage-common-error",
                        "Logo upload failed."
                    );
                }
                this.$emit('close', false);
            }).catch(error => {
                console.log(error);
                this.$emit('close', false);
                window.vEvent.fire(
                    "stage-common-error",
                    "Logo upload failed."
                );
            });
        },
        crop() {
            const {canvas} = this.$refs.logo_cropper.getResult();
            this.uploadCropImage(canvas.toDataURL());
        },
    }
}
</script>