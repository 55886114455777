<template>
    <el-card class="without-padding">
        <div class="title">Billing History</div>
       <!--  <div
            class="sub-title"
        >Manage your account users. Use Permissions to limit what they can access.</div> -->
        <div class="body-history" v-if="billingInvoices">
            <el-row class="table-header">
                <el-col :span="12">NAME</el-col>
                <el-col :span="4">TOTAL</el-col>
                <el-col :span="8">STATUS</el-col>
            </el-row>
            <el-row
                v-for="(invoice, index) in billingInvoices"
                :key="invoice.id"
                :class="{'table-row': true, 'table-row__expanded': index > 4 && !expanded,}"
            >
                <el-col :span="12">
                    <el-row class="id">{{invoice.receipt_id}}</el-row>
                    <el-row v-if="invoice.subscription" class="capitalize casual-text">
                        <!-- <template  v-if="invoice.plan_id">
                        <span
                            v-for="item in splitPlanName(invoice.plan_id)"
                            :key="item"
                        >{{item}}</span>
                        </template> -->
                        <template>
                        <div v-if='invoice.provider_id === "stripe"'>
                            <span
                                v-for="item in splitPlanName(invoice.subscription.stripe_plan)"
                                :key="item"
                            >{{item}}</span>
                        </div>
                        <span v-else>
                            <span v-if='invoice.plan && invoice.plan.name'>
                                <span v-if='!invoice.plan.name.includes("4KPlayer")'>
                                    4KPlayer {{ invoice.plan.name }}
                                </span>
                                <span v-else>
                                    {{ invoice.plan.name }}
                                </span>
                            </span>
                        </span>
                        </template> Subscription
                    </el-row>
                    <el-row
                        v-if="invoice.status === 'notPaid' && billingStatus"
                        class="casual-text warning-text"
                    >Due {{setDate(billingStatus.billingDate)}}</el-row>
                    <el-row
                        v-if="invoice.status === 'paid' || invoice.status === 'refund'"
                        class="casual-text"
                    >Paid {{setDate(invoice.updated_at)}} at {{setTime(invoice.updated_at)}}</el-row>
                    <el-row
                        v-if="!invoice.updated_at && invoice.status === 'draft'"
                        class="casual-text"
                    >Created {{setDate(invoice.created_at)}} at {{setTime(invoice.created_at)}}</el-row>
                </el-col>
                <el-col :span="4" class="total">${{invoice.total}}</el-col>
                <el-col :span="4">
                    <div v-if="invoice.status === 'paid' || (invoice.status !== 'refund' && invoice.paid)" class="paid">Paid</div>
                    <div v-else-if="invoice.status === 'refund'" class="paid refunded">Refunded</div>
                    <div v-else class="paid not-paid">Not Paid</div>
                </el-col>
                <el-col :span="4" class="button-column">
                    <el-button
                        v-if="invoice.status === 'paid' || invoice.status === 'refund' || invoice.paid"
                        @click="openInvoicePage(invoice, index, id)"
                        round
                    >View</el-button>
                    <div v-else>
                        <button class="button" @click="openInvoicePage(invoice, index, invoice.receipt_id)">View & Pay</button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-card
            class="expand-card"
            @click="expandFeatures()"
            v-if="billingInvoices && billingInvoices.length > 4"
        >
            <div class="expand-card__wrapper" @click="expandFeatures()">
                <span v-if="!expanded">See all invoices in billing history</span>
                <span v-else>See less invoices in billing history</span>
            </div>
        </el-card>
    </el-card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
    name: 'BillingHistory',
    components: {},
    props: {
        billingStatus: Object,
        billingInvoices: Array
    },
    computed: {
        errorsForm: {
            get() {
                return this.$store.state.errorsForm;
            }
        },

        ...mapState({
            billingInformation: state => state.billingInformation,
            subscriptionData: state => state.subscription
        })
    },
    data: function() {
        return {
            isMobile: false,
            expanded: false
        };
    },
    beforeDestroy() {

    },
    mounted() {

    },
    created() {},
    methods: {
        openInvoicePage(invoice, index, id) {
            console.log( invoice.id)
            let path = ''
            if (this.$route.path.includes('admin')) {

                 path =
                    '/admin/active/' +
                    this.$route.params.id +
                    '/user-info/receipt/' +
                    id;
            }

            if (this.$route.path.includes('settings')) {
                 path = {
                    name: 'BillingReceipt',
                    // params: { section: 'billing', receiptID: index + 1 }
                    params: { section: 'billing', receiptID: invoice.id }
                };
            }
            this.$store.commit('setInvoiceDetails', invoice);
            this.$router.push(path);
        },

        expandFeatures() {
            this.expanded = !this.expanded;
        },

        setDate(val) {
            return moment(val).format("LL");
        },

        setTime(val) {
            return moment(val).format("LT");
        },

        splitPlanName(val) {
            const newStrings = val.split("-").map(item => item + " ");
            return newStrings;
        }
    }
};
</script>

<style lang="less" scoped>
.el-card {
    margin-bottom: 15px;
    padding: 1.5rem 0rem !important;
    box-shadow: none !important;
}

.title {
    font-size: 20px;
    font-weight: 500;
    margin: 20px 20px 0px 40px;
}

.sub-title {
    font-size: 14px;
    margin: 5px 20px 0px 40px;
}

.body-history {
    display: flex;
    flex-direction: column;

    .table-header {
        overflow: hidden;
        width: 100%;
        background: #fafeff;
        padding: 15px 40px;
        margin-top: 20px;
        font-size: 13px;
        font-weight: 500;
    }

    .table-row {
        padding: 30px 40px 30px 40px;
        border-bottom: #e8e8ea solid 1px;
        display: flex;
        align-items: center;

        .id {
            font-weight: 500;
            font-size: 16px;
        }

        .total {
            font-size: 16px;
        }

        .casual-text {
            font-size: 13px;
        }

        .warning-text {
            color: #f27500;
        }
        .paid {
            background: rgb(245, 254, 250);
            border-radius: 15px;
            width: fit-content;
            padding: 2px 15px;
            color: #0ad688;
            font-size: 13px;
        }

        .not-paid {
            background: rgb(255, 250, 245);
            color: #fd7a00;
        }

        .refunded {
            background: rgb(255, 248, 248);
            color: #ee5951;
        }

        .button-column {
            display: flex;
            justify-content: flex-end;

            .button {
                background: #f27500;
                border-radius: 16px;
                border: none;
                color: #ffffff;
                width: fit-content;
                height: 32px;
                font-weight: 500;
                font-size: 16px;
                padding: 4px 15px 4px 15px;
            }

            .button:focus {
                outline: none;
            }
        }

        .capitalize {
            text-transform: capitalize;
        }

        &__expanded {
            display: none;
        }
    }

    .table-row:hover {
        background-color: #f7fcfd;
        cursor: pointer;
    }
}

.expand-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    height: 64px;
    color: #00acdc;

    &__wrapper {
        cursor: pointer;
    }
}
</style>
