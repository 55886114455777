export default ({
  card_number: number,
  cvc,
  exp_year,
  exp_month,
}) => new Promise((resolve, reject) => {
  Stripe.setPublishableKey(process.env.STRIPE_PUBLIC_KEY);
  Stripe.card.createToken({
    number,
    cvc,
    exp_year,
    exp_month,
  }, (status, response) => {
    if (response.error) {
      return reject(response);
    }

    return resolve(response);
  });
});