<template>
    <el-card>
        <div slot="header" class="clearfix header-billinginfo">
            <div class="title">Billing Preferences</div>
        </div>
        <div class="body-preferences" v-if="billingInformation">
            <div class="email-row">
                <span>
                    Receipts and billings alerts will be sent to
                    <span
                        class="text-bold"
                    >{{billingInformation.preferences.notify_email}}</span>
                </span>
                <el-link type="primary" class="link-edit" @click="openEditEmailModal">Edit</el-link>
            </div>
            <div class="wrapper">
                <el-checkbox
                    v-model="labelInvoicesPreferences"
                    label="Label your Receipts as Invoices"
                    @change="putPreferences"
                />
                <div class="checkbox-explanation">
                    <span>
                        Select this option to label your receipt header as “Invoice”
                        for legal or accounting reasons. This will not affect how
                        you are billed.
                    </span>
                </div>
            </div>
            <div>
                <el-checkbox
                    v-model="billEstimatesPreferences"
                    label="Bill Estimates"
                    @change="putPreferences"
                />
                <div class="checkbox-explanation">
                    <span>
                        Received bill estimates 2 weeks before recurring charges
                        are due.
                    </span>
                </div>
            </div>
        </div>

        <el-dialog
            :top="mobileCheck ? `10vh` : `30vh`"
            :visible.sync="modalEditBillingEmail"
            :width="mobileCheck ? `100%` : `700px`"
            center
            custom-class="edit-billing-email-dialog"
            :title="'Billing Email'"
        >
            <div class="dialog-body">
                <div class="edit-area">
                    <div class="row">
                        <el-col class="column" :span="16" :offset="1">
                            <div class="input-label">
                                <div class="main-text">Billing email</div>
                            </div>
                            <el-input
                                placeholder
                                v-model="billingEmail"
                                :value="billingEmail"
                                suffix-icon="el-icon-postcard "
                            ></el-input>
                        </el-col>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="saveBillingEmail" type="primary" round>Save</el-button>
                <el-button @click="modalEditBillingEmail = false" type="info" round>Cancel</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "BillingPreferences",
    components: {},
    props: {},
    computed: {
        errorsForm: {
            get() {
                return this.$store.state.errorsForm;
            }
        },

        ...mapState({
            billingInformation: state => state.billingInformation,
            mobileCheck: state => state.isMobile
        })
    },
    data: function() {
        return {
            isMobile: false,
            labelInvoicesPreferences: false,
            billEstimatesPreferences: false,
            modalEditBillingEmail: false,
            billingEmail: ""
        };
    },
    watch: {
        billingInformation: function(newVal) {
            this.labelInvoicesPreferences = Boolean(
                newVal.preferences.label_receipts
            );
            this.billEstimatesPreferences = Boolean(
                newVal.preferences.bill_estimates
            );
            this.billingEmail = newVal.preferences.notify_email;
        }
    },
    beforeDestroy() {},
    mounted() {},
    created() {},
    methods: {
        openEditEmailModal() {
            this.modalEditBillingEmail = true;
        },

        saveBillingEmail() {
            const put_data = {
                url: "/billing/information/preferences",
                data: {
                    notify_email: this.billingEmail
                }
            };

            this.$store
                .dispatch("putData", { ...put_data })
                .then(response => {
                    
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.dispatch("getUserData");
            this.$store.dispatch("getBillingInformationData");
            this.modalEditBillingEmail = false;
        },

        putPreferences() {
            const put_data = {
                url: "/billing/information/preferences",
                data: {
                    label_receipts: this.labelInvoicesPreferences,
                    bill_estimates: this.billEstimatesPreferences
                }
            };

            this.$store
                .dispatch("putData", { ...put_data })
                .then(response => {
                    this.labelInvoicesPreferences =
                        response.data.label_receipts;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>

<style lang="less" scoped>
.header-billinginfo {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .title {
        font-size: 20px;
        font-weight: 500;
    }
}

.el-card {
    margin-bottom: 15px;
    padding: 1.5rem 0rem !important;
    box-shadow: none !important;
}

.body-preferences {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;

    .text-bold {
        font-weight: 500 !important;
    }

    .link-edit {
        margin-left: 40px;
    }

    .email-row {
        margin-bottom: 20px;
    }

    .wrapper {
        margin-bottom: 30px;
    }

    .checkbox-explanation {
        /*padding: 0 50% 0 3%;*/
    }
}

@media (max-width: 1024px) {
    .checkbox-explanation {
        padding: 0px !important;
    }
}
</style>

