<template>
    <el-col :span="span ? span : 12" class='issued-to-billing'>
        <el-col :span="twoColumns ? 12 : 24">
            <el-row class="title" v-if="issuedToView">
                <span>Issued To</span>
            </el-row>

            <el-row v-if="issuedTo.name" class="name">
                <span>{{issuedTo.name}}</span>
            </el-row>

            <el-row v-if="issuedTo.company" class="name">
                <span>{{issuedTo.company}}</span>
            </el-row>

            <el-row v-if="issuedTo.phone" class="text">
                <span>{{issuedTo.phone}}</span>
            </el-row>

            <el-row v-if="issuedTo.email" class="text email__margin">
                <span>{{issuedTo.email}}</span>
            </el-row>
        </el-col>

        <el-col :span="twoColumns ? 10 : 24">
            <el-row v-if="issuedTo.billing_address" class="text">
                <span>{{issuedTo.billing_address}}</span>
            </el-row>

            <el-row v-if="issuedTo.billing_address_line_2" class="text">
                <span>{{issuedTo.billing_address_line_2}}</span>
            </el-row>

            <el-row class="text">
                <span v-if="issuedTo.billing_city">{{issuedTo.billing_city}}</span>
                <span v-if="issuedTo.billing_zip">, {{issuedTo.billing_zip}}</span>
            </el-row>
            <el-row class="text">
                <span v-if="issuedTo.billing_country">{{issuedTo.billing_country}}</span>
            </el-row>
        </el-col>
    </el-col>
</template>

<script>
export default {
    name: "IssuedToBilling",
    components: {},
    props: {
        issuedTo: Object,
        issuedToView: {
            type: Boolean,
            default: true
        },
        span: Number,
        twoColumns: Boolean
    },
    computed: {},
    data: () => ({}),
    beforeDestroy() {},
    mounted() {
    },
    created() {},
    methods: {}
};
</script>

<style lang="less" scoped>
.title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;
}

.name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.text {
    font-size: 16px;
}

.email__margin {
    margin-bottom: 20px;
}
</style>

