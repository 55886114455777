<template>
    <div class="w-100 m-top-4 introduce-area">
        <div class="w-100 justify-content-center">
            <img alt src="../../../assets/img/settings/no-users.svg"/>
        </div>
        <div class="w-100 m-top-2 text-center header-title">Manage Account Users</div>
        <div class="justify-content-center w-100 text-center">
            <div
                class="w-60"
            >Create account users, assign them Roles and use Permissions to restrict what they can and cannot do in your account.
            </div>
        </div>
        <div class="text-center" @click="openTutorial">
            <a class="btn btn-default-outline watch-video-btn">
                <!-- <img alt src="../../../assets/img/settings/buttton-play-circle.svg"/> -->
                Tutorial on account users
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BlankUser",
        methods: {
            openTutorial() {
                window.open('https://help.bigcommand.com/article/61-adding-sub-user-to-your-account-and-editing-user-permissions', '_blank');
            }
        }
    };
</script>

<style lang="less" scoped>
    .introduce-area {
        .watch-video-btn {
            border: 1px solid #0bacdb !important;
            font-weight: 500 !important;
            color: #21455e !important;
            padding: 10px 15px !important;
            // width: fit-content;
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            top: 20px;
            font-size: 14px;
            img {
                margin-right: 1rem;
            }
        }
    }
</style>
