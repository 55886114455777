<template>
    <div class="dialog-body">
        <div class="edit-area">
            <div class="row">
                <el-col class="column" :span="9" :offset="1">
                    <div class="input-label">
                        <div class="main-text">Credit Card Number</div>
                    </div>
                    <el-input
                        placeholder
                        v-model="cardData.card_number"
                        suffix-icon="el-icon-bank-card"
                    ></el-input>
                </el-col>
                <el-col class="column" :span="12" :offset="1">
                    <div class="input-label">
                        <div class="main-text">Expiration Date</div>
                        <div></div>
                    </div>
                    <div class="expiration-selects">
                        <el-col :span="4">
                            <el-select v-model="cardData.exp_month" filterable placeholder="Month" >
                                <el-option
                                    v-for="item in selectMonthOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4" :offset="1">
                            <el-select v-model="cardData.exp_year" filterable placeholder="Year">
                                <el-option
                                    v-for="item in selectYearOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4" :offset="1">
                        <el-input placeholder="CVC" v-model="cardData.cvc"
                                  ></el-input>
                        </el-col>

                    </div>
                </el-col>

            </div>

            <div class="credit-card-butons" style="margin:15px auto 50px!important;">
                <el-button @click="saveNewCardInfo" type="primary" round>Save</el-button>
                <el-button @click="closeCardEditModal" type="info" round>Cancel</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "EditCardInformation",
    components: {},
    props: {
        userInfo: Object,
        cardEditModal: Boolean
    },
    computed: {
        errorsForm: {
            get() {
                return this.$store.state.errorsForm;
            }
        }
    },
    data: function() {
        return {
            selectMonthOptions: [],
            selectYearOptions: [],
            cardData: {
                card_number: "",
                exp_month: "",
                exp_year: "",
                cvc: ""
            }
        };
    },
    beforeDestroy() {},
    mounted() {
        this.setMonthsArray();
        this.setYearsArray();
        if (!this.cardEditModal) {
            this.cardData = {
                card_number: "",
                exp_month: "",
                exp_year: "",
                cvc: ""
            };
        }
    },
    created() {},
    methods: {
        saveNewCardInfo() {
            this.$emit(
                "save-card-data-close-edit-card-modal-open-billing",
                this.cardData
            );
        },

        closeCardEditModal() {
            this.$emit("close-edit-card-modal-open-billing");
        },

        setMonthsArray() {
            for (let i = 1; i < 13; i++) {
                if (i < 10) {
                    this.selectMonthOptions.push({
                        value: "0" + i,
                        label: "0" + i
                    });
                } else {
                    this.selectMonthOptions.push({
                        value: i,
                        label: i
                    });
                }
            }
        },

        setYearsArray() {
            let currentYear = Number(moment(new Date()).format("YYYY"));
            for (let i = 0; i < 50; i++) {
                this.selectYearOptions.push({
                    value: String(currentYear + i),
                    label: String(currentYear + i)
                });
            }
        }
    }
};
</script>

<style lang="less" scoped>
</style>

