<template>
    <div>
        <div class="settings-stage settings-right-area user-referral-area" id="settings-stage">
            <right-header-footer class="header-items m-top-3">
                <template v-slot:title>Referral Earnings & Payouts</template>


                <template v-slot:actions>
                    <MobileMenu :isMobile='isMobile'></MobileMenu>
                    <button class="btn btn-primary m-left-2" type="button">
                        Promo Details Page
                    </button>
                </template>

            </right-header-footer>

            <el-container id="id_user_referral">
                <el-card>
                    <el-row :gutter="40" class="m-top-2">
                        <el-col :span="12" :xs="24">
                            <div class="form-group m-bottom-1">
                                <label class="control-label capital-transform text-green">Earnings</label>
                            </div>
                            <div class="form-group m-bottom-1 big-font">
                                <span class="">Current Month:</span>&nbsp;<span class="font-bold">${{topDetail.current_month_earning}}</span>
                            </div>
                            <div class="form-group m-bottom-1 medium-font">
                                <span>Lifetime:</span>&nbsp;<span>${{topDetail.life_time_earning}}</span>
                            </div>
                        </el-col>

                        <el-col :span="12" :xs="24">
                            <div class="form-group m-bottom-1">
                                <label class="control-label capital-transform text-blue">Payouts</label>
                            </div>
                            <div class="form-group m-bottom-1 big-font">
                                <span class="">Current Month:</span>&nbsp;<span class="font-bold">${{topDetail.current_month_payout}}</span>
                            </div>
                            <div class="form-group m-bottom-1 medium-font">
                                <span>Lifetime:</span>&nbsp;<span>${{topDetail.life_time_payout}}</span>
                            </div>
                            <div class="form-group m-bottom-1 small-font">
                                <span class="capital-transform text-orange">Pending:</span>&nbsp;<span class="text-orange">${{topDetail.pending_payout}}</span>
                                <span class="capital-transform text-red">Clawbacks:</span>&nbsp;<span class="text-red">${{topDetail.clawbacks}}</span>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="40" class="m-top-4">
                        <el-col class="condition-content" :span="9" :offset="15">
                            <span class="size-14">Timeline:&nbsp;</span>
                            <custom-date-range-picker
                                    :conditionItem="conditionTime" :start-date="$store.state.start_date" :show-range="true"
                                    v-on:change-date-range-picker-value="getNewDateRange"
                                    class="border-content"
                                    parent-el=".analytics-area"></custom-date-range-picker>
                        </el-col>
                    </el-row>


                    <el-row :gutter="40" class="m-top-4">

                        <el-col :span="24" :xs="24">

                            <div class="form-group m-bottom-1">
                                <label class="control-label trend-title">View Trends</label>
                            </div>
                            <div class="legend-dropdown align-items-center" id="legend-dropdown">
                                <el-select
                                        :value="checkedLegend[0]"
                                        class=" custom-el-select"
                                        popper-class="checkbox-select custom_select_pop with_arrow_190"
                                        v-model="checkedLegend[0]"
                                        @change="changeLegend(0)"
                                        placem
                                >
                                    <el-option
                                            v-for="item in command_ary"
                                            :key="`check_legend_0_${item.value}`"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="checkedLegend[1] === item.value || checkedLegend[2] === item.value"
                                    >
                                        <div class="check-icon">
                                            <i v-if="checkedLegend[0] === item.value" class="el-icon-check"></i>
                                        </div>
                                        <span class="legend-label">{{ item.label }}</span>
                                    </el-option>
                                </el-select>
                                <el-select
                                        :value="checkedLegend[1]"
                                        class=" custom-el-select"
                                        popper-class="checkbox-select custom_select_pop with_arrow_190"
                                        v-model="checkedLegend[1]"
                                        @change="changeLegend(1)"
                                >
                                    <el-option
                                            v-for="item in command_ary"
                                            :key="`check_legend_1_${item.value}`"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="checkedLegend[0] === item.value || checkedLegend[2] === item.value"
                                    >
                                        <div class="check-icon">
                                            <i v-if="checkedLegend[1] === item.value" class="el-icon-check"></i>
                                        </div>
                                        <span class="legend-label">{{ item.label }}</span>
                                    </el-option>
                                </el-select>

                            </div>
                            <div class="trend-chart-area">
                                <line-chart :chart-data="chartData" :options="options"></line-chart>
                            </div>

                        </el-col>
                    </el-row>

<!--                    <view-trend :trendData="trendData"></view-trend>-->

                    <referral-links :user="user"
                                    :linksToList="linksToList"
                                    :promoStats="promoStats"
                                    :referrals="referrals"
                                    :payouts="payouts"

                    ></referral-links>

                    <div class='pagination d-flex justify-content-between' v-if='linksCount > 10' >
                        <div class='pagination-left'>
                            <div class='pagination-left-title'>View</div>
                            <div class='pagination-left-count'>
                                <select @change='paginateView($event)'>
                                    <option :selected='item == paginate.view' v-for='(item, index) in paginate.count' :key='index'>{{ item }}</option>
                                </select>
                            </div>
                        </div>
                        <div class='pagination-right d-flex justify-content-between'>
                            <div @click='paginatePrev' class='pagination-right-prev' :class='paginate.begin == 0 ? "disabled" : ""'>
                                <ArrowRightIcon></ArrowRightIcon>
                            </div>
                            <div class='pagination-right-count'>
                                {{ paginate.begin + 1 }} - {{ linksCount > paginate.end ? paginate.end : linksCount }} of {{ linksCount }}
                            </div>
                            <div @click='paginateNext' class='pagination-right-next' :class='paginate.end >= linksCount ? "disabled" : ""'>
                                <ArrowRightIcon></ArrowRightIcon>
                            </div>
                        </div>
                    </div>

                    <promo-stats :user="user"
                                    :promoStats="promoStats"
                                    :referrals="referrals"
                                 :ref_count="ref_count"
                    ></promo-stats>



                    <payout :user="user"
                                 :payouts="payouts"
                    ></payout>

                    <div class="management">
                    <div class="header-title">
                        <span>Management</span>
                    </div>
                        <div>
                            <el-row :gutter="40" class="m-top-2">
                                <el-col :span="8" :xs="24">
                                    <button class="btn btn-primary m-left-2" type="button" @click="uploadw8w9Form = true">
                                        Submit W8/W9 Form
                                    </button>
                                </el-col>
                                <el-col :span="8" :xs="24">
                                    <button class="btn btn-primary m-left-2" type="button" @click="showPayoutModes = true">
                                        Update Payment Mode
                                    </button>
                                </el-col>
                                <el-col :span="8" :xs="24">
                                    <div class="form-group m-bottom-1 small-font">
                                        <span class="capital-transform text-orange">Contact affiliate support</span>
                                        <label>Email: partners@adilo.com</label>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>


                    <el-dialog
                            title="UPLOAD SIGNED W8/W9 TAX FORM"
                            center
                            :width=" `40%`"
                            class="el-dialog--huge dialog_edit dialog_border_radius "
                            :visible.sync="uploadw8w9Form"
                            :modalAppendToBody="false"
                    >

                        <div class="relative">

                            <label>Form Uploader</label>
                            <div class="upload_w8_file" id="upload_w8_file">
                                <div v-if="showUploadArea">
                                    <label class="inner" for="file" :class="{'error': invalidFileFormat}" v-if>


                                        +&nbsp;&nbsp;&nbsp;&nbsp;Upload Document (only pdf, doc, jpeg or png accepted)
                                    </label>
                                    <label class="text-danger small" v-if="invalidFileFormat">-->
                                                                                    <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                                                                                    Please upload correct file.
                                                                                </label>


                                    <input type="file" class="hidden" id="file" ref="file" v-on:change="handleFileUpload()"/>
                                </div>
                                <div v-else class="list-form">

                                    <div class="file_name">
                                        <span>{{filename}}</span>
                                    </div>
                                    <div class="file_size">
                                        <span>{{bytesToSize(filesize)}}</span>
                                    </div>
                                    <div class="remove_file">
                                        <span @click="removeForm()" class="remove-form-icon"><i class="el-dialog__close el-icon el-icon-close" ></i></span>
                                    </div>

                                </div>



<!--                                <div v-if="showUploadArea">-->
<!--                                    <el-upload drag-->
<!--                                               :show-file-list="false"-->
<!--                                               :on-progress="handleImageUploadProgress"-->
<!--                                               :on-success="handleImageUploadSuccess"-->
<!--                                               :before-upload="handleBeforeImageUpload"-->
<!--                                               :action="this.endpoint">-->

<!--                                        <div class="inner" :class="{'error': invalidFileFormat}">-->


<!--                                            +&nbsp;&nbsp;&nbsp;&nbsp;Upload Document (only pdf, doc, jpeg or png accepted)-->
<!--                                        </div>-->
<!--                                        <div v-show="upload_in_progress" class="inner" >-->
<!--                                            <div class="text-center">-->
<!--                                                <i class="el-icon-loading"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <label class="text-danger small" v-if="invalidFileFormat">-->
<!--                                            <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>-->
<!--                                            Please upload correct file.-->
<!--                                        </label>-->
<!--                                    </el-upload>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div slot="footer" class="dialog-footer">
                            <button class="btn btn-grey" @click="uploadw8w9Form = false" type="gray">Cancel</button>
                            <button class="btn btn-primary" @click="uploadw8w9form">Continue</button>
                        </div>
                    </el-dialog>
                    <el-dialog
                            title="PAYOUT MODES"
                            center
                            :width=" `40%`"
                            class="el-dialog--huge dialog_edit dialog_border_radius"
                            :visible.sync="showPayoutModes"
                            :modalAppendToBody="false"
                    >

                        <div class="relative">

                            <div v-if="this.dilogeVisibleMode == 'list'" class="list-payout-mode">
                                <label>Primary</label>
                                <el-row :gutter="40" class="m-top-2" v-if="payout_detail.account_number !== null && payout_detail.account_name !== null && payout_detail.bank_name !== null ">
                                    <el-col :span="24" :xs="24">
                                     <div class="list-mode">
                                         <div class="list-logo">

                                             <img src="../../assets/img/settings/paypal.png" class="payout-icon">
                                         </div>
                                         <div class="list-detail">
                                             <span class="big-font">{{payout_detail.account_number}}</span><br>
                                             <span>{{payout_detail.account_name}}</span><br>
                                             <span>{{payout_detail.bank_name}}</span><br>
                                             <span class="text-blue" @click="makePrimary('bank')" v-if="payout_detail.payout_type !== 'bank'">Make Primary</span>
                                         </div>
                                         <div class="list-info text-blue">
                                             <span>Fees: 3.5%</span><br>
                                             <span @click="editPayoutMode('bank')">View / Edit</span>
                                         </div>
                                         <i class="el-dialog__close el-icon el-icon-close remove-payout-mode" @click="removePaymentMode('bank')" v-if="payout_detail.payout_type !== 'bank'"></i>

                                     </div>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="40" class="m-top-2" v-if="payout_detail.email !== null">
                                    <el-col :span="24" :xs="24">
                                     <div class="list-mode">
                                         <div class="list-logo">

                                             <img src="../../assets/img/settings/paypal.png" class="payout-icon">
                                         </div>
                                         <div class="list-detail">
                                             <span class="big-font">{{payout_detail.email}}</span><br>
                                             <span class="text-blue" @click="makePrimary('paypal')" v-if="payout_detail.payout_type !== 'paypal'">Make Primary</span>
                                         </div>
                                         <div class="list-info text-blue">
                                             <span>Fees: 4.7%</span><br>
                                             <span @click="editPayoutMode('paypal')">View / Edit</span>
                                         </div>
                                         <i class="el-dialog__close el-icon el-icon-close remove-payout-mode" @click="removePaymentMode('paypal')" v-if="payout_detail.payout_type !== 'paypal'"></i>
                                     </div>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="40" class="m-top-2" v-if="payout_detail.full_name !== null && payout_detail.cheque_address !== null">
                                    <el-col :span="24" :xs="24">
                                     <div class="list-mode">
                                         <div class="list-logo">

                                             <img src="../../assets/img/settings/cheque.png" class="payout-icon">
                                         </div>
                                         <div class="list-detail">
                                             <span class="big-font">{{payout_detail.full_name}}</span><br>
                                             <span>{{payout_detail.cheque_address}}</span><br>
                                             <span class="text-blue" @click="makePrimary('cheque')" v-if="payout_detail.payout_type !== 'cheque'">Make Primary</span>
                                         </div>
                                         <div class="list-info text-blue">
                                             <span>Fees: 4.7%</span><br>
                                             <span @click="editPayoutMode('cheque')">View / Edit</span>
                                         </div>
                                         <i class="el-dialog__close el-icon el-icon-close remove-payout-mode" @click="removePaymentMode('cheque')" v-if="payout_detail.payout_type !== 'cheque'"></i>
                                     </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="payout_detail" v-if="this.dilogeVisibleMode == 'add'">


                                <el-row :gutter="40" class="m-top-2">
                                    <el-col :span="8" :xs="24" class="text-center">
                                        <img src="../../assets/img/settings/paypal.png" class="payout-icon" @click="primary_payout_mode = 'bank'" :class="{'active': primary_payout_mode == 'bank' }"><br>
                                        <span class="small-font text-blue fees">Fees: 3.5%</span>
                                    </el-col>
                                    <el-col :span="8" :xs="24" class="text-center">
                                        <img src="../../assets/img/settings/paypal.png" class="payout-icon " @click="primary_payout_mode = 'paypal'" :class="{'active': primary_payout_mode == 'paypal' }"><br>
                                        <span class="small-font text-blue">Fees: 4.7%</span>
                                    </el-col>
                                    <el-col :span="8" :xs="24" class="text-center">
                                        <img src="../../assets/img/settings/cheque.png" class="payout-icon " @click="primary_payout_mode = 'cheque'" :class="{'active': primary_payout_mode == 'cheque' }"><br>
                                        <span class="small-font text-blue">Fees: 1.2%</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="40" class="m-top-2">
                                    <el-col :span="24" :xs="24" v-if="primary_payout_mode == 'bank'">
                                    <div class="bank-detail">
                                        <div class="form-group">
                                            <label >Account Number</label>
                                            <input type="text"
                                                    class="form-control"
                                                    v-model="payout_detail.account_number"
                                                    placeholder=""/>

                                        </div>
                                        <div class="form-group">
                                            <label >Account Name</label>
                                            <input type="text"
                                                    class="form-control"
                                                    v-model="payout_detail.account_name"
                                                    placeholder=""/>

                                        </div>
                                        <div class="form-group">
                                            <label >Address</label>
                                            <input type="text"
                                                    class="form-control"
                                                    v-model="payout_detail.address"
                                                    placeholder=""/>

                                        </div>
                                        <div class="form-group">
                                            <label >Bank Name</label>
                                            <input type="text"
                                                    class="form-control"
                                                    v-model="payout_detail.bank_name"
                                                    placeholder=""/>

                                        </div>
                                        <div class="form-group">
                                            <label >Bank Address</label>
                                            <input type="text"
                                                    class="form-control"
                                                    v-model="payout_detail.bank_address"
                                                    placeholder=""/>

                                        </div>
                                        <div class="form-group">
                                            <label >Routing Number</label>
                                            <input type="text"
                                                    class="form-control"
                                                    v-model="payout_detail.routing_number"
                                                    placeholder=""/>

                                        </div>
                                        <div class="form-group">
                                            <label >Swift/BIC Code (if applicable)</label>
                                            <input type="text"
                                                    class="form-control"
                                                    v-model="payout_detail.swift_code"
                                                    placeholder=""/>

                                        </div>
                                        <div class="form-group">
                                            <label >IBAN Code (if applicable)</label>
                                            <input type="text"
                                                    class="form-control"
                                                    v-model="payout_detail.iban_code"
                                                    placeholder=""/>

                                        </div>

                                        <div class="form-group">
                                            <label >Account Type</label>
                                            <label>
                                                <input
                                                        type="radio"
                                                        name="user_type"
                                                        v-model="payout_detail.account_type"
                                                        value="checkings"
                                                />
                                                Checkings
                                            </label>
                                            <label>
                                                <input
                                                        type="radio"
                                                        name="user_type"
                                                        v-model="payout_detail.account_type"
                                                        value="savings"
                                                /> Savings
                                            </label>

                                        </div>

                                    </div>
                                    </el-col>
                                    <el-col :span="24" :xs="24" v-if="primary_payout_mode == 'paypal'">
                                    <div class="paypal-detail">

                                        <div class="form-group">
                                            <label >Paypal Email Address</label>
                                            <input type="text"
                                                   class="form-control"
                                                   v-model="payout_detail.email"
                                                   placeholder=""/>

                                        </div>

                                    </div>
                                    </el-col>
                                    <el-col :span="24" :xs="24" v-if="primary_payout_mode == 'cheque'">
                                    <div class="cheque-detail">
                                        <div class="form-group">
                                            <label >Full Name</label>
                                            <input type="text"
                                                   class="form-control"
                                                   v-model="payout_detail.full_name"
                                                   placeholder=""/>

                                        </div>
                                        <div class="form-group">
                                            <label >Address</label>
                                            <input type="text"
                                                   class="form-control"
                                                   v-model="payout_detail.cheque_address"
                                                   placeholder=""/>

                                        </div>

                                    </div>
                                    </el-col>
                                </el-row>

                            </div>
                        </div>


                        <div slot="footer" class="dialog-footer" v-if="this.dilogeVisibleMode == 'list'">
                            <button class="btn btn-grey" @click="dilogeVisibleMode = 'add'" >Add New</button>
                        </div>
                        <div slot="footer" class="dialog-footer" v-if="this.dilogeVisibleMode == 'add'">
                            <button class="btn btn-grey" @click="showPayoutModes = false" type="gray">Cancel</button>
                            <button class="btn btn-primary" @click="storePayoutDetail()" >Submit</button>
                        </div>

                    </el-dialog>

                </el-card>
            </el-container>
        </div>
    </div>
</template>

<script>
    import RightHeaderFooter from "./Layout/RightHeaderFooter";
    import MobileMenu from './Layout/MobileMenu';
    import ViewTrend from './UserReferral/ViewTrend';
    import ReferralLinks from './UserReferral/ReferralLinks';
    import PromoStats from './UserReferral/PromoStats';
    import Payout from './UserReferral/Payout';
    import ArrowRightIcon from "./../Admin/SvgComponents/ArrowRightIcon";
    import CustomDateRangePicker from "../Contacts/Items/CustomDateRangePicker";
    import LineChart from "../../utils/Chart/LineChart";
    import { customTooltip } from "../../utils/Chart/Tooltip";
    import moment from "moment";
    const cur_date = new Date();
    const conditionTime = {
        timeline_type: "30",
        start_date: moment().subtract(30, 'days').format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD")
    };


    export default {
        name: "UserReferral",
        components: {ViewTrend, RightHeaderFooter, MobileMenu, ReferralLinks, CustomDateRangePicker, PromoStats, Payout, ArrowRightIcon, LineChart},
        props: {
            user: Object
        },
        data: () => ({

            // conditionTime: {
            //     timeline_type: '30',
            //     start_date: `${cur_date.getFullYear()}-${cur_date.getUTCMonth()}-${cur_date.getDate()}`,
            //     end_date: `${cur_date.getFullYear()}-${cur_date.getUTCMonth() + 1}-${cur_date.getDate()}`,
            // },
            isMobile: false,
            uploadw8w9Form: false,
            showPayoutModes: false,
            endpoint: window.app.backendUrl + 'api/user-tax-from',
            invalidFileFormat: false,
            showUploadArea: true,
            upload_in_progress: false,
            primary_payout_mode : 'bank',
            dilogeVisibleMode : 'add',
            payout_detail: {
                // user_id: user.id,
                tax_form_url: null,
                payout_type: 'bank',
                email: null,
                account_number: null,
                account_name: null,
                address: null,
                cheque_address: null,
                bank_name: null,
                bank_address: null,
                routing_number: null,
                swift_code: null,
                iban_code: null,
                full_name: null,
                account_type: 'checkings',


            },
            uploadedFiles: [],
            filename: '',
            filesize: '',
            file: '',
            form_id: null,
            conditionTime,
            startDate: null,
            endDate: null,
            topDetail: [{
                'current_month_earning': 0.00,
                'life_time_earning' : 0.00,
                'current_month_payout' : 0.00,
                'life_time_payout' : 0.00,
                'pending_payout' : 0.00,
                'clawbacks' : 0.00,
            }],
            linksToList: [],
            promoStats: [],
            referrals: [],
            payouts: [],
            trendData:[],
            linksCount: 0,
            ref_count: 0,
            paginate: {
                begin: 0,
                end: 10,
                count: [10, 25, 50, 100],
                view: 10,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                onResize: (chart)=>{
                    if(window.innerWidth <= 414){
                        chart.scales['x-axis-0'].options.ticks.maxTicksLimit = 2
                    }
                },
                events: ["mousemove"], // this is needed, otherwise onHover is not fired
                onHover: (event, chartElement) => {
                    event.target.style.cursor = chartElement[0]
                        ? "pointer"
                        : "default";
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                    mode: "point",
                    intersect: true,
                    custom: customTooltip
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                drawTicks: false,
                                color: "#EAF0F4",
                                zeroLineColor: "#EAF0F4"
                            },
                            ticks: {
                                beginAtZero: true,
                                padding: 10
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                drawTicks: false,
                                color: "transparent"
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 7,
                                lineHeight: "normal",
                                maxRotation: 0,
                                padding: 10
                            }
                        }
                    ]
                }
            },
            chartData: {
                labels: [],
                datasets: []
            },
            // trendData:[],
            command_ary: [
                { value: 0, label: "clicks", color: "#0072A5" },
                { value: 1, label: "signups", color: "#56D9FE" },
                { value: 2, label: "upgraded", color: "#00d34b" },
                { value: 3, label: "earnings", color: "#AB7DF6" },
                { value: 4, label: "clawbacks", color: "#ae2e91" },
            ],
            checkedLegend: [0, 1]

        }),
        mounted(){

            this.getLinkss();
            // console.log(this.topDetail, this.linksToList, this.promoStats, this.referrals, this.payouts, '--------')
        },

        created(){
            this.getTrendData();
            this.getEarningPayoutDetail();

            this.fillData('Clicks','Sign Ups', this.trendData.trend.clicks, this.trendData.trend.signups)

            this.mobileCheck();
            // console.log(this.user.referral_payout_detail, 'payout_detail');
            if(this.user.referral_payout_detail !== null){
                this.payout_detail = this.user.referral_payout_detail;
                this.dilogeVisibleMode = 'list';

                if(this.payout_detail.tax_form_url !== null){
                    this.filename = this.payout_detail.original_name ;
                    this.filesize = this.payout_detail.tax_form_size ;
                    this.form_id = this.payout_detail.id ;
                    this.showUploadArea = false ;
                }
            }

            // console.log(this.user, 'llskdslkd');
        },
        methods: {

            paginateNext()
            {
                if(this.paginate.begin + (this.paginate.end - this.paginate.begin) < this.linksCount) {
                    var $this = this;
                    var lastId=0;

                    this.linksToList.forEach(function (link, index) {
                        if ((index + 1) == $this.linksToList.length) {
                            lastId = link.id;
                        }
                    });

                    this.paginate.end = parseInt(this.paginate.end) + parseInt(this.paginate.view);
                    this.paginate.begin = parseInt(this.paginate.begin) + parseInt(this.paginate.view);
                    this.getLinkss('>', lastId);
                }
            },
            paginatePrev()
            {
                if(this.paginate.end - this.paginate.begin >= 0) {
                    // const lastId = Math.max.apply(Math, this.linksToList.map(function(o) { return o.id; }))
                    var $this = this;
                    var lastId=0;

                    this.linksToList.forEach(function (link, index) {
                        if (index  == 0) {
                            lastId = link.id;
                        }
                    });
                    this.paginate.end = parseInt(this.paginate.end) - parseInt(this.paginate.view);
                    this.paginate.begin = parseInt(this.paginate.begin) - parseInt(this.paginate.view);
                    this.getLinkss('<', lastId);
                }
            },
            paginateView(event)
            {
                this.paginate.end = event.target.value;
                this.paginate.begin = 0;
                this.paginate.view = event.target.value;

                this.getLinkss();
            },

            getTrendData(type){
                const queryParams = `?end_date=${this.conditionTime.end_date}&start_date=${this.conditionTime.start_date}`
                var $this = this;
                this.axios({
                    method: 'post',
                    url: `/trend-data${queryParams}`,
                    // data: {type: type}
                }).then(function (response) {
                    $this.trendData = response.data.trends
                    $this.fillData('Clicks','Sign Ups', $this.trendData.trend.clicks, $this.trendData.trend.signups);
                })
            },
            getLinkss(direction = 'increase', begin = 0, type = 'links') {
                const queryParams = `?end_date=${this.conditionTime.end_date}&start_date=${this.conditionTime.start_date}&begin=${begin}&limit=${this.paginate.view}&direction=${direction}&view=${this.paginate.view}&type=${type}`
                var $this = this;
                this.axios({
                    method: 'get',
                    url: `/user-links${queryParams}`,
                    data: {
                        data: true
                    },
                }).then(response => {
                    $this.linksToList = response.data.links;
                    $this.promoStats = response.data.promoStats;
                    $this.referrals = response.data.referrals;
                    $this.payouts = response.data.payouts;


                    $this.linksCount = response.data.total_links;
                    $this.ref_count = response.data.ref_count;

                    if(direction == '<'){
                        if(type == 'link'){

                            $this.linksToList = $this.linksToList.reverse();
                        }

                    }
                    // console.log(response.data, 'linksToList 123  1');

                });

            },
            changeLegend(flag) {
                let checkedLeg = this.checkedLegend[flag];
                let sel_item = this.command_ary[checkedLeg];
                if (document.querySelector("#legend-dropdown")) {
                    let input_div = document.querySelectorAll(
                        `#legend-dropdown .custom-el-select`
                    )[flag].childNodes;
                    // $(this).parents('.legend-dropdown')

                    var data = [];
                    this.command_ary.forEach((ele, index) => {
                        if(this.checkedLegend.includes(index)){
                            data.push(ele.label)
                        }
                        // var clicks = this.trendData.trend.clicks;
                    })

                    if(data[0] == 'clicks'){
                        var label1 = 'Clicks';
                        var data1 = this.trendData.trend.clicks;

                    }
                    if(data[0] == 'signups'){
                        var label1 = 'Sign Ups';
                        var data1 = this.trendData.trend.signups;

                    }
                    if(data[0] == 'upgraded'){
                        var label1 = 'Upgraded';
                        var data1 = this.trendData.trend.upgraded;

                    }
                    if(data[0] == 'earnings'){
                        var label1 = 'Earnings';
                        var data1 = this.trendData.trend.earnings;

                    }
                    if(data[0] == 'clawbacks'){
                        var label2 = 'Clawbacks';
                        var data2 = this.trendData.trend.clawbacks;

                    }

                    if(data[1] == 'clicks'){
                        var label2 = 'Clicks';
                        var data2 = this.trendData.trend.clicks;

                    }
                    if(data[1] == 'signups'){
                        var label2 = 'Sign Ups';
                        var data2 = this.trendData.trend.signups;

                    }
                    if(data[1] == 'upgraded'){
                        var label2 = 'Upgraded';
                        var data2 = this.trendData.trend.upgraded;

                    }
                    if(data[1] == 'earnings'){
                        var label2 = 'Earnings';
                        var data2 = this.trendData.trend.earnings;

                    }
                    if(data[1] == 'clawbacks'){
                        var label2 = 'Clawbacks';
                        var data2 = this.trendData.trend.clawbacks;

                    }




                    this.fillData(label1, label2, data1, data2);
                    // console.log(data, this.checkedLegend, sel_item, 'sel_item');


                }


                // window.vEvent.fire("change-engagement-chart", this.checkedLegend);
            },
            fillData(label1, label2, data1, data2) {
                console.log(label1, label2, data1, data2);
                if (document.querySelector('#line-chart')) {
                    let gradient = document.querySelector('#line-chart').getContext("2d").createLinearGradient(0, 0, 0, 300);
                    gradient.addColorStop(0, 'rgba(90, 45, 219, 1)');
                    gradient.addColorStop(0.5, 'rgba(90, 45, 219, 0.5)');
                    gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');

                    let gradient1 = document.querySelector('#line-chart').getContext("2d").createLinearGradient(0, 0, 0, 300);
                    gradient1.addColorStop(0, 'rgba(86, 217, 255, 1)');
                    gradient1.addColorStop(0.5, 'rgba(86, 217, 255, 0.5)');
                    gradient1.addColorStop(1, 'rgba(255, 255, 255, 0.8)');

                    this.chartData = {
                        labels: this.trendData.trend.xLabel,
                        datasets: [
                            {
                                label: label1,
                                backgroundColor: gradient,
                                borderColor: 'rgba(90, 45, 219, 0.7)',
                                data: data1,
                                lineTension: 0.4,
                                fill: false,
                                pointHoverRadius: 3,
                                pointHoverBackgroundColor: 'rgba(90, 45, 219, 0.7)'
                            }, {
                                label: label2,
                                backgroundColor: gradient1,
                                borderColor: 'rgba(86, 217, 255, 0.7)',
                                data: data2,
                                lineTension: 0.4,
                                fill: false,
                                pointHoverRadius: 3,
                                pointHoverBackgroundColor: 'rgba(86, 217, 255, 0.7)'
                            }
                        ]
                    }
                }
            },
            getEarningPayoutDetail(){


                var $this = this;
                this.axios({
                    method: 'get',
                    url: '/getEarningPayoutDetail',
                }).then(function (response) {
                    $this.topDetail = response.data.topDetail;
                })

            },
            getFilteredData(date){

                console.log(start_date, 'change');
            },
            bytesToSize(bytes) {
                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                if (bytes == 0) return '0 Byte';
                var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
            },
            removeForm(){

                if(this.form_id !== null ){
                    console.log(this.form_id, 'form_id');

                    var $this = this;
                    this.axios({
                        method: 'delete',
                        url: '/remove-tax-form/' + this.form_id,
                    }).then(function (response) {
                        $this.payout_detail = response.data;
                        $this.showUploadArea = true
                    })

                }else{
                    this.showUploadArea = true
                }
            },
            handleFileUpload(){
                this.file = this.$refs.file.files[0];
                console.log(this.file, 'this.file');
                this.filename = this.file.name;
                this.filesize = this.file.size;
                this.showUploadArea = false
            },
            removePaymentMode(type){
                var $this = this;
                this.axios({
                    method: 'delete',
                    url: '/remove-payment-mode',
                    data: {type: type}
                }).then(function (response) {
                    $this.payout_detail = response.data;
                })
            },
            makePrimary(type){
                var $this = this;
                this.axios({
                    method: 'post',
                    url: '/make-primary',
                    data: {type: type}
                }).then(function (response) {
                    $this.payout_detail = response.data;
                    $this.primary_payout_mode = response.data.payout_type;
                    $this.dilogeVisibleMode = 'list';
                })
            },
            editPayoutMode(type){
                this.primary_payout_mode = type;
                this.dilogeVisibleMode = 'add'
            },
            storePayoutDetail(){
                var $this = this;

                this.payout_detail.payout_type = this.primary_payout_mode;
                this.axios({
                    method: 'post',
                    url: '/store-payout-detail',
                    data: this.payout_detail
                }).then(function (response) {
                    $this.payout_detail = response.data;
                    $this.dilogeVisibleMode = 'list';
                })
            },
            uploadw8w9form(){
                var $this = this;
                let formData = new FormData();
                formData.append('file', this.file);
                this.axios({
                    method: 'post',
                    url: '/user-tax-from',
                    data: formData
                }).then(function (response) {
                    $this.form_id = response.data.detail.id
                })

            },
            handleImageUploadProgress(ev, file, rawFile) {
                this.upload_in_progress = true;
                if (ev.total > 0) {
                    let percentage = parseInt(ev.loaded / ev.total * 100) || 0;
                    if (percentage === 100) {
                        this.$Progress.finish();
                        this.upload_in_progress = false;
                    } else {
                        this.$Progress.update(percentage);
                    }
                }
            },
            handleBeforeImageUpload(file) {

                // const isLt2M = file.size / 1024 / 1024 < 8;
                // if (!isLt2M) {
                //     this.$message.error('Image picture size can not exceed 8MB!');
                //     return false;
                // }
                console.log(file.type, '///???');

                var allowedType = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword'];
                // var allowedType = ["application/x-subrip", 'text/vtt'];
                // var valid = file.type.match(allowedType);
                // if (!valid) {
                if (allowedType.indexOf(file.type) === -1) {
                    this.invalidFileFormat = true;
                    document.getElementById('upload_w8_file').classList.add('error')
                    return false;
                } else {
                    // this.$message.error('Image picture must be SRT format!');
                    document.getElementById('upload_w8_file').classList.remove('error')
                    this.invalidFileFormat = false;
                    this.$Progress.start();
                    return true;
                }
            },
            handleImageUploadSuccess(res, file) {

                this.upload_in_progress = false;

                if (res.filename !== '') {
                    this.showFile = true;
                    this.showUploadArea = false;
                    this.fileList = res.filename;

                    this.uploadedFiles.push({
                        name: file.name,
                        size: file.size,
                    })
                    // this.fileList.push(res.filename);
                }


            },
            mobileCheck() {
                let BC_onMobile = false;
                if (window.screen.availWidth <= 414 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }

                this.isMobile = BC_onMobile;
            },
            getNewDateRange(date) {
                const { start_date, end_date } = this.conditionTime;
                if (start_date === end_date) {
                    this.filter = {
                        ...this.filter,
                        createdAt: {
                            equal: start_date
                        }
                    };
                } else {
                    const endDateForQuery = moment(end_date)
                        .add(1, "d")
                        .format("YYYY-MM-DD");
                    this.filter = {
                        ...this.filter,
                        createdAt: {
                            between: { from: start_date, to: endDateForQuery }
                        }
                    };
                }
                this.startDate = start_date;
                this.endDate = end_date;
                // this.$emit('open-image-library', model)
                console.log(start_date, end_date, '/', this.startDate, this.endDate);

                this.getLinkss();
                this.getTrendData();
                // this.getUsers(this.id);
            },
        },

    };
</script>

<style lang="less">
.user-referral-area{
    .pagination {
        padding: 15px 0px;
        .pagination-left {
            width: 123px;
            height: 31px;
            display: flex;
            flex-direction: row;
            border: 1px solid #C7E9F2;
            justify-content: space-between;
            border-radius: 20px;
            .pagination-left-title {
                color: var(--color-content);
                padding: 5px 15px;
                border-right: 1px solid #C7E9F2;
            }
            .pagination-left-count {
                color: var(--color-content);
                padding: 5px 15px 5px 0px;
                select {
                    background: transparent !important;
                    border: transparent !important;
                    outline: none !important;
                }
            }
        }
        .pagination-right {
            width: 157px;
            height: 31px;
            border-radius: 20px;
            border: 1px solid #C7E9F2;
            padding: 0px 15px;
            .pagination-right-count {
                padding: 5px 10px;
                border-left: 1px solid #C7E9F2;
                border-right: 1px solid #C7E9F2;
            }
            .pagination-right-next,
            .pagination-right-prev {
                padding: 5px 0px;
            }
            .pagination-right-prev svg {
                transform: rotate(180deg);
            }
        }
        .disabled {
            opacity: 0.4;
        }
    }
    .list-form {
        .remove-form-icon{
            background-color: #ffd3d3;
            padding: 5px;
            border-radius: 50%;
            color: #c84343;
        }
        display: flex;
        /* flex: 0 0 20%; */
        font-size: 15px;

        .file_size {
            position: absolute;
            right: 20%;
        }

        .remove_file {
            position: absolute;
            right: 0;
        }
    }

    .list-form > div {
        padding: 5px;
    }

    .el-dialog__title{
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: normal;
        font-size: 15px;
        color: var(--color-content);
    }
    .header-title{
        margin: 30px 0;
        span{
            font-size: 28px;
        }
    }
    #id_user_referral{
        max-width: 1000px;
    }
    .settings-right-header-footer .header-footer-left-side.title{
        width: 33% !important;
    }
    .settings-right-header-footer .header-footer-right-side{
        position: unset !important;
    }
    .capital-transform{
        text-transform: uppercase;
    }
    .text-green{
        color: #19d2a8 !important;
    }
    .text-blue{
        color: #33abd8 !important;
    }
    .text-orange{
        color: #e3a824 !important;
    }
    .text-red{
        color: #eb5217 !important
    }
    .big-font{
        font-size: 25px;
    }
    .small-font{
        font-size: 13px;
        font-weight: 500;
    }
    .font-bold{
        font-weight: 600;
    }
    .medium-font{
        font-size: 18px !important;
    }
}

.upload_w8_file.error {
    .inner {
        color: var(--color-red);
        background: #f76e2c14;
    }
}

.inner.error{
    background: #fcf3ee !important;
    color: var(--color-red) !important;
}
    .payout-icon{
        height: 100px;
        padding: 15px;
        cursor: pointer;
    }
.payout-icon.active{

    border-radius: 10px;
    background-color: #b4eaf92b;

    }
.upload_w8_file {
    width: 100%;
    display: inline-block;
    position: relative;
    /*padding: 15px;*/
    cursor: pointer;

    &.full-width {
        display: block;
        width: 100%;
    }

    .el-upload {
        display: block;
    }

    .el-upload-dragger {
        width: 100% !important;
        border: none !important;
        height: auto;
    }

    .el-upload__input {
        display: none !important;
    }

    .inner {
        height: 90px;
        background: #F4FDFF;
        color: #00ACDC;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        font-size: 14px;
    }
}
    .list-payout-mode{
        .list-mode{
            display: flex;
            flex-wrap: wrap;
            position: relative;
            .list-logo{
                flex: 0 0 20%;
            }
            .list-detail{
                flex: 0 0 60%;
                margin: auto 0;
            }
            .list-info{
                flex: 0 0 20%;
                margin: auto 0;
            }
            .remove-payout-mode{
                position: absolute;
                right: 10px;
                top: 38%;
                background: #fb878761;
                color: #d44747;
                padding: 5px;
                border-radius: 50%;
                cursor: pointer;
                visibility: hidden;
            }
        }
        .list-mode:hover{
            .remove-payout-mode {
                visibility: visible;
            }

            background-color: aliceblue;
            border-radius: 5px;

        }

    }
.legend-dropdown{
    display: block !important;
    .el-input__inner{
        background-color: #cff3ff;
        border-radius: 25px;
        border: 1px solid #37b6e7 !important;
        height: 28px
    }

}
</style>


