<template>
    <el-dialog
        title="SWITCH TO ANNUAL BILLING"
        :visible.sync="dialogVisible"
        v-if="subscriptionData.plan.interval === 'monthly'"
      >
        <p class="bold mb-1">
          You are about to switch your billing cycle from monthly to anually.
        </p>
        <p>By switching to annual billing you save . Here's a quick breakdown</p>
        <div style="text-align: center;">
          <ul v-if="annualPlan" class="list-group-item">
            <li>
              <span> {{ subscriptionData.plan.name }} Plan Annual Billing </span>
              <div
                class="d-flex flex-direction-column"
                style="align-items: flex-end;"
              >
                <span class="bold">
                  ${{ annualPlan.price }}
                </span>
                <small class="flex"
                  >instead of<del>
                    ${{ subscriptionData.plan.price * 12 }}</del
                  ></small
                >
              </div>
            </li>
            <li>
              <span>
                Credit from current billing cycle
              </span>
              <span class="bold green"> - ${{ planData.credit }} </span>
            </li>
            <li>
              <span>
                Due today
              </span>
              <span class="bold">
                ${{ (planData.debit - planData.credit).toFixed(2) }} + Taxes
              </span>
            </li>
          </ul>
        </div>
        <el-checkbox class="" v-model="updateAccountAllow"
          >
            <span :class='updateError ? "text-danger" : ""'>
                Upgrade my account and change my billing method
            </span>
          </el-checkbox
        >
        <!-- <div class="billing-type-column">
          <div
            v-if="userInfo.payment_method.toLowerCase() === 'paypal'"
            class="billing-type"
          >
            <paypal-icon class="billing-icon" />
            <div>Pay with PayPal</div>
          </div>
          <div
            v-if="userInfo.payment_method.toLowerCase() === 'stripe'"
            class="billing-type"
          >
            <visa-icon class="billing-icon" />
            <div>
              &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
              &bull;&bull;&bull;&bull; {{ userInfo.card_last_four }}
            </div>
          </div>
          <div
            v-if="userInfo.payment_method.toLowerCase() === 'stripe'"
            class="billing-card"
          >
            Expires {{ `${userInfo.exp_month}/${userInfo.exp_year}` }}
            <a href="" style="padding-left: 60px;">Edit Payment Information </a>
          </div>
          <div
            v-if="userInfo.payment_method.toLowerCase() === 'paypal'"
            class="billing-email"
          >
            {{ userInfo.paypal_email }}
          </div>
        </div> -->
        <span slot="footer" class="dialog-footer">
          <el-button
            class="custom-btn custom-cancel-btn"
            @click="$emit('close')"
            >Cancel</el-button
          >
          <el-button
            class="custom-btn custom-confirme-btn"
            type="primary"
            @click="changePlan"
            >Switch to Annual Billing</el-button
          >
        </span>
    </el-dialog>
</template>

<style lang="less" scoped>
.el-dialog__wrapper {
  .billing-type-column {
    padding-left: 25px;
    .billing-type {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;

      .billing-icon {
        margin-right: 20px;
      }
    }
    .billing-email {
      margin-bottom: 40px;
      font-size: 16px;
    }

    .billing-card {
      margin-bottom: 15px;
      font-size: 13px;
    }
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #0f0f0f;
  }
  .el-dialog {
    border: 1px solid #0bacdb;
    border-radius: 10px;
    max-width: 635px;
    .bold {
      font-weight: bold;
    }
    .green {
      color: #0ad688;
    }
    .text-wrap-normal {
      white-space: normal !important;
    }
    .el-dialog__header {
      text-align: center;
      border-bottom: 1px solid #ebf9fa;
      padding-bottom: 15px;
      .el-dialog__title {
        color: #21455e;
        font-size: 15px;
      }
    }
    .el-dialog__body {
      padding: 15px 45px;
      p {
        color: #21455e;
        font-size: 14px;
      }
      .el-radio-group {
        margin-top: 5px;
        margin-bottom: 10px;
        .el-radio__inner,
        .el-radio__inner:hover {
          width: 16px;
          height: 16px;
          border: 2px solid #21455e;
        }
        .el-radio__inner::after {
          width: 8px;
          height: 8px;
          background-color: #21455e;
        }
        label.el-radio {
          display: block;
          margin-left: 0 !important;
        }
        .el-radio__input.is-checked .el-radio__inner {
          background: #ffffff;
        }
      }
      .el-radio__label {
        color: #21455e;
        font-size: 14px;
        font-weight: 100;
        padding-left: 18px;
      }
      .list-group-item {
        list-style: none;
        padding-left: 0;
        display: inline-block;
        max-width: 350px;
        width: 100%;
        li {
          display: flex;
          padding: 15px 0;
          justify-content: space-between;
          border-bottom: solid 1px #e8e8ea;
        }
      }
    }
  }
  .el-dialog__footer {
    text-align: center;
    padding: 20px;
    .dialog-footer {
      .custom-btn {
        border-radius: 15px;
        &.custom-cancel-btn {
          background-color: #637c8e;
          color: #ffffff;
        }
        .custom-confirme-btn {
        }
      }
    }
  }
  label.el-checkbox {
    display: flex;
    .el-checkbox__label {
      margin-top: -2px;
    }
  }
}
</style>

<script>
import moment from 'moment';
import VisaIcon from "../../../Settings/Icons/VisaIcon";
import CardIcon from "../../../Settings/Icons/CardIcon";
import BigPaypalIcon from "../../../Settings/Icons/BigPaypalIcon";

export default {
    name: 'SwitchAnnualDialog',
    props: ['dialogVisible', 'subscriptionData', 'annualPlan', 'updateAccount', 'userInfo', 'planData'],
    components: {
        VisaIcon,
        CardIcon,
        BigPaypalIcon
    },
    data: () => ({
        updateError: false,
        updateAccountAllow: false,
    }),
    mounted() {
      const { updateAccount } = this.$props;
      this.updateAccountAllow = updateAccount;
    },
    methods: {
        getFirstBillCredit() {
            let daysInInterval = this.dateDiff(
                this.subscriptionData.subscription.updated_at,
                this.subscriptionData.subscription.ends_at
            );
            let daysRemain = this.dateDiff(
                new Date().toString(),
                this.subscriptionData.subscription.ends_at
            );
            let credit =
                (daysRemain * this.subscriptionData.plan.price) / daysInInterval;
            return credit.toFixed(2);
            },
        dateDiff(from, to) {
            return moment(to).diff(moment(from), "days");
        },
        changePlan()
        {
            if (!this.$props.updateAccount) {
                this.updateError = true;
                return false;
            }
            this.$emit('change');
        }
    },
    watch: {
      updateAccountAllow(val) {
        this.$emit('updateAccount', val);
      }
    }
}
</script>