<template>
    <div class="col-md-2">
        <el-dropdown placement="bottom">
            <el-button type="default" round>
                <svg class="filter__icon" width="16.913" height="8.81" viewBox="0 0 16.913 8.81">
                    <g id="Group_5905" data-name="Group 5905" transform="translate(-316.85 -248.85)">
                        <line id="Line_353" data-name="Line 353" x2="15.613" transform="translate(317.5 249.5)" fill="none" stroke="#778d9d" stroke-linecap="round" stroke-width="1.3"/>
                        <line id="Line_353-2" data-name="Line 353" x2="12" transform="translate(317.5 253.255)" fill="none" stroke="#778d9d" stroke-linecap="round" stroke-width="1.3"/>
                        <line id="Line_353-3" data-name="Line 353" x2="8" transform="translate(317.5 257.01)" fill="none" stroke="#778d9d" stroke-linecap="round" stroke-width="1.3"/>
                    </g>
                </svg>
                <span class="filter__name">By </span><span class="sort-by__value">{{ selectedSort }}</span>
                <svg class="icon-arrow__down" xmlns="http://www.w3.org/2000/svg" width="9.709" height="5.395" viewBox="0 0 9.709 5.395">
                    <path id="Path_2500" data-name="Path 2500" d="M15.582,33.5a.328.328,0,0,0-.463,0l-4.075,4.083L6.959,33.5a.328.328,0,1,0-.463.463L10.8,38.267a.32.32,0,0,0,.232.1.334.334,0,0,0,.232-.1l4.307-4.307A.321.321,0,0,0,15.582,33.5Z" transform="translate(-6.168 -33.168)" fill="#778d9d" stroke="#778d9d" stroke-width="0.4"/>
                </svg>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="filter__dropdown__menu custom_dropdown dropdown_bottom_200">
                <el-dropdown-item v-for='option in sortOptons' :key='option'>
                    <div :data-value='option' @click='sort' class='dropdown-options'>
                        {{ option }}
                    </div>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<style scoped>
    .el-dropdown .el-button .icon-arrow__down {
        margin-left: 10px;
        vertical-align: middle;
    }

    .el-dropdown .el-button .filter__icon {
        margin-right: 12px;
        vertical-align: middle;
    }

    .el-dropdown .el-button span {
        vertical-align: middle;
    }

    .el-dropdown .el-button .sort-by__value {
        display: inline-block;
        min-width: 35px;
        text-transform: capitalize;
        text-align: left;
        vertical-align: middle;
    }

    .el-dropdown .el-button {
        padding: 5px 12px 5px 15px !important;
        background-color: var(--color-grey-light);
        border: none;
        color: var(--color-grey);
        font-size: 13px;
        /* line-height: 17px; */
        border-radius: 30px !important;
    }

    .el-dropdown-menu__item {
        list-style: none;
        line-height: 36px;
        padding: 0 10px;
        margin: 0;
        cursor: pointer;
    }

    .filter__dropdown__menu {
        list-style: none;
        width: 200px;
        position: relative;
        border: 1px solid var(--color-blue);
        border-radius: 5px;
        margin-top: 15px;
        padding: 15px 0 10px;
        font-family: "Helvetica Neue";
    }

    .filter__dropdown__menu::before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        top: -8px;
        left: calc(50%);
        transform: rotate(45deg);
        border: 1px solid var(--color-blue);
        border-bottom-color: transparent;
        border-right-color: transparent;
        background-color: white;
        border-radius: 3px;
    }

    .dropdown-options:hover {
        color: var(--color-blue);
    }
</style>

<script>
export default {
    props: ['videoList'],
    data: () => ({
        sortOptons: ['Date', 'Title', 'Views', 'Clicks', 'Leads'],
        selectedSort: 'Date'
    }),
    methods: {
        sort(e)
        {
            let value = e.currentTarget.getAttribute('data-value');
            this.selectedSort = value;
            this.sortVideos(value);
        },
        sortVideos(by)
        {
            switch (by) {
                case 'Date':
                    this.sortByDate();
                break;
                case 'Title':
                    this.sortByTitle();
                break;
                case 'Views':
                    this.sortByViews();
                break;
                case 'Leads':
                    this.sortByLeads();
                break;
                case 'Clicks':
                    this.sortByClicks();
                break;
            }
        },
        sortByDate()
        {
            let data = this.$props.videoList;

            data = data.sort((a, b) => {
                let dateA = new Date(a.created_at);
                let dateB = new Date(b.created_at);
                return dateA - dateB;
            }).reverse();
            this.$emit('list', data);
        },
        sortByTitle()
        {
            let data = this.$props.videoList;
            data = data.sort((a, b) => {
                let titleA = a.title;
                let titleB = b.title;
                return titleA.localeCompare(titleB);
            });
            this.$emit('list', data);
        },
        sortByViews()
        {
            let data = this.$props.videoList;

            data = data.sort((a, b) => {
                let viewsA = parseInt(a.views_count);
                let viewsB = parseInt(b.views_count);
                return viewsA - viewsB;
            }).reverse();
            this.$emit('list', data);
        },
        sortByLeads()
        {
            let data = this.$props.videoList;

            data = data.sort((a, b) => {
                let leadsA = parseInt(a.leads);
                let leadsB = parseInt(b.leads);
                return leadsA - leadsB;
            }).reverse();
            this.$emit('list', data);
        },
        sortByClicks()
        {
            let data = this.$props.videoList;

            data = data.sort((a, b) => {
                let clicksA = parseInt(a.clicks);
                let clicksB = parseInt(b.clicks);
                return clicksA - clicksB;
            }).reverse();
            this.$emit('list', data);
        }
    },
}
</script>