<template>
  <div class="block-with-bar">
    <div class="block-with-bar__row-one">
      <div class="block-with-bar__col-one">
        <div v-if="mainHeading" class="col-one__main-heading">{{ mainHeading }}</div>
        <div v-if="subHeading" class="col-one__sub-heading">
          {{ subHeading }}
          <span
            v-if="resetDate"
            class="col-one__reset-date"
          >{{ `(Resets in ${resetDate} days)` }}</span>
        </div>
      </div>
      <div class="block-with-bar__col-two">
        <div class="modal-links">
          <div
            @click="clickLink('add-bandwidth')"
            class="modal-link modal-link__add"
            v-if="addBandwidth"
          >Add bandwidth</div>
          <div
            @click="clickLink('edit-bonus')"
            class="modal-link modal-link__edit-bonus"
            v-if="editBonus"
          >Edit bonus</div>
          <div
            @click="clickLink('remove-bonus')"
            class="modal-link modal-link__remove-bonus"
            v-if="removeBonus"
          >Remove bonus</div>
        </div>
        <div class="col-two__amount">
          <div class="col-two__amount-spent">{{ amountSpent }}</div>
          <div class="col-two__amount-allowed">&ensp;{{ `/ ${amountAllowed}` }}</div>
        </div>
      </div>
    </div>
    <div class="block-with-bar__row-two">
      <el-progress
        :color="customColor"
        :percentage="+percentage"
        :stroke-width="16"
        :show-text="false"
        :stroke-linecap="'square'"
      ></el-progress>
    </div>
  </div>
</template>
<script>
export default {
  name: "BlockWithBar",
  props: {
    mainHeading: String,
    subHeading: String,
    addBandwidth: {
      default: false
    },
    editBonus: {
      default: false
    },
    removeBonus: {
      default: false
    },
    amountSpent: {
      type: [Number, String],
      default: ""
    },
    amountAllowed: {
      default: "unlimited"
    },
    percentage: {
      default: "0"
    },
    resetDate: {
      default: ""
    }
  },
  computed: {
    customColor: function() {
      if (this.percentage <= 90) {
        return "#0AD688";
      }
      if (this.percentage < 100) {
        return "#F27500";
      }

      if (this.percentage == 100) {
        return "#EE5951";
      }
    }
  },
  methods: {
    clickLink(evt) {
      this.$emit(evt);
    }
  }
};
</script>
<style lang="less" scoped>
.block-with-bar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 45px;
  margin-bottom: 20px;

  .block-with-bar__col-one,
  .block-with-bar__col-two {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: flex-end;
  }

  .block-with-bar__row-one,
  .block-with-bar__row-two {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .col-one__main-heading,
  .col-one__sub-heading,
  .modal-links,
  .col-two__amount,
  .block-with-bar__row-two {
    margin-top: 15px;
    .el-progress--line {
      width: 100%;
    }
  }

  .col-two__amount {
    display: flex;
    align-self: flex-end;
  }

  .col-one__main-heading {
    font-weight: 700;
  }

  .col-one__reset-date,
  .col-two__amount-allowed {
    color: #637d8e;
  }

  .col-two__amount-allowed {
    text-transform: uppercase;
  }

  .modal-links {
    display: flex;
    justify-content: flex-end;
  }

  .modal-link {
    color: #00acdc;
    cursor: pointer;
  }

  .modal-link__remove-bonus {
    margin-left: 19px;
  }

  .col-two__amount {
    display: flex;
    justify-content: flex-end;
    .col-two__amount-spent {
      font-size: 14px;
    }
  }
}
</style>