<template>
    <svg
        id="noun_Info_2392584"
        xmlns="http://www.w3.org/2000/svg"
        width="12.529"
        height="12.529"
        viewBox="0 0 12.529 12.529"
    >
        <path
            id="Path_9200"
            data-name="Path 9200"
            d="M11.265,5a6.265,6.265,0,1,0,6.265,6.265A6.265,6.265,0,0,0,11.265,5Zm.783,9.237a.783.783,0,1,1-1.566,0V10.716a.783.783,0,1,1,1.566,0Zm-.783-5.162a.783.783,0,1,1,.783-.783.783.783,0,0,1-.783.783Z"
            transform="translate(-5 -5)"
            class="hover-icon"
        />
    </svg>
</template>

<script>
export default {
    name: "InfoMarkIcon",
    props: {
        color: Boolean,
    },
    watch: {
        color: function(newVal) {
            console.log('color newVal, ', newVal)
        }
    }
};
</script>

<style lang="less" scoped>
.hover-icon {
    fill: #647d8e;

    &:hover {
        fill: #21455e
    }
}
</style>

