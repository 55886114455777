<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37.487"
        height="28.862"
        viewBox="0 0 37.487 28.862"
    >
        <g id="credit-card" transform="translate(0.1 0.1)">
            <g id="Group_7961" data-name="Group 7961" transform="translate(0 0)">
                <g id="Group_7960" data-name="Group 7960">
                    <path
                        id="Path_7993"
                        data-name="Path 7993"
                        d="M34.174,67.686H30.38V62.33a3.116,3.116,0,0,0-3.113-3.113H3.113A3.117,3.117,0,0,0,0,62.33V76.3A3.116,3.116,0,0,0,3.113,79.41H6.907v5.356a3.116,3.116,0,0,0,3.113,3.113H34.174a3.116,3.116,0,0,0,3.113-3.113V70.8A3.116,3.116,0,0,0,34.174,67.686ZM1.779,62.33A1.336,1.336,0,0,1,3.113,61H27.267A1.336,1.336,0,0,1,28.6,62.33v1.242H1.779ZM6.907,70.8v6.833H3.113A1.336,1.336,0,0,1,1.779,76.3V69.47H7.206A3.093,3.093,0,0,0,6.907,70.8ZM1.779,67.691V65.351H28.6v2.335H10.02c-.06,0-.12,0-.179.006ZM34.174,86.1H10.02a1.336,1.336,0,0,1-1.334-1.334V70.8A1.335,1.335,0,0,1,9.911,69.47H29.49a.888.888,0,0,0,.1-.006h4.587A1.336,1.336,0,0,1,35.508,70.8V84.766h0A1.336,1.336,0,0,1,34.174,86.1Z"
                        transform="translate(0 -59.217)"
                        fill="#21455e"
                        stroke="#fff"
                        stroke-width="0.2"
                    />
                </g>
            </g>
            <g id="Group_7963" data-name="Group 7963" transform="translate(15.13 14.277)">
                <g id="Group_7962" data-name="Group 7962">
                    <path
                        id="Path_7994"
                        data-name="Path 7994"
                        d="M217.406,255.266a4.266,4.266,0,0,0-2.676.941,4.288,4.288,0,1,0,0,6.692,4.286,4.286,0,1,0,2.676-7.634Zm-3.806,6.258a2.509,2.509,0,1,1,0-3.941,4.272,4.272,0,0,0,0,3.941Zm3.806.538a2.509,2.509,0,1,1,2.508-2.509A2.511,2.511,0,0,1,217.406,262.061Z"
                        transform="translate(-207.76 -255.265)"
                        fill="#21455e"
                        stroke="#fff"
                        stroke-width="0.2"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "CardIcon",
    props: {
        color: {
            type: String,
            default: "#00acdc"
        }
    }
};
</script>

<style lang="less" scoped>
</style>

