<template>
    <svg
        id="paypal_1_"
        data-name="paypal (1)"
        xmlns="http://www.w3.org/2000/svg"
        width="26.478"
        height="31.703"
        viewBox="0 0 26.478 31.703"
    >
        <path
            id="Path_411"
            data-name="Path 411"
            d="M66.137,8.191a10.9,10.9,0,0,0,.082-1.331A6.86,6.86,0,0,0,59.359,0H47.895a1.477,1.477,0,0,0-1.456,1.232L42.214,26.339a1.477,1.477,0,0,0,1.456,1.722h4.193a1.489,1.489,0,0,0,1.468-1.232l.02-.117h0l-.584,3.473A1.3,1.3,0,0,0,50.05,31.7h3.668A1.3,1.3,0,0,0,55,30.617l1.042-6.193a2.217,2.217,0,0,1,2.186-1.849h.964A9.477,9.477,0,0,0,68.671,13.1,6.011,6.011,0,0,0,66.137,8.191Z"
            transform="translate(-42.193 0)"
            fill="#002987"
        />
        <path
            id="Path_412"
            data-name="Path 412"
            d="M165.444,132.28a10.8,10.8,0,0,1-10.718,9.468h-3.2a1.6,1.6,0,0,0-1.541,1.161l-1.912,11.365a1.3,1.3,0,0,0,1.284,1.518h3.668a1.3,1.3,0,0,0,1.284-1.086l1.042-6.193a2.217,2.217,0,0,1,2.186-1.849h.964a9.477,9.477,0,0,0,9.477-9.477h0A6.011,6.011,0,0,0,165.444,132.28Z"
            transform="translate(-141.5 -124.089)"
            fill="#0085cc"
        />
        <path
            id="Path_413"
            data-name="Path 413"
            d="M180.774,124.884h3.2a10.8,10.8,0,0,0,10.718-9.468,5.991,5.991,0,0,0-3.486-1.113h-8.35a1.947,1.947,0,0,0-1.92,1.624l-1.7,10.118A1.6,1.6,0,0,1,180.774,124.884Z"
            transform="translate(-170.747 -107.225)"
            fill="#00186a"
        />
    </svg>
</template>

<script>
export default {
    name: "BigPaypalIcon",
    props: {
        color: {
            type: String,
            default: "#00acdc"
        }
    }
};
</script>

<style lang="less" scoped>
</style>

