<template>
    <el-container class="settings-area" id="settings-area" v-if="user">
        <left-side :active-tab="activeTab" v-if="!isMobile"></left-side>

        <el-container id="settings-container">
            <account-information v-bind:user-info="user" v-if="activeTab === 'profile'"></account-information>

            <subscription v-if="activeTab === 'billing'"></subscription>

            <settings-integration v-bind:user="user" v-if="activeTab === 'integration'"></settings-integration>

            <team-users v-bind:user="user" v-if="activeTab === 'users'"></team-users>

            <settings-stage v-bind:user="user" v-if="activeTab === 'stage'"></settings-stage>

            <settings-privacy v-bind:user="user" v-if="activeTab === 'privacy'"></settings-privacy>

            <settings-notification v-bind:user="user" v-if="activeTab === 'notification'"></settings-notification>

            <user-referral v-bind:user="user" v-if="activeTab === 'user_referral'"></user-referral>
        </el-container>
    </el-container>
</template>

<script>
    import LeftSide from "./Settings/Layout/LeftSide";
    import AccountInformation from "./Settings/AccountInformation";
    import SubscriptionBilling from "./Settings/SubscriptionBilling";
    import Subscription from "./Settings/Subscription";
    import SettingsIntegration from "./Settings/SettingsIntegration";
    import TeamUsers from "./Settings/TeamUsers";
    import SettingsStage from "./Settings/SettingsStage";
    import SettingsPrivacy from "./Settings/SettingsPrivacy";
    import SettingsNotification from "./Settings/SettingsNotification";
    import UserReferral from "./Settings/UserReferral";

    export default {
        name: "Settings",
        components: {
            SettingsNotification,
            SettingsStage,
            SettingsPrivacy,
            TeamUsers,
            SettingsIntegration,
            SubscriptionBilling,
            AccountInformation,
            UserReferral,
            LeftSide,
            Subscription
        },
        props: {
            user: Object
        },
        data: () => ({
            activeTab: "profile",
            isMobile: false
        }),
        created() {
            if (this.$route.params && this.$route.params.section) {
                this.activeTab = this.$route.params.section;
            }
            this.mobileCheck();
        },
        beforeDestroy() {
            window.vEvent.stop("handle-settings-menu-select", this.handleMenuSelect);
            window.vEvent.stop("settings-common-success", this.commonSuccess);
            window.vEvent.stop("settings-common-error", this.commonError);
        },
        mounted() {
            this.eventListen();
            this.setBasicValues();
        },
        methods: {
            setBasicValues() {
                this.$nextTick(() => {
                    let showVisitCheck = setInterval(() => {
                        if (this.user) {
                            this.$forceUpdate();
                            this.$nextTick(() => {
                                /*document.querySelector("#settings-container").style.height = document.querySelector(".settings-area").getBoundingClientRect().height + "px";
                                if (document.querySelector(".settings-area").scrollHeight > document.querySelector(".settings-area").getBoundingClientRect().height) {
                                    document.querySelector("#settings-container").style.height = document.querySelector(".settings-area").scrollHeight + "px";
                                }*/
                                // let rightsectheight = window.document.getElementById("settings-container").getBoundingClientRect().height;
                                // let windowheight = window.innerHeight - window.document.getElementById("top-bar").getBoundingClientRect().height;
                                // if (rightsectheight < windowheight) {
                                //     window.document.getElementById("settings-left-menu").style.height = windowheight + "px";
                                // }
                                // else
                                // {
                                //     window.document.getElementById("settings-left-menu").style.height = rightsectheight + "px";
                                // }
                                // console.log("rightsectheight: " + rightsectheight);
                                // console.log("windowheight: " + windowheight);
                            });
                            clearInterval(showVisitCheck);
                        }
                    }, 100);
                });
            },
            eventListen() {
                window.vEvent.listen("handle-settings-menu-select", this.handleMenuSelect);
                window.vEvent.listen("settings-common-success", this.commonSuccess);
                window.vEvent.listen("settings-common-error", this.commonError);
            },
            handleMenuSelect(item) {
                this.activeTab = item;
                document.querySelector("body").scrollTop = 0;
                document.querySelector("#app").scrollTop = 0;
            },
            commonSuccess({title, message, close}) {
                this.$notify({
                    title: title,
                    message: message,
                    type: "success",
                    position: "bottom-left",
                    showClose: close,
                    customClass: "settings-notify"
                });
            },
            commonError({title, message, close}) {
                this.$notify({
                    title: title,
                    message: message,
                    type: "error",
                    position: "bottom-left",
                    showClose: close,
                    customClass: "settings-notify"
                });
            },
            mobileCheck() {
                let BC_onMobile = false;
                if (window.innerWidth <= 1024 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }
                // /drtghrt iu

                this.isMobile = BC_onMobile;
                this.$store.commit('setIsMobile', BC_onMobile);
            }
        }
    };
</script>
<style lang="less">
    @import "~element-ui/lib/theme-chalk/tree.css";
</style>
<style lang="less" scoped src="../assets/less/custom/common.less"></style>
<style lang="less" src="../assets/less/custom/pages.less"></style>
<style lang="less" src="../assets/less/custom/settings.less"></style>
