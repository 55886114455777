<template>
    <el-menu
            :collapse="isCollapse" v-if="!ifMobile"
            :default-active="'/settings/' + activatedTab"
            :router="true"
            class="settings-left-menu"
            @select="onmenuopen"
    >
        <el-menu-item  :index="item.link" :key="`s_m_item_${m_ind}`" v-for="(item, m_ind) in items">
            <slot name="item" v-bind:item="item">
                <div class="item-left">
                    <img alt src="../../../../assets/img/settings/setting-user.svg"
                         v-if="item.icon !== '' && item.logo === 1"/>
                    <img alt src="../../../../assets/img/settings/credit-card.svg"
                         v-if="item.icon !== '' && item.logo === 2"/>
                    <img alt src="../../../../assets/img/settings/integration-icon.svg"
                         v-if="item.icon !== '' && item.logo === 3"/>
                    <img alt src="../../../../assets/img/settings/user-group.svg"
                         v-if="item.icon !== '' && item.logo === 4"/>
                    <img alt src="../../../../assets/img/settings/stage_icon.svg"
                         v-if="item.icon !== '' && item.logo === 5"/>
                    <img alt src="../../../../assets/img/settings/notifications.svg"
                         v-if="item.icon !== '' && item.logo === 6"/>
                    <img alt src="../../../../assets/img/Group_16919.png"
                         v-if="item.icon !== '' && item.logo === 7"/>

                    <div v-if="item.icon === '' && item.logo !== '' && item.logo !== 'integration-icon'">
                        {{ item.logo }}
                    </div>
                </div>

                <div class="between-v-line"></div>

                <span class="left-menu-title">{{ item.name }}</span>
            </slot>
        </el-menu-item>
    </el-menu>

<!--
    <el-menu
        v-else
        :default-active="'/settings/' + activatedTab"
        mode="horizontal"
        :router="true"
        menu-trigger="click"
        @select="onmenuopen" 
    >
        <el-submenu class="sub-menu-more" index="0" style="float: right;"> 
            <template slot="title">{{activatedTab === 'profile' ? 'Account Information' : activatedTab === 'billing' ? 'Subscription & Billing' : activatedTab | capitalize}}</template>
            
            <el-menu-item :index="item.link" :key="`s_m_item_${m_ind}`" v-for="(item, m_ind) in items"> 
                 <slot name="item" v-bind:item="item">
                    <span class="left-menu-title"> {{ item.name }}</span>
                </slot>
            </el-menu-item>
        </el-submenu>
    </el-menu>
-->
</template>

<script>
    export default {
        name: "Menu",
        props: {
            items: Array,
            activeTab: {
                type: String,
                default: "profile"
            }
        },
        data: () => ({
            activatedTab: "profile",
            isCollapse: false,
            ifMobile: false
        }),
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        created() {
            this.mobileCheck();
             window.addEventListener('resize', ()=>{
                this.mobileCheck();
            });
            this.activatedTab = this.activeTab;
            // console.log('user info',this.$store.getters.userInfo);
        },
        methods: {
            onmenuopen(_index) {
                console.log(_index, 'lk')
                setTimeout(function () {
                    let rightsectheight = window.document.getElementById("settings-container").clientHeight;
                    let windowheight = window.innerHeight - window.document.getElementById("top-bar").clientHeight;
                    if (rightsectheight < windowheight) {
                        window.document.getElementById("settings-left-side").style.minHeight = windowheight + "px";
                    }
                }, 500);
            },

            mobileCheck() {
                let BC_onMobile = false;
                if (window.screen.availWidth <= 1024 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }

                this.isCollapse = BC_onMobile;
                window.screen.availWidth <= 1024 ? this.ifMobile = true : this.ifMobile = false
            }
        },
        watch: {
            $route(to, from) {
                if(to.path.includes('/billing/') || to.path.includes('/billing')) {
                    this.activatedTab = to.path.replace(to.path, "billing");
                } else {
                    this.activatedTab = to.path.replace("/settings/", "");
                }

                if (!this.activatedTab || this.activatedTab === "" || this.activatedTab === "/settings") {
                    this.activatedTab = "profile";
                }

                window.vEvent.fire(
                    "handle-settings-menu-select",
                    this.activatedTab
                );

            }
        }
    };
</script>

<style lang="less" scoped>
    .settings-left-menu {
        border-right: 0;
         .el-menu--horizontal{
        &>.el-submenu.is-active .el-submenu__title{
            color: var(--color-content) !important;
            border: none !important;
        }
        .el-menu .el-menu-item.is-active{
          color: var(--color-content) !important;
        }
      }
        .el-menu-item {
            outline: 0;
            display: flex;
            margin-top: 15px;
            border: 1px solid #eef9fc;
            border-radius: 4px;
            position: relative;
            padding-left: 8px !important;
            width: 278px; //NEMESIS ADD
            .item-left {
                color: #63c8e9 !important;
                width: 30px;
                text-align: center;

                i {
                    font-size: 19px;
                    color: #63c8e9 !important;
                }

                div {
                    font-size: 22px;
                }
            }

            .left-menu-title {
                margin-left: 28px; //NEMESIS CHANGE margin-left: 2rem;
                color: var(--textThemeColor) !important;
                font-weight: 500;
                font-size: 15px; //NEMESIS ADD
            }

            .between-v-line {
                width: 1px;
                border: 1px ridge #eef9fc;
                height: 46px;
                position: absolute;
                left: 45px;
                top: 5px;
            }

            &:hover {
                .between-v-line {
                    border-color: rgb(209, 219, 229);
                }
            }
            &:hover {
                background-color: #ffffff33;
            }
            &.is-active {
                background-color: #ffffff33;
                /*background-color: #ebf9fa;*/
                border-color: #87d4ea;

                .between-v-line {
                    border-color: #c3ebf7;
                }
            }
        }
    }
</style>
