import jQuery from 'jquery';
export default {
    run: function (component) {
        jQuery(document).ready(function ($) {
            // colorpicker
            $(".colorpicker").each(function () {
                var cp = $(this);


                function adjust_picker_lightness(l) {
                    var color = (l <= 50) ? '0, 0, 0' : '255, 255, 255';
                    var alpha = (l <= 50) ? 1 - l / 100 * 2 : l / 100 * 2 - 1;

                    cp.find(".colorpicker__lightnessoverlay").css('background-color', 'rgba(' + color + ', ' + alpha + ')');
                }

                function adjust_hszonepoiner(h, s) {

                    var hszone = cp.find(".colorpicker__hszone");
                    var hsw = parseInt(hszone.css("width"));
                    var hsh = parseInt(hszone.css("height"));
                    var xpos = Math.round(h * hsw / 360);
                    var ypos = hsh - Math.round(s * hsh / 100);
                    xpos -= 9;	// adjust by the size of circle
                    ypos -= 9;

                    hszone.find(".colorpicker__hszonepointer").css("left", xpos + "px").css("top", ypos + "px");
                }


                function adjust_valzonepointer(h, s, l) {
                    var valzone = cp.find(".colorpicker__valzone");
                    valzone.css("background", "linear-gradient( to bottom, white, hsl( " + h + ", " + s + "%, " + l + "% ), black )");
                    var zh = parseInt(valzone.css("height"));
                    var ypos = zh - Math.round(l * zh / 100);
                    ypos -= 9;	// 9px is size of pointer
                    valzone.find(".colorpicker__valzonepointer").css("top", ypos + "px");
                }

                function adjust_samplebar(h, s, l) {
                    cp.find(".colorpicker__sample").css("background-color", "hsl( " + h + ", " + s + "%, " + l + "% )");
                }


                function hue2rgb(p, q, t) {
                    if (t < 0) t += 1;
                    if (t > 1) t -= 1;
                    if (t < 1 / 6) return p + (q - p) * 6 * t;
                    if (t < 1 / 2) return q;
                    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                    return p;
                }


                function update_color_value(changesrc) {
                    var hszone = cp.find(".colorpicker__hszone");
                    var hsw = parseInt(hszone.css("width"));
                    var hsh = parseInt(hszone.css("height"));
                    var hscursor = hszone.find(".colorpicker__hszonepointer");

                    var xpos = parseInt(hscursor.css("left"));
                    var ypos = parseInt(hscursor.css("top"));

                    xpos += 9;
                    ypos += 9;

                    ypos = hsh - ypos;


                    var valzone = cp.find(".colorpicker__valzone");
                    var zh = parseInt(valzone.css("height"));
                    var valcursor = valzone.find(".colorpicker__valzonepointer");

                    let lpos = parseInt(valcursor.css("top"));
                    lpos += 9;
                    lpos = zh - lpos;

                    var H = Math.round(xpos / hsw * 360);
                    var S = Math.round(ypos / hsh * 100);
                    var L = Math.round(lpos / zh * 100);

                    switch (changesrc) {
                        case "valzone":
                            adjust_picker_lightness(L);
                            break;

                        case "hszone":
                            adjust_valzonepointer(H, S, L);
                            break;
                    }

                    adjust_samplebar(H, S, L);

                    // HSL -> RGB
                    let tmph = H / 360;
                    let tmps = S / 100;
                    let tmpl = L / 100;

                    var r, g, b;

                    if (tmps == 0) {
                        r = g = b = tmpl;
                    } else {
                        var q = tmpl < 0.5 ? tmpl * (1 + tmps) : tmpl + tmps - tmpl * tmps;
                        var p = 2 * tmpl - q;

                        r = hue2rgb(p, q, tmph + 1 / 3);
                        g = hue2rgb(p, q, tmph);
                        b = hue2rgb(p, q, tmph - 1 / 3);
                    }
                    r = Math.round(r * 255);
                    g = Math.round(g * 255);
                    b = Math.round(b * 255);

                    // http://stackoverflow.com/a/5624139
                    var hexcolor = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

                    // RGB -> input field
                    cp.find(".colorpicker__colorvalue").val(hexcolor);
                    component.setColorValue(hexcolor)
                    cp.trigger("change");
                }


                function cp_move_lcursor(e) {
                    var bounds = cp.find(".colorpicker__valzone").get(0).getBoundingClientRect(),
                        xpos = e.clientX - bounds.left,
                        ypos = e.clientY - bounds.top;

                    xpos = (xpos < 0) ? 0 : ((xpos > bounds.width) ? bounds.width : xpos);
                    ypos = (ypos < 0) ? 0 : ((ypos > bounds.height) ? bounds.height : ypos);

                    xpos -= 9;
                    ypos -= 9;

                    cp.find(".colorpicker__valzonepointer").css("top", ypos + "px");
                    update_color_value("valzone");
                }


                function cp_move_hscursor(e) {
                    var bounds = cp.find(".colorpicker__hszone").get(0).getBoundingClientRect(),
                        xpos = e.clientX - bounds.left,
                        ypos = e.clientY - bounds.top;

                    xpos = (xpos < 0) ? 0 : ((xpos > bounds.width) ? bounds.width : xpos);
                    ypos = (ypos < 0) ? 0 : ((ypos > bounds.height) ? bounds.height : ypos);

                    xpos -= 9;
                    ypos -= 9;

                    cp.find(".colorpicker__hszonepointer").css("left", xpos + "px").css("top", ypos + "px");
                    update_color_value("hszone");
                }


                cp.find(".colorpicker__colorvalue").change(function (e) {
                    var cval = parseInt($(this).val().replace('#', ''), 16);
                    var r = ((cval >> 16) & 255) / 255;
                    var g = ((cval >> 8) & 255) / 255;
                    var b = (cval & 255) / 255;

                    var max = Math.max(r, g, b), min = Math.min(r, g, b);
                    var h, s, l = (max + min) / 2;
                    if (max == min) {
                        h = s = 0;
                    } else {
                        var d = max - min;
                        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                        switch (max) {
                            case r:
                                h = (g - b) / d + (g < b ? 6 : 0);
                                break;
                            case g:
                                h = (b - r) / d + 2;
                                break;
                            case b:
                                h = (r - g) / d + 4;
                                break;
                        }
                        h /= 6;
                    }

                    var h = Math.round(h * 360);
                    var s = Math.round(s * 100);
                    var l = Math.round(l * 100);
                    adjust_picker_lightness(l);
                    adjust_hszonepoiner(h, s);
                    adjust_valzonepointer(h, s, l);
                    adjust_samplebar(h, s, l);

                    cp.trigger("change");
                });

                cp.find(".colorpicker__hszone").on('mousedown', function (e) {
                    e.preventDefault();
                    cp_move_hscursor(e);
                    $(this).css("cursor", "none");
                    $(document).on('mousemove', cp_move_hscursor);
                }).on('touchmove touchstart', cp_move_hscursor);

                cp.find(".colorpicker__valzone").on('mousedown', function (e) {
                    e.preventDefault();
                    cp_move_lcursor(e);
                    $(this).css("cursor", "none");
                    $(document).on('mousemove', cp_move_lcursor);
                }).on('touchmove touchstart', cp_move_lcursor);


                $(document).on('mouseup', function () {
                    cp.find(".colorpicker__hszone").css("cursor", "");
                    cp.find(".colorpicker__valzone").css("cursor", "");
                    $(this).off('mousemove', cp_move_hscursor).off('mousemove', cp_move_lcursor);
                });


            });

            $(".colorpicker__colorvalue").val(component.color).trigger("change");
        });
    }
}