import { render, staticRenderFns } from "./SubscriptionPlan.vue?vue&type=template&id=48d09e80&scoped=true&"
import script from "./SubscriptionPlan.vue?vue&type=script&lang=js&"
export * from "./SubscriptionPlan.vue?vue&type=script&lang=js&"
import style0 from "./SubscriptionPlan.vue?vue&type=style&index=0&id=48d09e80&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d09e80",
  null
  
)

export default component.exports