<template>
    <svg
        id="Group_11262"
        data-name="Group 11262"
        xmlns="http://www.w3.org/2000/svg"
        width="20.768"
        height="20.768"
        viewBox="0 0 20.768 20.768"
    >
        <rect
            id="Rectangle_1408"
            data-name="Rectangle 1408"
            width="20.768"
            height="20.768"
            rx="10.384"
            fill="#00acdc"
        />
        <g id="Good_checkmark" data-name="Good checkmark" transform="translate(4.615 5.769)">
            <g id="Group_4357" data-name="Group 4357">
                <path
                    id="Path_4291"
                    data-name="Path 4291"
                    d="M11.686,68.171a.593.593,0,0,0-.839,0l-7.1,7.1L1.012,72.545a.593.593,0,0,0-.839.839l3.15,3.15a.593.593,0,0,0,.839,0l7.524-7.524A.593.593,0,0,0,11.686,68.171Z"
                    transform="translate(0 -67.998)"
                    fill="#fff"
                    stroke="#00acdc"
                    stroke-width="0.2"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "GoodCheckmarkLightBlue",
    props: {
        color: {
            type: String,
            default: "#00acdc"
        }
    }
};
</script>

<style lang="less" scoped>
</style>

