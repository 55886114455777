<template>
    <!-- subscriptionData -->
    <div class='subs-change-container'>
        <div class="upper-card">
            <div class="big-text upper-card-title">Current Plan</div>
            <div class="hr-div"></div>
            <div class="upper-card-content d-flex justify-content-between">
                <div class="upper-card-left flex-columns">
                    <span class="small-text">You are currently subscribed to:</span>
                    <div class="big-text plan-details">
                        {{ subscriptionData.plan.name }} Plan
                    </div>
                    <span class="small-text">
                        ${{ subscriptionData.plan.price }}
                        <span>
                            {{ getPlanIntervalText(subscriptionData.plan.id)['main'] }}
                            <span class="greyed-text">{{ getPlanIntervalText(subscriptionData.plan.id)['bracket'] }}</span>
                        </span>
                    </span>
                </div>
                <!-- <div class="upper-card-right flex-columns">
                    <div @click='getProration({type: "annual"})' class="annual-switch" v-if='canUpgradeToAnnual()'>
                        <base-loader :showLoader='loading' />
                        <span v-if='!loading'>Switch to Annual Billing</span>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="plans-blocks d-flex">
            <div class="plan-block basic-plan">
                <div class="plan-header">
                    <div class="plan-title">BASIC</div>
                    <div class="plan-per-month">$29 per month</div>
                </div>
                <div class="plan-body">
                    <div class="plan-feature d-flex" v-for='(feature, index) in features.basic' :key='index'>
                        <div class="check-mark">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                        <div class="feature-title">{{ feature }}</div>
                    </div>
                    <div class='text-center plan-change-container'>
                        <base-loader :showLoader='baseLoading' />
                        <div v-if='!baseLoading'>
                            <button @click='getProration({type: "monthly", plan_id: "adilo-basic-monthly", update_type: upgradeDowngradeText(0).type})' v-if='planLevel != 0 || subscriptionData.subscription.stripe_plan === "free"' class="plan-change-button btn">{{ upgradeDowngradeText(0).text }}</button>
                            <div v-else class="current-plan-wrapper">
                                <good-black-small-check-mark />
                                <span>This is your current plan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plan-block growth-plan">
                <div class="plan-header flex-columns">
                    <div class="plan-title">GROWTH</div>
                    <div class="plan-per-month">$49 per month</div>
                    <!-- <div class="plan-per-year">or $30 per month billed annually</div> -->
                </div>
                <div class="plan-body">
                    <div class="plan-feature d-flex" v-for='(feature, index) in features.growth' :key='index'>
                        <div class="check-mark">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                        <div class="feature-title">{{ feature }}</div>
                    </div>
                    <div class='text-center plan-change-container'>
                        <base-loader :showLoader='baseLoading' />
                        <div v-if='!baseLoading'>
                            <button @click='getProration({type: "monthly", plan_id: "adilo-growth-monthly", update_type: upgradeDowngradeText(1).type})' v-if='planLevel != 1 || subscriptionData.subscription.stripe_plan === "free"' class="plan-change-button btn">{{ upgradeDowngradeText(1).text }}</button>
                            <div v-else class="current-plan-wrapper">
                                <good-black-small-check-mark />
                                <span>This is your current plan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plan-block unlimited-plan">
                <div class="plan-header">
                    <div class="plan-title">UNLIMITED</div>
                    <div class="plan-per-month">$149 per month</div>
                    <!-- <div class="plan-per-year">or $80 per month billed annually</div> -->
                </div>
                <div class="plan-body">
                    <div class="plan-feature d-flex" v-for='(feature, index) in features.unlimited' :key='index'>
                        <div class="check-mark">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                        <div class="feature-title">{{ feature }}</div>
                    </div>
                    <div class='text-center plan-change-container'>
                        <base-loader :showLoader='baseLoading' />
                        <div v-if='!baseLoading'>
                            <button @click='getProration({type: "monthly", plan_id: "adilo-unlimited-monthly", update_type: upgradeDowngradeText(2).type})' v-if='planLevel != 2 || subscriptionData.subscription.stripe_plan === "free"' class="plan-change-button btn">{{ upgradeDowngradeText(2).text }}</button>
                            <div v-else class="current-plan-wrapper">
                                <good-black-small-check-mark />
                                <span>This is your current plan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if='messages && messages.length'>
            <base-message-box
                :messages='messages'
                head='Subscription Update'
                @close='messages = null'
            />
        </div>
    </div>
</template>

<style lang="less">
    .subs-change-container {
        .current-plan-wrapper {
            background: rgba(0, 0, 0, 0.062);
            border-radius: 30px;
            width: 80%;
            height: 30px;
            text-align: center;
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            svg {
                position: relative;
                top: 5px;
            }
            span {
                color: #000;
            }
        }
        .greyed-text {
            color: #8695a0;
        }
        .hr-div {
            border-bottom: 1px solid #d1dbe5;
            width: 100%;
            overflow: hidden;
        }
        .upper-card {
            background: #fff;
            border-radius: 5px;
            .upper-card-title {
                padding: 20px;
            }
            .big-text {
                font-size: 20px;
                font-family: "Helvetica Neue", serif;
                font-weight: bold;
            }
            .upper-card-content {
                .upper-card-left {
                    padding: 20px;
                    .plan-details {
                        padding: 15px 0 5px 0;
                    }
                }
                .upper-card-right {
                    .annual-switch {
                        padding: 60px 20px 0px 0;
                        color: #019EDB;
                        cursor: pointer;
                    }
                }
            }
        }
        .plans-blocks {
            position: relative;
            left: -5%;
            right: 0;
            margin: 20px auto auto auto;
            width: 110%;
            @media (max-width: 1024px) {
                width: 100% !important;
                left: 0% !important;
            }
            @media (max-width: 960px) {
                font-size: 12px;
            }
            .plan-block {
                width: 50%;
                margin: 15px;
                .plan-header {
                    padding: 15px 30px;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    height: 110px;
                    @media (max-width: 960px) {
                        min-height: 130px;
                        height: auto;
                        max-height: max-content;
                    }
                    @media (max-width: 960px) {
                        padding: 5px !important;
                        text-align: center;
                    }
                    .plan-title {
                        font-size: 20px;
                        font-weight: 500;
                        @media (max-width: 960px) {
                            font-size: 15px;
                        }
                    }
                    .plan-per-month {
                        font-size: 16px;
                    }
                }
                .plan-body {
                    padding: 25px;
                    background: #fff;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    @media (max-width: 960px) {
                        padding: 5px !important;
                    }
                    .plan-feature {
                        align-items: center;
                        border: 1px solid #f1f8fd;
                        border-radius: 5px;
                        margin-top: 10px;
                        padding: 10px 0;
                        width: 100%;
                        @media (max-width: 960px) {
                            padding: 5px !important;
                        }
                        .check-mark {
                            padding: 0 15px;
                            @media (max-width: 960px) {
                                display: none;
                            }
                        }
                    }
                    .plan-change-container {
                        padding: 25px 0;
                        .plan-change-button {
                            border-radius: 20px;
                            background: transparent;
                            transition: 0.3s;
                        }
                    }
                }
            }
            .basic-plan {
                .plan-header { background: #B8F7F7; }
                .plan-body {
                    .plan-feature {
                        .fa-check {
                            color: #B8F7F7
                        }
                    }
                }
                .plan-change-button {
                    border: 1px solid #B8F7F7 !important;
                    color: #38f3f3;
                    &:hover {
                        background: #B8F7F7 !important;
                        color: #fff;
                    }
                }
            }
            .growth-plan {
                .plan-header {
                    background: #0D0538;
                    color: #fff;
                    .plan-per-year { color: #ffffffb7; }
                }
                .plan-body {
                    .plan-feature {
                        .fa-check {
                            color: #0D0538
                        }
                    }
                }
                .plan-change-button {
                    border: 1px solid #0D0538 !important;
                    color: #0D0538;
                    &:hover {
                        background: #0D0538 !important;
                        color: #fff;
                    }
                }
            }
            .unlimited-plan {
                .plan-header {
                    background: #FAE0F2;
                    .plan-per-year { color: rgb(63, 62, 62); }
                }
                .plan-body {
                    .plan-feature {
                        .fa-check {
                            color: #FAE0F2
                        }
                    }
                }
                .plan-change-button {
                    border: 1px solid #fcb5e6 !important;
                    color: #fcb5e6;
                    &:hover {
                        background: #fcb5e6 !important;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>

<script>
import BaseMessageBox from '../../../Common/Global/BaseMessageBox.vue';
import GoodBlackSmallCheckMark from "../../Icons/GoodBlackSmallCheckMark";
export default {
    name: 'ChangeSubscriptionPlanV2',
    props: ['subscriptionData', 'baseLoading'],
    components: {
        GoodBlackSmallCheckMark
    },
    data: () => ({
        plans: {
            monthly: ['adilo-basic-monthly', 'adilo-growth-monthly', 'adilo-unlimited-monthly'],
            annual: ['adilo-growth-annual', 'adilo-unlimited-annual'],
            semi: ['adilo-unlimited-semi-annual'],
        },
        planIntervalText: '',
        currentPlan: '',
        planLevel: null,
        features: {
            basic: ['50 GB Storage', '200 GB Bandwidth (Monthly)', 'Stage Video Channel', 'Video experimentation & split testing'],
            growth: [
                '200 GB Storage', '500 GB Bandwidth (Monthly)', 'Stage Video Channel',
                'Video experimentation & split testing', 'Snapbyte video recorder & messaging', '40 Minutes A.I. captions & translations',
                'Scheduled streaming', 'Ad pixels retargeting', 'Leads auto-tagging'
            ],
            unlimited: [
                'Unlimited Storage', '2 TB Bandwidth (Monthly)', 'Stage Video Channel',
                'Video experimentation & split testing', 'Snapbyte video recorder & messaging', '200 Minutes A.I. captions & translations',
                'Scheduled streaming', 'Ad pixels retargeting', 'Leads auto-tagging', 'Advanced streaming technology', 'Sub users & collaboration',
                '100 Licenses Anti-piracy multi-DRM', '500 Sessions Dynamic visual watermarking', 'Dedicated account manager'
            ]
        },
        prorations: {},
        loading: false,
        requestTypes: { 0: '', 1: '', 2: '' },
        messages: null,
    }),
    mounted() {
        this.getPlanLevel();
        this.canUpgradeToAnnual();
    },
    methods: {
        getPlanIntervalText(id) {
            const plans = this.plans;
            let text = { main: '', bracket: '' };
            if (plans.monthly.includes(id)) {
                text.main = 'per month';
                text.bracket = '(paid monthly)';
            } else if (plans.annual.includes(id)) {
                text.main = 'per year';
                text.bracket = '(paid annually)';
            } else if (plans.semi.includes(id)) {
                text.main = 'per 6 months';
                text.bracket = '(paid semi-annually)';
            }
            return text;
        },
        upgradeDowngradeText(level) {
            if (!this.planLevel || level > this.planLevel) {
                this.requestTypes[level] = 'upgrade';
                return {text: 'Upgrade to this plan', type: this.requestTypes[level]};
            }
            this.requestTypes[level] = 'downgrade';
            return {text: 'Downgrade to this plan', type: this.requestTypes[level]};
        },
        getPlanLevel() {;
            const subscription = this.$props.subscriptionData;
            if (!subscription) return null;

            const id = subscription.plan.id
            this.currentPlan = subscription.plan.id;
            if (id.includes('lifetime')) {
                this.planLevel = -1;
            } else if (id.includes('basic')) {
                this.planLevel = 0;
            } else if (id.includes('growth')) {
                this.planLevel = 1;
            } else if (id.includes('unlimited')) {
                this.planLevel = 2;
            }
        },
        canUpgradeToAnnual() {
            const subscription = this.$props.subscriptionData;
            if (!subscription) return null;

            const id = subscription.plan.id;
            const { monthly } = this.plans;

            if (monthly.includes(id) && id != 'adilo-basic-monthly') {
                return true;
            }
            return false;
        },
        getProration(data) {
            if (this.loading) return false;
            this.loading = true;
            const currentPlan = this.currentPlan;
            const update_type = data.update_type;
            const type = data.type;
            if (!data.plan_id) {
                if (type === 'annual') {
                    if (currentPlan.includes('growth')) {
                        data.plan_id = 'adilo-growth-annual';
                    } else if (currentPlan.includes('unlimited')) {
                        data.plan_id = 'adilo-unlimited-annual';
                    }
                }
            }

            if (data.plan_id) {
                const plan_id = data.plan_id;
                this.axios.get(`payments/subscription/prorations?plan=${plan_id}`).then(
                    response => {
                        this.loading = false;
                        if (response.data) {
                            if (!response.data.success) {
                                this.messages = [response.data.message];
                            } else {
                                const { credit, debit, new_plan_id, new_plan_name, platform, customer_id } = response.data;
                                this.$emit('openChangePlan', { type, plan_id, credit, debit, new_plan_id, new_plan_name, update_type, platform, customer_id });
                            }
                        }
                    }
                ).catch(
                    error => {
                        console.log(error);
                        this.loading = false;
                    }
                )
            }
        }
    },
}
</script>
