<template>
    <el-container>
        <el-card>
            <div class="clearfix" slot="header">
                <div class="header-title">Profile Information</div>
            </div>

            <el-row :gutter="40">
                <el-col :span="12" :xs="24">
                    <label class="control-label" for="user-first-name">First Name</label>
                    <input
                            :class="{'has-error': (errorsForm.firstNameRequired)}"
                            class="form-control"
                            id="user-first-name"
                            placeholder="Enter first name"
                            type="text"
                            v-model="selUser.first_name"
                    />

                    <label class="text-danger small" v-if="errorsForm.firstNameRequired">
                        <i class="el-icon-warning"></i>
                        {{ errorsForm.first_name }}
                    </label>
                </el-col>

                <el-col :span="12" :xs="24">
                    <label class="control-label" for="user-last-name">Last Name</label>
                    <input
                            :class="{'has-error': (selUser.last_name !== '' && errorsForm.nameInvalid)}"
                            class="form-control"
                            id="user-last-name"
                            placeholder="Enter first name"
                            type="text"
                            v-model="selUser.last_name"
                    />
                </el-col>

                <el-col
                        :span="20"
                        :xs="24"
                        class="text-danger small m-bottom-2"
                        v-if="errorsForm.nameInvalid"
                >
                    <i class="el-icon-warning"></i>
                    {{ errorsForm.name }}
                </el-col>
            </el-row>

            <el-row :gutter="40" class="m-top-3">
                <el-col :span="12" :xs="24">
                    <auth-email-field :credentials="selUser" :errors-form="errorsForm"></auth-email-field>
                </el-col>
            </el-row>

            <el-row :gutter="40">
                <el-col
                        :span="12"
                        :xs="24"
                >The user will log in using this Email Address and their Password.
                </el-col>
            </el-row>
        </el-card>

        <el-card class="m-top-4 password-card">
            <div class="clearfix" slot="header">
                <div class="header-title">Password</div>
            </div>

            <div class="form-group">
                <label class="control-label">User Account Password</label>
            </div>

            <el-row>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                    <el-radio label="1" v-model="password_type">Autogenerate Password</el-radio>
                </el-col>
            </el-row>

            <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                    <el-radio label="2" v-model="password_type">Custom Password</el-radio>
                </el-col>
            </el-row>

            <el-row class="m-top-2">
                <el-col :span="2">&nbsp;</el-col>
                <el-col :span="13" :xs="18">
                    <input
                            :class="{'has-error': (errorsForm.passwordInvalid)}"
                            @input="passwordValidation"
                            class="form-control"
                            placeholder="Enter account password"
                            required
                            type="text"
                            v-if="showPasswordText"
                            v-model="password"
                    />

                    <input
                            :class="{'has-error': (errorsForm.passwordInvalid)}"
                            @input="passwordValidation"
                            class="form-control"
                            placeholder="Enter account password"
                            required
                            type="password"
                            v-else
                            v-model="password"
                    />

                    <label class="text-danger small" v-if="errorsForm.passwordInvalid">
                        <i class="el-icon-warning"></i>
                        {{ errorsForm.password }}
                    </label>
                </el-col>
                <el-col :span="2" :xs="4" class="show-password-checker">
                    <span @click="showPasswordText = !showPasswordText" class="login-password-show">
                        <img
                                alt
                                class="hide-password"
                                src="../../../assets/img/auth/show.svg"
                                v-if="showPasswordText"
                        />
                        <img
                                alt
                                class="hide-password"
                                src="../../../assets/img/auth/hide.svg"
                                v-else
                        />
                    </span>
                </el-col>
            </el-row>

            <el-row class="m-top-2">
                <el-col :span="2" :xs="4" class='password-group-space'>&nbsp;</el-col>
                <el-col :span="13" :xs="20">
                    <password-checker :errors-form="errorsForm"></password-checker>
                </el-col>
            </el-row>
        </el-card>

        <el-card class="m-top-4 tree-card-of-edit-user">

            <div :class="{'blured-permission': this.user.role == 'subuser'}">

            </div>

            <div class="clearfix" slot="header">
                <div
                        class="header-title"
                >Permissions {{ selUser.assigned_permissions.length }}/{{ perms_length }}
                </div>
            </div>

            <div class="form-group">
                <label
                        class="control-label"
                >Regardless of which permissions you choose, only you can view and manage billing information.</label>
            </div>
            <el-tree
                    :data="permissions_list"
                    :default-checked-keys="selUser.assigned_permissions"
                    :props="defaultProps"
                    @check-change="checkPermission"
                    @node-click="clickPermissionNode"
                    default-expand-all
                    icon-class="no-display-tree-icon"
                    node-key="id"
                    ref="permission_tree"
                    show-checkbox
                    :role="this.selUser.pivot.role"

            ></el-tree>

        </el-card>
    </el-container>
</template>

<script>
    import AuthEmailField from "../../Auth/Items/AuthEmailField";
    import PasswordChecker from "./PasswordChecker";

    export default {
        name: "CreateEditUser",
        components: {PasswordChecker, AuthEmailField},
        props: {
            user: Object,
            pageType: Number,
            isDisabled: false,
            selUser: {
                type: Object,
                default: () => ({
                    id: 0,
                    first_name: "",
                    last_name: "",
                    email: "",
                    assigned_permissions: [],
                    pivot : {
                        role: ''
                    }
                })
            }
        },
        computed: {
            errorsForm: {
                get() {
                    return this.$store.state.errorsForm;
                }
            }
        },
        data: () => ({
            showPasswordText: false,
            password_type: "2",
            permissions_list: [],
            perms_length: 11,
            defaultProps: {
                children: "children",
                label: "label"
            },
            password: ""
        }),
        beforeDestroy() {
            window.vEvent.stop("save-sub-user", this.saveUser);
        },
        mounted() {
            window.vEvent.listen("save-sub-user", this.saveUser);

            this.getPermissionsList();

            if (this.selUser.pivot.role == 'subuser') {
                isDisabled: true
            }
            console.log(this.selUser, this.user, '=========');
        },
        methods: {
            getPermissionsList() {
                let params = {
                    url: "/permissions",
                    config: {}
                };

                this.$store.dispatch("getData", {...params})
                    .then(response => {
                        this.permissions_list = response.data;

                        let vm = this;
                        this.perms_length = 0;
                        this.permissions_list.map(function (perm, index) {
                            vm.perms_length += perm.children.length;
                        });

                        if (this.pageType === 2) {
                            this.selUser.assigned_permissions = [
                                2,
                                3,
                                8,
                                10,
                                12,
                                13
                            ];
                        }
                    })
                    .catch(e => {
                        this.$message({
                            type: "error",
                            message: e.response.message
                        });
                    });
            },

            checkPermission(node, checked, c) {
                if (!node.children) {
                    let index = this.selUser.assigned_permissions.indexOf(node.id);
                    if (checked) {
                        if (index === -1) {
                            this.selUser.assigned_permissions.push(node.id);
                        }
                    } else {
                        if (index !== -1) {
                            this.selUser.assigned_permissions.splice(index, 1);
                        }
                    }
                }
            },

            clickPermissionNode(node, treeNode, c) {
                this.$refs.permission_tree.setChecked(node, !treeNode.checked, 1);
            },

            validateName(name) {
                let re = /^[a-zA-Z]+$/;
                return re.test(String(name).toLowerCase());
            },

            /**
             * Email validation check.
             * @returns {boolean}
             */
            validateEmail() {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(this.credentials.email).toLowerCase());
            },

            clearValidate() {
                this.errorsForm.firstNameRequired = false;
                this.errorsForm.nameInvalid = false;
                this.errorsForm.emailInvalid = false;
                this.errorsForm.emailExist = false;
                this.errorsForm.bemailEmpty = false;
                this.errorsForm.passwordInvalid = false;
                this.errorsForm.passwordType = {
                    uppercase: false,
                    lowercase: false,
                    number: false,
                    special: false,
                    minimum: false
                };
            },

            saveUser() {
                this.clearValidate();

                let valid = true;
                if (
                    this.selUser.first_name === "" ||
                    this.selUser.first_name === null ||
                    this.selUser.first_name === " "
                ) {
                    this.errorsForm.firstNameRequired = true;
                    return false;
                } else {
                    this.errorsForm.firstNameRequired = false;
                }
                if (!this.validateName(this.selUser.first_name)) {
                    this.errorsForm.nameInvalid = true;
                    valid = false;
                }

                if (this.selUser.last_name !== "") {
                    if (!this.validateName(this.selUser.last_name)) {
                        this.errorsForm.nameInvalid = true;
                        valid = false;
                    }
                }

                if (this.selUser.email === "") {
                    this.errorsForm.bemailEmpty = true;
                    valid = false;
                } else {
                    this.errorsForm.bemailEmpty = false;
                }

                if (this.pageType === 2) {
                    if (!this.passwordValidation()) {
                        this.errorsForm.passwordInvalid = true;
                        valid = false;
                    }
                } else {
                    if (this.password !== "") {
                        if (!this.passwordValidation()) {
                            this.errorsForm.passwordInvalid = true;
                            valid = false;
                        }
                    }
                }

                if (!valid) {
                    return false;
                }

                let post_data = {
                    url:
                        this.pageType === 2
                            ? "/settings/save-sub-user-data"
                            : "/settings/update-sub-user-data",
                    data: {
                        id: this.selUser.id,
                        name: `${this.selUser.first_name} ${this.selUser.last_name}`,
                        email: this.selUser.email,
                        password: this.password,
                        assigned_permissions: this.selUser.assigned_permissions
                    }
                };

                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        if (response.data.result === "success") {
                            let vm = this;
                            this.$nextTick(function () {
                                this.$store
                                    .dispatch("getUserData")
                                    .then(re => {
                                        vm.$forceUpdate();
                                        window.vEvent.fire("set-user-page-type", 1);
                                    });
                            });
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (error.response.data.errors) {
                                if (error.response.data.errors.email) {
                                    this.errorsForm.emailExist = true;
                                }
                            }
                        }
                    });
            },

            passwordValidation() {
                window.vEvent.fire("password-validation", this.password);

                let re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
                return re.test(String(this.password));
            },

            passwordGenerate() {
                let chars =
                    "abcdefghijklmnopqrstuvwxyz!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
                let pass = "";
                for (let x = 0; x < 6; x++) {
                    let i = Math.floor(Math.random() * chars.length);
                    pass += chars.charAt(i);
                }

                let chars1 = "!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?";
                for (let x1 = 0; x1 < 2; x1++) {
                    let i1 = Math.floor(Math.random() * chars1.length);
                    pass += chars1.charAt(i1);
                }

                let chars2 = "abcdefghijklmnopqrstuvwxyz";
                for (let x2 = 0; x2 < 2; x2++) {
                    let i2 = Math.floor(Math.random() * chars2.length);
                    pass += chars2.charAt(i2);
                }

                let chars3 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                for (let x3 = 0; x3 < 2; x3++) {
                    let i3 = Math.floor(Math.random() * chars3.length);
                    pass += chars3.charAt(i3);
                }

                let chars4 = "1234567890";
                for (let x4 = 0; x4 < 2; x4++) {
                    let i4 = Math.floor(Math.random() * chars4.length);
                    pass += chars4.charAt(i4);
                }

                this.password = pass;

                this.passwordValidation();
            }
        },
        watch: {
            password_type(newVal, oldVal) {
                if (newVal === "1") {
                    this.passwordGenerate();
                } else {
                    this.password = "";
                    this.passwordValidation();
                }
            }
        }
    };
</script>

<style lang="less">

    .password-card {
        .el-radio {
            outline: 0;

            * {
                outline: 0;
            }
        }

        .show-password-checker {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 20px;
            color: #a08484;
        }
    }

    .tree-card-of-edit-user {
        position: relative;
        .el-tree {
            border: 0;
        }
        .blured-permission{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: #eee;
            z-index: 10;
            opacity: 0.5;
        }
    }

    .no-display-tree-icon {
        display: none;
    }

    @media (max-width: 960px) {
        .el-card__header {
            padding: 15px 0px !important;
        }

        .el-card__body {
            padding: 15px 5px !important;
        }

        .header-title {
            padding: 0 5px;
        }

        .password-group-space {
            display: none;
        }
    }
</style>
