<template>
    <el-card class="blank-card">
        <div class="form-group align-items-center w-100 h-100 blank-integration">
            <img alt src="/img/sdsdsdsd.png"/>
        </div>
        <div class="header-title text-center m-top-4">You Don’t Have Any Active Integration</div>
        <div
            class="text-center"
        >Connect {{ $store.state.siteName }} 4KPlayer to apps you already use.
        </div>
        <div class="text-center">Connect to hundreds more using Zapier-powered integration.</div>
<!--        <div class="text-center">-->
<!--            <a class="btn btn-default-outline watch-video-btn">-->
<!--                <img alt src="../../../assets/img/settings/buttton-play-circle.svg"/>-->
<!--                Watch the Video Guide-->
<!--            </a>-->
<!--        </div>-->
    </el-card>
</template>

<script>
    export default {
        name: "BlankIntegration"
    };
</script>

<style lang="less" scoped>
    .settings-integration {
        > .el-container {
            > .el-card {
                &.blank-card {
                    padding: 6rem 2rem !important;
                    border: 1px dashed #99d6e9 !important;

                    .blank-integration {
                        justify-content: center;
                    }

                    .watch-video-btn {
                        border: 1px solid #0bacdb !important;
                        font-weight: 500;//NEMESIS CHANGE font-weight: normal !important;
                        font-size:16px !important;//NEMESIS ADD
                        color: #21455e !important;
                        padding: 2px 30px 2px 2px !important;
                        position: relative;
                        margin-top: 3rem;

                        img {
                            margin-right: 1rem;
                        }
                    }
                    //NEMSIS ADD START
                    .text-center {
                        font-size: 14px;
                    }
                    //NEMESIS ADD END
                }
            }
        }
    }
</style>
