<template>
  <el-card>
    <div slot="header" class="clearfix header-billinginfo">
      <div class="title">Billing information</div>
      <div class="subtitle">View and update your billing contact address and payment method.</div>
    </div>
    <div class="body-information" v-if="billingInfo && billingInformation && userInfo">
      <div class="issued-column">
        <issued-to-billing :issuedTo="billingInfo" :issued-to-view="false" />
        <el-link
          type="primary"
          class="edit-link"
          @click="openEditBillingContactInfoDialog"
        >Edit Billing Contact Info</el-link>
        <div class="for-mobile">
          <ChangePaymentButton
            :isLoading="isLoading"
            :changePaymentDisabled="changePaymentDisabled"
            @openDialog="openDialogChangePaymentMethod"
            :mobile="true"
          ></ChangePaymentButton>
        </div>
      </div>
      <div class="billing-type-column">
        <!--<div v-if="userInfo.payment_method.toLowerCase() === 'paypal'" class="billing-type">-->
        <!--<paypal-icon class="billing-icon" />-->
        <!--<div>Pay with PayPal</div>-->
        <!--</div>-->
        <div v-if="userInfo.payment_method && userInfo.payment_method.toLowerCase() === 'stripe'" class="billing-type">
          <visa-icon class="billing-icon" v-if="userInfo.card_brand=='Visa'" />
          <span class="card-icon" v-if="userInfo.card_brand!='Visa'">
            <img
              alt="JCB"
              v-if="userInfo.card_brand=='Jcb'"
              class="img-responsive card-mark jcb"
              src="../../../assets/img/auth/card/jcb.svg"
            />
            <img
              alt="Master"
              v-if="userInfo.card_brand=='Master' || userInfo.card_brand=='MasterCard'"
              class="img-responsive card-mark"
              src="../../../assets/img/auth/card/mastercard.svg"
            />
            <img
              v-if="userInfo.card_brand=='AMEX'"
              alt="Master"
              class="img-responsive card-mark"
              src="../../../assets/img/auth/card/amex.svg"
            />
            <img
              v-if="userInfo.card_brand=='Discover'"
              alt="discover"
              class="img-responsive card-mark"
              src="../../../assets/img/auth/card/discover.svg"
            />
            <img
              v-if="userInfo.card_brand=='Diner'"
              alt="Diner"
              class="img-responsive card-mark"
              src="../../../assets/img/auth/card/diners.svg"
            />
          </span>
          <div>&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;</div>
        </div>
        <div
          v-if="userInfo.payment_method && userInfo.payment_method.toLowerCase() === 'stripe'"
          class="billing-card"
        >Expires {{`${userInfo.exp_month}/${userInfo.exp_year}`}}</div>
        <div
          v-if="userInfo.payment_method && userInfo.payment_method.toLowerCase() === 'paypal'"
          class="billing-email"
        >{{userInfo.paypal_email}}</div>
        <div class="for-desktop">
          <ChangePaymentButton
            :isLoading="isLoading"
            :changePaymentDisabled="changePaymentDisabled"
            @openDialog="openDialogChangePaymentMethod"
          ></ChangePaymentButton>
        </div>
      </div>
    </div>
    <base-loader :showLoader='!billingInfo || !billingInformation || !userInfo'/>

    <el-dialog
      :top="mobileCheck ? `10vh` : `30vh`"
      :visible.sync="showEditBillingInfo"
      :width="mobileCheck ? `100%` : `700px`"
      center
      custom-class="edit-billing-info-dialog"
      :title="'PAYMENT INFORMATION'">
      <common-body-billing-information
        v-if="changePaymentMethodModal"
        :user-info="userInfo"
        :billing-info="billingInfo"
        :cardData="cardData"
        :cards="cards"
        @getCard="getUsersCard()"
        @remove-card="removeCard"
        @make-card-primary ="makeCardPrimary"
        @checked-box="checkedBoxProps"
        @change-card="changeCardData"
        @change-modal-to-edit="changeModalToEditBillingInfo"
        @change-modal-to-edit-card="changeModalToEditCard"
        @get-payment-token="getPaymentToken"
      />
      <edit-body-billing-information
        v-if="editBillingContactInfoModal"
        :billing-info="billingInfo"
        @new-billing-info="changeNewBillingInfo"
      />
      <span v-if="changePaymentMethodModal" slot="footer" class="dialog-footer">
        <el-button @click="closeEditBillingInfoModalWithoutSaveCardData" type="info" round>Cancel</el-button>
        <el-button @click="putBillingInfo" type="primary" round>Save</el-button>
      </span>
      <span v-if="editBillingContactInfoModal" slot="footer" class="dialog-footer">
        <el-button @click="saveNewBillingInfo" type="primary" round>Save</el-button>
        <el-button @click="showEditBillingInfo = false" type="info" round>Cancel</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :top="mobileCheck ? `10vh` : `30vh`"
      :visible.sync="cardEditModal"
      :width="mobileCheck ? `100%` : `700px`"
      center
      custom-class="edit-billing-info-dialog"
      :title="'Credit Card Details'"
    >
      <edit-card-information
        @close-edit-card-modal-open-billing="closeEditCardModalOpenBilling"
        @save-card-data-close-edit-card-modal-open-billing="closeEditCardModalSaveCardData"
        :cardEditModal="cardEditModal" />
      <!--<span slot="footer" class="dialog-footer">-->
      <!--<el-button @click="closeEditCardModalOpenBilling" type="primary" round>Back</el-button>-->
      <!--<el-button @click="cardEditModal = false" type="info" round>Cancel</el-button>-->
      <!--</span>-->
    </el-dialog>
  </el-card>
</template>

<script>
import IssuedToBilling from "../../Settings/Items/Billings/Shared/IssuedToBilling";
import PaypalIcon from "../../Settings/Icons/PaypalIcon";
import VisaIcon from "../../Settings/Icons/VisaIcon";
import CardIcon from "../../Settings/Icons/CardIcon";
import BigPaypalIcon from "../../Settings/Icons/BigPaypalIcon";
import GoodCheckmarkLightBlue from "../../Settings/Icons/GoodCheckmarkLightBlue";
import CommonBodyBillingInformation from "../../Settings/Items/Billings/ModalBodiesChangeBillingInformation/CommonBodyBillingInformation";
import EditBodyBillingInformation from "../../Settings/Items/Billings/ModalBodiesChangeBillingInformation/EditBodyBillingInformation";
import EditCardInformation from "../../Settings/Items/Billings/ModalBodiesChangeBillingInformation/EditCardInformation";
import { isEmpty } from "lodash";
import { mapState } from "vuex";
import createStripeToken from "../../../utils/createStripeToken";
import ChangePaymentButton from "./ChangePaymentButton";
import axios from "axios";

export default {
  name: "BillingInformation",
  components: {
    IssuedToBilling,
    PaypalIcon,
    VisaIcon,
    CardIcon,
    BigPaypalIcon,
    GoodCheckmarkLightBlue,
    CommonBodyBillingInformation,
    EditBodyBillingInformation,
    EditCardInformation,
    ChangePaymentButton
  },
  props: {
    billingInformation: Object,
    subscriptionData: Object,
    userInfo: Object,
    mobileCheck: Boolean
  },
  computed: {
    errorsForm: {
      get() {
        return this.$store.state.errorsForm;
      }
    },
    onAdminPage: function() {
      return this.$route.path.includes("admin");
    }
  },
  data: function() {
    return {
      isMobile: false,
      showEditBillingInfo: false,
      checkedCardBoxCard: false,
      checkedCardBoxPaypal: false,
      newInfo: {},
      cards: {},
      changePaymentMethodModal: false,
      editBillingContactInfoModal: false,
      cardEditModal: false,
      billingInfo: null,
      paymentToken: null,
      cardType: null,
      cardLast4: null,
      changePaymentDisabled: false,
      cardData: {},
      isLoading: false
    };
  },
  watch: {
    billingInformation: function(newVal) {
      this.isLoading = true;
      if (newVal) {
        this.isLoading = false;
        this.billingInfo = {
          ...newVal.general,
          name: newVal.general.full_name
        };
      }
    }
  },
  beforeDestroy() {},
  mounted() {

    if (this.userInfo.payment_method && this.userInfo.payment_method.toLowerCase() === "stripe") {
      this.checkedCardBoxCard = "stripe";
    } else if (this.userInfo.payment_method && this.userInfo.payment_method.toLowerCase() === "braintree") {
      this.checkedCardBoxCard = "braintree";
    }
  },
  created() {},
  methods: {
    openDialogChangePaymentMethod() {
      axios
        .get("/billing/get_user_cards")
        .then(response => {
          console.log(response);
          this.cards = response.data;
          this.changePaymentMethodModal = true;
          this.editBillingContactInfoModal = false;
          this.showEditBillingInfo = true;
        })
        .catch(() => {
          this.isLoading = false;
          console.warn;
        });
    },
    removeCard(card_id){
       axios
        .get(`/billing/remove_card/${card_id}`)
        .then(response => {         
        })
        .catch(() => {
        });
    },
    getUsersCard() {
      axios
        .get("/billing/get_user_cards")
        .then(response => {
          console.log(response);
          this.cards = response.data;
        })
        .catch(() => {});
    },
    openEditBillingContactInfoDialog() {
      this.changePaymentMethodModal = false;
      this.editBillingContactInfoModal = true;
      this.showEditBillingInfo = true;
    },

    changeModalToEditBillingInfo() {
      this.changePaymentMethodModal = false;
      this.editBillingContactInfoModal = true;
      this.showEditBillingInfo = true;
    },

    changeModalToEditCard() {
      this.cardEditModal = true;
      this.changePaymentMethodModal = false;
      this.editBillingContactInfoModal = false;
      this.showEditBillingInfo = false;
    },

    checkedBoxProps(card, paypal) {
      this.checkedCardBoxCard = card;
      this.checkedCardBoxPaypal = paypal;
    },

    changeNewBillingInfo(newInfo) {
      console.log(newInfo);
      this.newInfo = { ...newInfo };
    },

    closeEditCardModalOpenBilling() {
      this.cardEditModal = false;
      this.changePaymentMethodModal = true;
      this.editBillingContactInfoModal = false;
      this.showEditBillingInfo = true;
    },

    closeEditCardModalSaveCardData(newCard) {
      this.cardData = { ...newCard };
      
      this.cardEditModal = false;
      this.changePaymentMethodModal = true;
      this.editBillingContactInfoModal = false;
      this.showEditBillingInfo = true;
    },

    closeEditBillingInfoModalWithoutSaveCardData() {
      this.cardData = {};
      this.cardEditModal = false;
      this.changePaymentMethodModal = false;
      this.editBillingContactInfoModal = false;
      this.showEditBillingInfo = false;
    },

    getPaymentToken(token) {
      this.paymentToken = token;
    },

    saveNewBillingInfo() {
      const url = this.onAdminPage
        ? `/admin/users/${this.$route.params.id}/information/general`
        : "/billing/information/general";
      const put_data = {
        url,
        data: {
          ...this.newInfo
        }
      };

      this.provideData(put_data);
    },

    putBillingInfo() {
      const paymentMethod =
        this.checkedCardBoxCard || this.checkedCardBoxPaypal;
      const isStripe =
        (this.checkedCardBoxCard && !isEmpty(this.cardData)) ||
        (this.userInfo.payment_method === "paypal" &&
          paymentMethod == "stripe");
      if (!isStripe) {
        this.cardData = {};
      }
      if (isStripe || !isEmpty(this.cardData)) {
        return createStripeToken(this.cardData)
          .then(data => {
            console.log(data.card);
            this.paymentToken = data.id;
            this.cardLast4 = data.card.last4;
            this.cardType = data.card.brand;
            const put_data = {
              url: "/billing/information/general",
              data: {
                payment_method:
                  this.checkedCardBoxCard || this.checkedCardBoxPaypal,
                ...(this.cardLast4 && {
                  cardLast4: this.cardLast4
                }),
                ...(this.cardType && {
                  cardType: this.cardType
                }),

                ...(this.paymentToken && {
                  payment_token: this.paymentToken
                }),

                ...(!isEmpty(this.cardData) && this.cardData)
              }
            };
            return this.provideData(put_data);
          })
          .catch(console.warn);
      }

      const url = this.onAdminPage
        ? `/admin/users/${this.$route.params.id}/information/general`
        : "/billing/information/general";

      const put_data = {
        url,
        data: {
          payment_method: this.checkedCardBoxCard || this.checkedCardBoxPaypal,
          ...(this.paymentToken && {
            payment_token: this.paymentToken
          })
        }
      };

      this.provideData(put_data);
    },

    provideData(data) {
      this.setBillingMethodLoading(true);
      this.$store
        .dispatch("putData", {
          ...data,
          ...(data.card_number && {
            card_number: data.card_number.slice(
              data.card_number.length - 4,
              data.card_number.length
            )
          })
        })
        .then(response => {
          this.setBillingMethodLoading(false);
          // window.location.reload();
        })
        .catch(error => {
          this.setBillingMethodLoading(false);
          console.log(error);
        });

      this.showEditBillingInfo = false;
      this.$store.dispatch("getUserData");
      this.$store.dispatch("getBillingInformationData");
    },

    changeCardData(data) {
      this.cardData = {
        ...this.cardData,
        ...data
      };
    },

    setBillingMethodLoading(value) {
      this.isLoading = value;
    },
    makeCardPrimary(card_id) {
      this.doingAction = true;
      axios
        .get(`/billing/make_card_primary/${card_id}`)
        .then(response => {
          console.log(response);
          this.doingAction = false;
          // this.$emit("getCard");
          this.getUsersCard()
          this.$store.disptch('getUserData')
        })
        .catch(() => {
          this.isLoading = false;
          this.doingAction = false;
          console.warn;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.header-billinginfo {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  .title {
    font-size: 20px;
    font-weight: 500;
  }

  .subtitle {
    font-size: 14px;
  }
}

.el-card {
  margin-bottom: 15px;
  padding: 1.5rem 0rem !important;
  box-shadow: none !important;
}

.body-information {
  display: flex;
  flex-direction: row;
  .issued-column {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 50%;

    .edit-link {
      margin-top: 40px;
    }
  }
  .billing-type-column {
    .billing-type {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;

      .billing-icon {
        margin-right: 20px;
      }
    }
    .billing-email {
      margin-bottom: 40px;
      font-size: 16px;
    }

    .billing-card {
      margin-bottom: 40px;
      font-size: 13px;
    }
    .card-icon {
      width: 55px;
    }
  }

  .loading-space-users {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    z-index: 100;

    i {
      font-size: 34px;
    }

    &.overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}

.for-mobile {
  display: none;
}
.for-desktop {
  display: block;
}
@media (max-width: 1024px) {
  .for-desktop {
    display: none !important;
  }
  .for-mobile {
    display: block !important;
  }

  .name {
    width: max-content;
  }
}
</style>

