<template>
    <div class="base-cover-inner-bottom" v-loading="loading">
        <h6>
            <a href="javascript:;" @click="createUserSuccessful">Go to All Users</a>
        </h6>
        <h2 style="padding: 10px;">Create User</h2>
        <div style="box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; padding: 2%;">
            <h5 style="padding: 10px;">PROFILE INFORMATION</h5>
            <div class="d-flex flex-wrap w-100">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 uc-input-cover">
                    <label for="">First Name</label>
                    <div>
                        <input class="w-100 uc-input"  type="text" v-model="first_name" placeholder="John">
                        <div v-if="firstNameError" class="text-danger"> {{ firstNameError }}</div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 form-group uc-input-cover">
                    <label for="">Last Name</label>
                    <div>
                        <input class="w-100 uc-input"  type="text" v-model="last_name" placeholder="Doe">
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 form-group uc-input-cover">
                    <label for="">Email</label>
                    <div>
                        <input class="w-100 uc-input"  type="text" v-model="email" placeholder="john@doe.com">
                        <ul v-if="emailError.length > 0">
                            <li v-for="eErr in emailError" :key="eErr" class="text-danger">{{ eErr }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <div class="i-card i-mt-20">
            <h5 class="i-p-10">FEATURE LIMITS</h5>
            <div class="d-flex flex-wrap w-100">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                    <label for="">Total Projects Allowed</label>
                    <div>
                        <input v-if="!unlimited_projects_allowed" min="0" class="uc-input"  type="number" v-model="projects_allowed_number" placeholder="Number of Projects">
                        <div class="i-mt-10">
                            <el-checkbox v-model="unlimited_projects_allowed"></el-checkbox> &nbsp;
                            <span class="unlimited">
                                Unlimited 
                            </span>
                        </div>  
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                    <label for="">Total Videos Allowed</label>
                    <div>
                        <input v-if="!unlimited_videos_allowed" min="0" class="uc-input"  type="number" v-model="videos_allowed_number" placeholder="Number of Videos">
                        <div class="i-mt-10">
                            <el-checkbox v-model="unlimited_videos_allowed"></el-checkbox> &nbsp;
                            <span class="unlimited">
                                Unlimited 
                            </span>
                        </div>  
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                    <label for="">Player Skins Allowed</label>
                    <div>
                        <div>
                            <el-select v-model="skins_allowed" multiple placeholder="Select Player skins">
                                <el-option
                                v-for="skin in skins"
                                :key="skin.value"
                                :label="skin.label"
                                :value="skin.value">
                                </el-option>
                            </el-select>
                        </div>  
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                    <label for="">Access to Interaction Tools</label>
                    <div>
                        <div class="i-mt-10">
                            <el-switch
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#0AD688"
                            slot="reference"
                            v-model="interaction_allowed"
                            ></el-switch>
                        </div>  
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                    <label for="">Access to Video Behaviors</label>
                    <div>
                        <div class="i-mt-10">
                            <el-switch
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#0AD688"
                            slot="reference"
                            v-model="behavior_allowed"
                            ></el-switch>
                        </div>  
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 uc-input-cover" >
                    <label for="">Access to Dynamic Funnels</label>
                    <div>
                        <div class="i-mt-10">
                            <el-switch
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#0AD688"
                            slot="reference"
                            v-model="funnel_allowed"
                            ></el-switch>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <div class="i-mt-10">
            <el-checkbox v-model="automaticPassword"></el-checkbox> &nbsp;
            <span class="unlimited">
                Send Automatic Password to user 
            </span>
        </div> 
        <div v-if="!automaticPassword" class="i-card i-mt-20">
            <h5 class="i-p-10">PASSWORD</h5>
            <div class="d-flex flex-wrap w-100">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 uc-input-cover" >
                    <label for="">New Password</label>
                    <div>
                        <input class="w-100 uc-input" :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="************">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 uc-input-cover" >
                    <label for="">Confirm Password</label>
                    <div>
                        <input class="w-100 uc-input" :type="showPassword ? 'text' : 'password'" v-model="confirmPassword" placeholder="************"> 
                    </div>
                </div>
            </div>
            <div class="i-mt-2">
                <el-checkbox v-model="showPassword"></el-checkbox> &nbsp;
                <span class="unlimited">
                    Show Password 
                </span>
            </div>
            <ul v-if="passwordError.length > 0">
                <li v-for="pErr in passwordError" :key="pErr" class="text-danger">{{ pErr }}</li>
            </ul>
        </div>
        <p v-if="firstNameError || emailError.length > 0 || (!automaticPassword && passwordError.length > 0)" class="text-danger text-center">There is at least one error in the form</p>
        <div class="text-right mt-5">
            <button class="btn-primary i-btnpry" @click.prevent="createAccount">Create</button>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: 'UserCreate',
    props: ['user'],
    data: () => ({
        password: '',
        confirmPassword: '',
        automaticPassword: false,
        showPassword: false,
        first_name: '',
        last_name: '',
        email: '',
        projects_allowed_number: 0,
        unlimited_projects_allowed: false,
        videos_allowed_number: 0,
        unlimited_videos_allowed: false,
        interaction_allowed: 0,
        behavior_allowed: 0,
        funnel_allowed: 0,
        skins: [
            {label: 'Aero', value: 'aero'},
            {label: 'Bold', value: 'bold'},
            {label: 'Clean', value: 'clean'},
            {label: 'Flow', value: 'flow'},
            {label: 'Fluid', value: 'fluid'},
            {label: 'Frequency', value: 'frequency'},
            {label: 'Modern', value: 'modern'},
            {label: 'Oxygen', value: 'oxygen'},
            {label: 'Silk', value: 'silk'},
            {label: 'Transient', value: 'transient'},
        ],
        skins_allowed: [],
        firstNameError: '',
        emailError: [],
        passwordError: [],
        createClicked: false,
        loading: false,
    }),
    watch: {
        first_name() {
            if(this.createClicked) {
                this.verifyForm()
            }
        },
        email() {
            if(this.createClicked) {
                this.verifyForm()
            }
        }
    },
    methods: {
        ...mapActions(['registerUser']),
        verifyForm() {
            this.firstNameError = ''
            this.emailError = []
            this.passwordError = []
            if(this.first_name.length < 1) {
                this.firstNameError = 'First name is required'
            }
            if(this.email.length < 1) {
                this.emailError.push('Email is required');
            }
            else if(!this.validEmail(this.email)) {
                this.emailError.push('Invalid email');
            }
            if(!this.automaticPassword) {
                if(this.password.length < 8) {
                    this.passwordError.push('Password is required and Password must be at least 8 characters long');
                }
                else if(this.password != this.confirmPassword) {
                    this.passwordError.push('Password not match');
                }
            } else {
                this.passwordError = []
            }
            this.createClicked = true;

            
        },
        async createAccount() {
            await this.verifyForm();
            if(this.firstNameError.length > 0 || this.emailError.length > 0 || this.passwordError.length > 0) {
                return false;
            }
            this.loading = true;
            let credentials = {
                first_name : this.first_name,
                last_name : this.last_name,
                email : this.email,
                user_id : this.user.id,
                projects_allowed_number : this.unlimitedCheck(this.projects_allowed_number, this.unlimited_projects_allowed),
                videos_allowed_number : this.unlimitedCheck(this.videos_allowed_number, this.unlimited_videos_allowed),
                interaction_allowed : this.interaction_allowed,
                behavior_allowed : this.behavior_allowed,
                funnel_allowed : this.funnel_allowed,
                skins_allowed : JSON.stringify(this.skins_allowed),
                automaticPassword: this.automaticPassword,
            }
            if(!this.automaticPassword) {
                credentials.password = this.password;
                credentials.password_confirmation = this.confirmPassword;
            }
            // this.registerUser(credentials )
            this.axios.post('/sub-reg', credentials)
            .then(response => {
                this.loading = false;
                if (response.status === 201) {
                    this.$message({
                        message: response.data.message,
                        type: 'success',
                    });
                    this.createUserSuccessful();
                } else if(response.status === 401) {
                    this.$message.error(response.data.message);
                    this.$store.state.userData = null;
                    this.$store.dispatch("destroyToken").then(response => {
                        window.location.replace("/login/1");
                    });
                } else if(response.status === 403) {
                    this.$message.error(response.data.message);
                    window.location.replace("/settings");
                } else if(response.data.old_customer == true) {
                    this.$message.error(response.data.message);
                }
                else {
                    this.$message.error(response.data.message);
                }
            })
            .catch(error => {
                console.log(error)
                this.loading = false;
                if(error.response.data.old_customer == true) {
                    this.$message.error(error.response.data.message);
                } else {
                this.$message.error(error.response ? error.response.data.message : 'Error! Something went wrong');
                    if(error.response.status === 403) {
                        window.location.replace("/settings");
                    } else if(error.response.status === 401) {
                        this.$store.state.userData = null;
                        this.$store.dispatch("destroyToken").then(response => {
                            window.location.replace("/login/1");
                        });
                    } 
                } 
            });
        },
        unlimitedCheck(limited, unlimited) {
            return limited = unlimited ? 'unlimited' : limited;
        },
        createUserSuccessful() {
            this.$emit('userCreated');
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
    },
}
</script>

<style>

    .i-btnpry:hover {
        background-color: #053083 !important ;
        color: #fff !important ;
    }
</style>