<template>
    <div class="settings-right-area" id="settings-billing">
        <right-header-footer class="header-items m-top-3">
            <template v-slot:title>
<!--                <router-link-->
<!--                    :to="{name: 'SubscriptionPlan', params: { section: 'billing'}}"-->
<!--                >-->
                    Subscription plan
<!--                </router-link>-->
            </template>

<!--            <template v-slot:second-title>-->
<!--                <router-link-->
<!--                    :to="{name: 'BillingInformationPage', params: { section: 'billing'}}"-->
<!--                >Billing information</router-link>-->
<!--            </template>-->

            <template v-slot:actions>
                <MobileMenu :isMobile='true' :rightArrow='true' class='mobileMenu'></MobileMenu>
            </template>
        </right-header-footer>

        <el-container>
            <el-card>
            <el-row>
                <el-col :span="24" >
                    <div class="card-panel">
                        <div class="card-header">
                            <h3 class="heading">Subscription Plan</h3>
                        </div>
                        <div class="card-body">
                            <div v-if="subscription === null">

                                <p class="plan-info">
                                    Please subscribe 4kplayer
                                </p>
                            </div>

                            <div class="current-plan section" v-else>

                                <div>

                                    <el-row>
                                        <el-col :span="6">
                                            <span>Current Plan:</span>
                                        </el-col>
                                        <el-col :span="6">
                                            <span>{{subscription.name}}</span>
                                        </el-col>
                                    </el-row>

                                </div>

                                <div>

                                    <el-row>
                                        <el-col :span="6">
                                            <span>Amount:</span>
                                        </el-col>
                                        <el-col :span="6">
                                            <span>${{subscription.plan.amount}}</span>
                                        </el-col>
                                    </el-row>

                                </div>
                                <div>

                                    <el-row>
                                        <el-col :span="6">
                                            <span>Paid at:</span>
                                        </el-col>
                                        <el-col :span="6">
                                            <span>{{changeDateFormate(subscription.created_at)}}</span>
                                        </el-col>
                                    </el-row>

                                </div>
                                <div>

                                    <el-row>
                                        <el-col :span="6">
                                            <span>Renews:</span>
                                        </el-col>
                                        <el-col :span="6">
                                            <span>Lifetime</span>
                                        </el-col>
                                    </el-row>

                                </div>

                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            </el-card>
        </el-container>

        <router-view />
    </div>
</template>

<script>
import { mapState } from "vuex";
import RightHeaderFooter from "./Layout/RightHeaderFooter";
import SubscriptionPlan from "./Items/Subscription/SubscriptionPlan";
import ChangeSubscriptionPlan from "./Items/Subscription/ChangeSubscriptionPlan";
import BillingEstimate from "./Items/Billings/BillingEstimate";
import BillingInformationPage from "./Items/Billings/BillingInformationPage";
import BillingReceipt from "../Shared/BillingInformation/BillingReceipt";
import MobileMenu from './Layout/MobileMenu';

export default {
    name: "Subscription",
    components: {
        RightHeaderFooter,
        // SubscriptionPlan,
        // ChangeSubscriptionPlan,
        // BillingEstimate,
        // BillingInformationPage,
        // BillingReceipt,
        // MobileMenu
    },
    props: {},
    computed: {
        // errorsForm: {
        //     get() {
        //         return this.$store.state.errorsForm;
        //     }
        // },
        // ...mapState({
        //     invoiceDetails: state => state.invoiceDetails
        // })
    },
    data: () => ({
        subscription: null,
        billing: false
    }),
    watch: {
        // "$route.path": function(newVal) {
        //     this.setBreadcrumbFlag(newVal);
        // }
    },
    beforeDestroy() {},
    mounted() {
    },
    created() {this.getData()},
    methods: {
        changeDateFormate(date){
            var myArr = date.split(" ");
            var data = myArr[0].split("-");


            var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var d = new Date(myArr[0]);
            var monthName=months[d.getMonth()];

            return monthName+ ' '+ data[2] + ', '+ data[0];

        },
getData(){
    let post_data = {

        url: "/list/subscription"
    };

    this.$store.dispatch("post", {...post_data})
        .then(response => {
            if (response.data.success === true) {
               this.subscription = response.data.subscription
            }
        })
        .catch(error => {
        });
}
    }
};
</script>

<style lang="less" scoped>
.bread-crumbs {
    margin-bottom: 25px;

    .breadcrumbs-item_cursor {
        cursor: pointer;
    }
}

.mobileMenu {
    display: none;
}

@media (max-width: 1024px) {
    .mobileMenu {
        display: block !important;
    }
}

// @media screen and (max-width: 1600px) {
//     .header-items {
//         width: calc(50% + 40px) !important;
//         padding: 0px 0rem 2rem 6rem !important;
//     }
// }

@media (max-width: 960px) {
    .el-container,
    .is-always-shadow {
        padding: 0px !important;
    }
}
</style>

