<template>
    <el-aside class="settings-left-side" id="settings-left-side">
        <smenu v-bind:active-tab="activeTab" v-bind:items="menuItems"></smenu>
    </el-aside>
</template>

<script>
    import smenu from "./menu/Menu";

    export default {
        name: "LeftSide",
        props: {
            activeTab: {
                type: String,
                default: "profile"
            }
        },
        components: {
            smenu
        },
        computed: {
            menuItems: {
                get() {
                    let newArr = [];
                    this.$store.state.menu.forEach(el => {
                        if(this.$store.getters.userInfo.subuser && el.name == 'Users') {}
                        else {
                            newArr.push(el);
                        }
                    });
                    return newArr;
                }
            }
        },
        mounted() {
            this.mobileCheck();
        },
        methods: {
            mobileCheck() {
                let BC_onMobile = false;
                if (
                    window.screen.availWidth <= 414 ||
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    )
                ) {
                    BC_onMobile = true;
                }

                if (BC_onMobile) {
                    document.querySelector(".settings-left-side").style.width =
                        "129px";
                } else {
                    document.querySelector(".settings-left-side").style.width =
                        "403px";
                }
            }
        }
    };
</script>

<style lang="less" scoped>
    .settings-left-side {
        background-color: var(--backgroundThemeColor);
        border-right: 1px solid #fff;
        //NEMESIS REMOVE height: ~"calc(100vh - 75px)";
        padding: 20px 40px;


         @media(max-width:414px){
            width: 100% !important;
            padding: 15px;
            min-height: fit-content !important
        }

        .el-menu {
            background-color: var(--backgroundThemeColor) !important;
        }

        .el-menu-item:focus, .el-menu-item:hover {
            background-color: #ffffff33 !important;
        }
    }
</style>
