<template>
    <el-container>
        <el-card>
            <div class='upload-restriction-dialog-3' v-if='freePack && freePack != "NA"'>
                <div  class="warn-sign"><img src="/static/img/exclamation.svg" alt=""></div>
                <p>Your current plan doesn’t allow multiple users.</p>
                <p>Get Unlimited Users, Projects Collaboration, Advanced Content Security and access to more features when you upgrade today.</p>
                <img width="170.87" height="187.22" src="../../../assets/img/settings/no-users.svg">
                <el-button round class="restriction_dialog_btn" type="success" @click="showUpgradeOptions"> 
                    <span style='color: #fff'>
                        See Upgrade Options 
                    </span>
                </el-button>
            </div>
            <div :class='freePack == "NA" || freePack ? "blurred" : ""'>
                <div class="header-title" style='padding: 0 10px !important'>Users & Permissions</div>
                <div class="m-bottom-1" style='padding: 0 10px !important'>
                    <span v-if="sub_users.length === 1">View</span> Manage your account users. Use Permissions to limit what they can access.
                </div>

                <el-table
                    :data="sub_users"
                    class="user-lists-table"
                    ref="userListTable"
                    style="width: 100%"
                >
                    <el-table-column label prop="id" width="80">
                        <template slot-scope="scope">
                            <div class="w-100">
                                <img
                                    :src="croppa(scope.row.settings.avatar, 150, 150)"
                                    alt="User Avatar"
                                    class="img-circle profile-avatar"
                                />
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="NAME" width="295">
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                            <div class="small">{{ scope.row.email }}</div>
                            <div class="small">Created: {{ scope.row.member_since }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="LAST ACTIVITY" width="160">
                        <template slot-scope="scope">
                            <div>{{ scope.row.last_signin }}</div>
                            <div class="disabled" v-if="!scope.row.login_city">Unknown Location</div>
                            <div v-else>In {{ scope.row.login_city }}, {{ scope.row.login_country }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="ROLE" width="140">
                        <template slot-scope="scope">
                            <el-tag
                                class="text-uppercase"
                                style="font-size: 13px !important;"
                                disable-transitions
                                type="info"
                            >{{ scope.row.pivot.role }}
                            </el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="ACTIVE" width="130">
                        <template slot-scope="scope">
                            <el-popover
                                content="The Owner account cannot be Disabled or Deleted."
                                placement="top"
                                trigger="hover"
                                v-if="scope.row.pivot.role === 'owner'"
                                popper-class="custom_popover pop_top_220x62"
                            >
                                <el-switch
                                    :active-value="1"
                                    :disabled="scope.row.pivot.role === 'owner'"
                                    :inactive-value="0"
                                    active-color="#0AD688"
                                    active-icon-class="el-icon-check success"
                                    slot="reference"
                                    v-model="scope.row.user_status"
                                ></el-switch>
                            </el-popover>

                            <el-switch
                                :active-value="1"
                                :inactive-value="0"
                                @change="changeEnableDisable(1, scope.row)"
                                active-color="#0AD688"
                                active-icon-class="el-icon-check success"
                                v-else-if="logged_in_user.currentTeam.role === 'owner' || logged_in_user.id === scope.row.id"
                                v-model="scope.row.user_status"
                            ></el-switch>

                            <el-popover
                                content="You can't Enable or Disable other subuser accounts."
                                placement="top"
                                trigger="hover"
                                v-else
                                popper-class="custom_popover pop_top_220x62"
                            >
                                <el-switch
                                    :active-value="1"
                                    :disabled="true"
                                    :inactive-value="0"
                                    active-color="#0AD688"
                                    active-icon-class="el-icon-check success"
                                    slot="reference"
                                    v-model="scope.row.user_status"
                                ></el-switch>
                            </el-popover>
                        </template>
                    </el-table-column>

                    <el-table-column label prop="id" width="100">
                        <template slot-scope="scope">
                            <el-dropdown @command="dropdownSelect"
                                        v-if="logged_in_user.currentTeam.role === 'owner' || logged_in_user.id === scope.row.id"
                                        class="m-left-2"
                                        trigger="click"
                                        placement="bottom">
                                <div class="el-dropdown-link btn btn-circle">
                                    <i class="el-icon-more"></i>
                                </div>

                                <el-dropdown-menu slot="dropdown"  class="custom_dropdown dropdown_bottom_200">
                                    <el-dropdown-item command="userDetail">
                                        <div @click="selUser(scope.row)" class="w-100">User Details</div>
                                    </el-dropdown-item>

                                    <el-dropdown-item command="editUser">
                                        <div @click="selUser(scope.row)" class="w-100">Edit User</div>
                                    </el-dropdown-item>

                                    <el-dropdown-item command="downloadCredentials">
                                        <div
                                            @click="selUser(scope.row)"
                                            class="w-100"
                                        >Download Credentials
                                        </div>
                                    </el-dropdown-item>

                                    <el-dropdown-item
                                        command="deleteUser"
                                        divided
                                        v-if="scope.row.pivot.role !== 'owner'"
                                    >
                                        <div @click="selUser(scope.row)" class="w-100 delete">Delete</div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>

                <blank-user v-if="sub_users.length === 1"></blank-user>
            </div>
        </el-card>

        <el-dialog
            :top="mobileCheck ? `10vh` : `30vh`"
            :visible.sync="deleteDialog"
            :width="mobileCheck ? `100%` : `600px`"
            center
            custom-class="pages-custom-dialog"
            title="ARE YOU SURE?"
        >
            <el-container>
                <div class="align-items-center justify-content-center">
                    <img alt src="../../../assets/img/settings/delete-user-popup-img.svg"/>
                </div>
                <div class="m-top-3 text-center">You can lost all settings of this account.</div>
            </el-container>
            <span class="dialog-footer" slot="footer">
                <el-button @click="deleteDialog = false" round type="info">Cancel</el-button>
                <el-button @click="deleteSubUser" round type="danger">Delete</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>

<script>
    import BlankUser from "./BlankUser";

    export default {
        name: "UserList",
        components: {BlankUser},
        props: {
            logged_in_user: Object,
            sub_users: Array,
            role: String,
            freePack: [Boolean, String]
        },
        data: () => ({
            selected_user: {},
            deleteDialog: false,
        }),
        computed: {
            mobileCheck: {
                get() {
                    let BC_onMobile = false;
                    if (
                        window.screen.availWidth <= 414 ||
                        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                            navigator.userAgent
                        )
                    ) {
                        BC_onMobile = true;
                    }

                    return BC_onMobile;
                }
            }
        },
        methods: {
            changeEnableDisable(flag, user) {
                let title = "User Account";
                if (flag === 1) {
                    if (user.user_status === 1) {
                        title += " Enabled";
                    } else {
                        title += " Disabled";
                    }
                }

                window.vEvent.fire("settings-common-success", {
                    title: title,
                    message: user.name,
                    close: false
                });

                this.selected_user = user;

                let post_data = {
                    url: "/settings/disable-enable-sub-user",
                    data: {
                        id: this.selected_user.id,
                        user_status: user.user_status
                    }
                };

                this.sendUpdateData(post_data);
            },

            selUser(user) {
                this.selected_user = user;
                this.$emit("set-sel-user", user);
            },

            dropdownSelect(command) {
                if (command === "editUser") {
                    window.vEvent.fire("set-user-page-type", 3);
                } else if (command === "deleteUser") {
                    if (this.selected_user.role === "subuser") {
                        this.$emit("delete-dialog");
                    } else {
                        this.deleteDialog = true;
                    }
                } else if (command === "downloadCredentials") {
                    const rows = [
                        ["User Email:", this.selected_user.email],
                        ["Login URL:", location.origin + "/login"]
                    ];

                    let csvContent =
                        "data:text/csv;charset=utf-8," +
                        rows.map(e => e.join(",")).join("\n");

                    let encodedUri = encodeURI(csvContent);
                    let link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "AccountCredentials.csv");
                    document.body.appendChild(link);

                    link.click();
                } else if (command === "userDetail") {
                    if (this.selected_user.pivot.role !== "owner") {
                        window.Ls.set(
                            "sub-user-info",
                            JSON.stringify(this.selected_user)
                        );
                    } else {
                        window.Ls.remove("sub-user-info");
                    }
                    this.$router.push("/settings/profile");
                    this.$forceUpdate();
                }
            },
            deleteSubUser() {
                let post_data = {
                    url: "/settings/delete-sub-user",
                    data: {
                        id: this.selected_user.id
                    }
                };

                this.sendUpdateData(post_data);
            },
            sendUpdateData(post_data) {
                this.$store.dispatch("post", {...post_data})
                .then(response => {
                    if (response.data.result === "success") {
                        this.deleteDialog = false;
                        let vm = this;
                        this.$nextTick(function () {
                            this.$store
                            .dispatch("getUserData")
                            .then(re => {
                                vm.$forceUpdate();
                                window.vEvent.fire("set-user-page-type", 1);
                            });
                        });
                    }
                });
            },
            showUpgradeOptions() {
                this.$router.push({name : 'ChangeSubscriptionPlan', params: {'section': 'billing'} });
            }
        },
    };
</script>

<style lang="less">
.blurred {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
    .m-bottom-4 {
        margin-bottom: 2.25rem;
    }

    .settings-users {
        > .el-container {
            > .el-card {
                .layouts-header {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }

                .user-lists-table {
                    border: 0;

                    .profile-avatar {
                        width: 50px;
                        height: 50px;
                    }

                    .user-name {
                        font-size: 1.7rem;
                    }

                    &:before {
                        height: 0;
                    }

                    &:after {
                        width: 0;
                    }

                    thead {
                        border: 0;

                        th {
                            border: 0;
                            background: transparent;

                            .cell {
                                background: transparent;
                            }
                        }
                    }

                    .disabled {
                        color: #b3b3b3;
                    }

                    .el-tag {
                        border-radius: 20px;
                        padding: 0 12px;
                        height: 25px;
                        line-height: 25px;
                    }
                }
            }
        }
    }

.upload-restriction-dialog-3 {
    font-size: 14px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 40;
}
.upload-restriction-dialog-3 p {
    color: #21455E;
    margin-top: 15px;
}
.warn-sign {
    position: relative;
    width: 60px;
    height: 60px;
    text-align: center;
    margin-bottom: 15px;
    img {
        max-width: 100%;
        object-fit: cover;
    }
}

.restriction_dialog_btn{
    border-radius: 19px;
    padding: 8px 25px;
    background-color: #F27500;
    border-color: #F27500;
    color: white !important;
    margin-top: 15px;
}
</style>
