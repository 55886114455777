<template>
    <el-col :span="8" v-if="invoice">
        <el-row class="title">
            <span>Details</span>
        </el-row>

        <el-row class="name">
            <span>Order {{invoice.receipt_id}}</span>
        </el-row>

        <el-row v-if="invoice.created_at" class="text">
            <span>Due {{setDate(invoice.updated_at)}} at {{setTime(invoice.updated_at)}}</span>
        </el-row>

        <el-row v-if="invoice.updated_at && invoice.status === 'paid'" class="text">
            <span>Paid {{setDate(invoice.updated_at)}} at {{setTime(invoice.updated_at)}}</span>
        </el-row>

        <el-row v-if="invoice.status === 'refund'" class="text">
            <span>Refunded {{setDate(invoice.updated_at)}} at {{setTime(invoice.updated_at)}}</span>
        </el-row>
    </el-col>
</template>

<script>
import moment from "moment";

export default {
    name: "InvoiceDetails",
    components: {},
    props: {
        invoice: Object
    },
    computed: {},
    data: () => ({}),
    beforeDestroy() {},
    mounted() {},
    created() {},
    methods: {
        setDate(val) {
            return moment(val).format("LL");
        },

        setTime(val) {
            return moment(val).format("LT");
        },
    }
};
</script>

<style lang="less" scoped>
.title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;
}

.name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.text {
    font-size: 13px;
}
</style>

