<template>
  <el-container
    class="cards-container change-subs-plan-container"
    style="
       {
        overflow: visible;
      }
    "
    v-if="subscriptionData"
  >
    <div>
      <base-loader :showLoader='isLoading' />
      <change-subscription-plan-v2 :subscriptionData='subscriptionData' @openChangePlan='openChangePlan' :baseLoading='isLoading'/>
    </div>
    <div class="v2-dialogs">
      <div v-if='dialogVisible && requestFor === "annual"'>
        <switch-annual-dialog
          :dialogVisible='dialogVisible && requestFor === "annual"'
          :subscriptionData='subscriptionData'
          :annualPlan='annualPlan'
          :updateAccount='updateAccount'
          :userInfo='userInfo'
          :planData='planData'
          @close='dialogVisible = false'
          @change='changePlanToAnnual'
          @updateAccount='updateAccount = $event' />
      </div>
      <div v-if='dialogVisible && requestFor === "monthly"'>
        <update-plan
          title='working'
          :planData='planData'
          :dialogVisible='true'
          @close='dialogVisible = false'
          @change='changePlanToAnnual'
          @updateAccount='updateAccount = $event'
          :updateAccount='updateAccount'
        />
      </div>
    </div>
    <div v-if='messages && messages.length'>
      <base-message-box
        head='Subscription update'
        :messages="messages"
        @close='messages = null'
      />
    </div>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardPlan from "./CardPlan";
// import { SUBSCRIPTION_API } from "../../../../../config/endpoints";
import moment from "moment";
import PaypalIcon from "../../../Settings/Icons/PaypalIcon";
import VisaIcon from "../../../Settings/Icons/VisaIcon";
import CardIcon from "../../../Settings/Icons/CardIcon";
import BigPaypalIcon from "../../../Settings/Icons/BigPaypalIcon";
import ChangeSubscriptionPlanV2 from './ChangeSubscriptionPlanV2.vue';
import SwitchAnnualDialog from './SwitchAnnualDialog';
import BaseMessageBox from '../../../Common/Global/BaseMessageBox.vue';
import UpdatePlan from './UpdatePlan';

export default {
  name: "ChangeSubscriptionPlan",
  components: {
    CardPlan,
    PaypalIcon,
    VisaIcon,
    CardIcon,
    BigPaypalIcon,
    ChangeSubscriptionPlanV2,
    SwitchAnnualDialog,
    BaseMessageBox,
    UpdatePlan,
  },
  computed: {
    errorsForm: {
      get() {
        return this.$store.state.errorsForm;
      },
    },

    ...mapState({
      subscriptionPlans: (state) => state.subscriptionPlans,
      subscriptionData: (state) => state.subscription,
      userInfo: (state) => state.userData,
    }),
  },
  watch: {
    subscriptionPlans: function (val) {
      if (this.subscriptionData) {
        val.forEach((element) => {
          this.sortedPlans.push(element);
        });

        this.sortedPlans.sort((a, b) => a.price - b.price);

        this.sortedPlans.map((element, index) => {
          if (
            element.name === this.subscriptionData.plan.name &&
            element.features.length
          ) {
            element.upgradeFlag = "Current";
            this.indexCurrentPlan = index;
          }

          if (
            element.name === this.subscriptionData.plan.name &&
            element.interval === "yearly"
          ) {
            this.annualPlan = element;
          }
        });

        this.sortedPlans.map((element, index) => {
          if (index < this.indexCurrentPlan && element.features.length) {
            element.upgradeFlag = "Downgrade";
          } else if (index > this.indexCurrentPlan && element.features.length) {
            element.upgradeFlag = "Upgrade";
          }

          if (element.name === "Free Forever") {
            this.freePlan.push(element);
          } else if (element.name === "Starter") {
            this.starterPlan.push(element);
          } else if (element.name === "Pro") {
            this.proPlan.push(element);
          } else if (element.name === "Business") {
            this.bussinessPlan.push(element);
          }
        });
      }
    },
  },
  data: () => ({
    isMobile: false,
    starterPlan: [],
    proPlan: [],
    bussinessPlan: [],
    freePlan: [],
    expanded: false,
    sortedPlans: [],
    indexCurrentPlan: null,
    isLoading: false,
    dialogVisible: false,
    annualPlan: null,
    updateAccount: false,
    ownerVideos: null,
    v2Plans: ['adilo-basic-monthly', 'adilo-growth-monthly', 'adilo-unlimited-monthly', 'adilo-growth-annual', 'adilo-unlimited-annual', 'adilo-unlimited-semi-annual'],
    requestFor: 'annual',
    planData: {
      credit: 0,
      debit: 0,
      new_plan_id: null,
      new_plan_name: null,
      update_type: null,
      platform: null,
      customer_id: null,
    },
    messages: null,
    refUserId: null,
    linkId: null,
    // Added to avoid error
    credentials: {
      refUserId: null,
      linkId: null,
    },
  }),

  beforeDestroy() {},
  mounted() {
    this.$store.dispatch("getSubscriptionPlans");
    this.$store.dispatch("getSubscriptionData");
  },
  created() {
    this.$store.dispatch("getSubscriptionPlans");
    this.$store.dispatch("getSubscriptionData");
    this.allVideos();
    this.credentials.refUserId = this.getCookie('ref')
    this.credentials.linkId = this.getCookie('lid')
  },
  methods: {
    // Added to avoid error
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    openChangePlan(data) {
      this.dialogVisible = true;
      const { type, plan_id, credit, debit, new_plan_id, new_plan_name, update_type, platform, customer_id } = data;
      this.planData.credit = credit;
      this.planData.debit  = debit;
      this.planData.new_plan_id = new_plan_id;
      this.planData.new_plan_name = new_plan_name;
      this.planData.update_type = update_type;
      this.planData.platform    = platform;
      this.planData.customer_id = customer_id;
      this.requestFor = type;

      if (type === 'annual') {
        let annualPlan = this.subscriptionPlans.filter(plan => plan.id === plan_id);
        if (annualPlan.length) {
          this.annualPlan = annualPlan[0];
        }
      }
    },
    expandFeatures() {
      this.expanded = !this.expanded;
    },
    getFirstBillCredit() {
      let daysInInterval = this.dateDiff(
        this.subscriptionData.subscription.updated_at,
        this.subscriptionData.subscription.ends_at
      );
      let daysRemain = this.dateDiff(
        new Date().toString(),
        this.subscriptionData.subscription.ends_at
      );
      let credit =
        (daysRemain * this.subscriptionData.plan.price) / daysInInterval;
      return credit.toFixed(2);
    },
    dateDiff(from, to) {
      return moment(to).diff(moment(from), "days");
    },
    getNextPaymentDate() {
      let d = new Date();
      d.setMonth(d.getMonth() + 8);

      return moment(String(d)).format("MMMM DD, YYYY");
    },

    setChangePlanLoading(value) {
      this.isLoading = value;
    },
    changePlanToAnnual() {

      const { platform } = this.planData;
      const ref = this;
      switch (platform) {
        case 'fast-spring':
          addEventListener('fast-spring-cart-close', function (order) {
            ref.processFastSpringOrder(order.detail);
          });
          this.checkoutFastspring();
        break;
      }

    },
    checkoutFastspring()
    {
      const { customer_id, platform, new_plan_id, credit, debit } = this.planData;

      const data = {
        customer_id,
        product_id: new_plan_id,
        platform_id: platform,
        update: true,
        price: credit,
        checkout_page: 'main'
      }
      this.isLoading = true;
      this.axios.post('payments/sessions', data)
        .then(response => {
          this.isLoading = false;
          const session = response.data.session;
          const sessionId = session ? session.id : false;
          const overage = session.overage;
          console.log(sessionId);
          if (sessionId) fastspring.builder.checkout(sessionId);
          else alert('Failed to create checkout session.');
        }).catch(error => {
          this.isLoading = false;
          const details = error.response.data;
          console.log(details);
        });
    },
    processFastSpringOrder(order)
    {



      if (order && order != 'undefined' && order.id) {
        this.isLoading = true;
        this.dialogVisible = false;
        this.axios.post('payments/subscription', {
          object_id: order.id,
          platform_id: 'fast-spring',
          linkId: this.linkId,
          refUserId: this.refUserId,
          // update: this.planData.update_type,
        }).then(response => {
            if (response.data.user) {
                const user = response.data.user;
                // this.setUserInfo(user);
                setTimeout(() => {
                  location.reload();
                }, 2000);
            } else if (response.data.message) {
              this.messages = [response.data.message];
              this.isLoading = false;
            }
        }).catch(error => {
            this.isLoading = false;
            console.log({error});
        });
      }
    },
    allVideos() {
      this.axios
        .get("all-owner-videos?allData=true")
        .then((r) => (r.data ? (this.ownerVideos = r.data) : null));
    },
     getProration(){
      let plans = '';
       if (this.billingCycle == "annually") {
        plans = this.annualPlan.id;
      }
      if (this.billingCycle == "monthly") {
        plans = this.monthlyPlan.id;
      }
         axios
        .post('get_proration', { plan_id: plans,'type':'annual' })
        .then((data) => {
          console.log(data);
          this.dialogVisible = true
        })
        .catch(() => {
          this.$emit("loading-changeplan", false);
          console.warn;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.subscription-and-billing {
  position: relative;
}

.hr-div {
  border-bottom: 1px solid rgb(209, 219, 229);
  width: 150%;
  overflow: hidden;
  margin-left: -10%;
  margin-top: 25px;
  margin-bottom: 25px;
}

section.cards-container {
  overflow: visible !important;
}

.three-cards {
  width: 145%;
  margin-left: -16%;
  margin-top: 30px;
}

.card-first-second {
  margin-right: 20px;
}

.subscription-text {
  &__bold {
    font-weight: bold;
  }

  &__size {
    font-size: 20px;
  }

  &__margin {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &__div {
    background: rgb(253, 246, 236);
    border-radius: 15px;
    text-align: center;
    width: 45%;
    color: #f27500;
    margin-left: 55%;
    font-size: 13px;
  }

  &__annual-block {
    margin-top: 6px;
    text-align: end;
  }

  &__margin-bottom {
    margin-bottom: 5px;
  }

  &__light {
    color: rgb(134, 149, 160);
  }
}

.enterprise-card {
  margin-top: 30px;

  &__header {
    background: #00acdc;
    padding: 1.5rem 6rem;
    border-radius: 15px 15px 0px 0px;
  }

  &__wrapper-text {
    background: #ffffff;
    border-radius: 0px 0px 7px 7px;
  }

  &__text {
    padding: 2.5rem 6rem 1.5rem 6rem;
  }

  &__button {
    margin-top: 10px;
    padding: 0rem 6rem 2rem 6rem;
  }
}

.card-header__name-enterprise {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
}

.free-plan-card {
  margin-top: 30px;
}

.free-plan-expand-card {
  color: #00acdc;
  text-align: center;
  padding: 0 !important;
  margin-bottom: 40px;
  cursor: pointer;
}

.loading-space-users {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  i {
    font-size: 34px;
  }

  &.overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.el-dialog__wrapper {
  .billing-type-column {
    padding-left: 25px;
    .billing-type {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;

      .billing-icon {
        margin-right: 20px;
      }
    }
    .billing-email {
      margin-bottom: 40px;
      font-size: 16px;
    }

    .billing-card {
      margin-bottom: 15px;
      font-size: 13px;
    }
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #0f0f0f;
  }
  .el-dialog {
    border: 1px solid #0bacdb;
    border-radius: 10px;
    max-width: 635px;
    .bold {
      font-weight: bold;
    }
    .green {
      color: #0ad688;
    }
    .text-wrap-normal {
      white-space: normal !important;
    }
    .el-dialog__header {
      text-align: center;
      border-bottom: 1px solid #ebf9fa;
      padding-bottom: 15px;
      .el-dialog__title {
        color: #21455e;
        font-size: 15px;
      }
    }
    .el-dialog__body {
      padding: 15px 45px;
      p {
        color: #21455e;
        font-size: 14px;
      }
      .el-radio-group {
        margin-top: 5px;
        margin-bottom: 10px;
        .el-radio__inner,
        .el-radio__inner:hover {
          width: 16px;
          height: 16px;
          border: 2px solid #21455e;
        }
        .el-radio__inner::after {
          width: 8px;
          height: 8px;
          background-color: #21455e;
        }
        label.el-radio {
          display: block;
          margin-left: 0 !important;
        }
        .el-radio__input.is-checked .el-radio__inner {
          background: #ffffff;
        }
      }
      .el-radio__label {
        color: #21455e;
        font-size: 14px;
        font-weight: 100;
        padding-left: 18px;
      }
      .list-group-item {
        list-style: none;
        padding-left: 0;
        display: inline-block;
        max-width: 350px;
        width: 100%;
        li {
          display: flex;
          padding: 15px 0;
          justify-content: space-between;
          border-bottom: solid 1px #e8e8ea;
        }
      }
    }
  }
  .el-dialog__footer {
    text-align: center;
    padding: 20px;
    .dialog-footer {
      .custom-btn {
        border-radius: 15px;
        &.custom-cancel-btn {
          background-color: #637c8e;
          color: #ffffff;
        }
        .custom-confirme-btn {
        }
      }
    }
  }
  label.el-checkbox {
    display: flex;
    .el-checkbox__label {
      margin-top: -2px;
    }
  }
}
.helplink{
  color:#fff
}
@media screen and (max-width: 1800px) {
  .three-cards {
    margin-left: calc(40% - 50px);
    width: calc(165% - 40px);
  }
}

@media screen and (max-width: 1500px) {
  .three-cards {
    margin-left: calc(41% - 50px);
    width: calc(160% - 40px);
  }

  .card-first-second {
    margin-right: calc(0% + 2px);
  }
}

@media screen and (max-width: 1440px) {
  .three-cards {
    margin-left: calc(44% - 50px);
    width: calc(76% + 40px);
  }

  .card-first-second {
    margin-right: calc(0% + 1px);
  }
}
</style>
