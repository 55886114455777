<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.341"
        height="25.552"
        viewBox="0 0 21.341 25.552"
    >
        <g id="paypal_1_" data-name="paypal (1)" transform="translate(-42.193)">
            <path
                id="Path_411"
                data-name="Path 411"
                d="M61.491,6.6a8.782,8.782,0,0,0,.066-1.073A5.529,5.529,0,0,0,56.029,0h-9.24a1.19,1.19,0,0,0-1.174.993l-3.4,20.236a1.19,1.19,0,0,0,1.174,1.388h3.379a1.2,1.2,0,0,0,1.183-.993l.016-.094h0l-.471,2.8a1.049,1.049,0,0,0,1.035,1.223h2.956a1.049,1.049,0,0,0,1.035-.875l.84-4.992a1.787,1.787,0,0,1,1.762-1.491H55.9a7.638,7.638,0,0,0,7.638-7.638A4.845,4.845,0,0,0,61.491,6.6Z"
                transform="translate(0 0)"
                fill="#002987"
            />
            <path
                id="Path_412"
                data-name="Path 412"
                d="M162.07,132.28a8.705,8.705,0,0,1-8.638,7.631h-2.578a1.293,1.293,0,0,0-1.242.936l-1.541,9.16a1.049,1.049,0,0,0,1.035,1.223h2.956a1.049,1.049,0,0,0,1.035-.875l.84-4.992a1.787,1.787,0,0,1,1.762-1.491h.777a7.638,7.638,0,0,0,7.638-7.638h0A4.845,4.845,0,0,0,162.07,132.28Z"
                transform="translate(-100.579 -125.678)"
                fill="#0085cc"
            />
            <path
                id="Path_413"
                data-name="Path 413"
                d="M180.475,122.831h2.578a8.705,8.705,0,0,0,8.638-7.631,4.828,4.828,0,0,0-2.809-.9h-6.73a1.569,1.569,0,0,0-1.548,1.309l-1.372,8.155A1.293,1.293,0,0,1,180.475,122.831Z"
                transform="translate(-130.2 -108.599)"
                fill="#00186a"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "PaypalIcon",
    props: {
        color: {
            type: String,
            default: "#00acdc"
        }
    }
};
</script>

<style lang="less" scoped>
</style>

