<template>

    <div class="referral-list-component">
        <div class="header-title">
            <span>Your Referral Links</span>
        </div>
        <header class="component__header">
            <div class="d-flex align-items-center">
                <div class="content__col content__col-head">
                    CAMPAIGN
                </div>
                <div class="content__col content__col-head clicks">
                    CLICKS
                </div>
                <div class="content__col content__col-head signups">
                    SIGH UPS
                </div>
                <div class="content__col content__col-head upgraded">
                    UPGRADED
                </div>
                <div class="content__col content__col-head">

                </div>
            </div>
        </header>
        <div class="component__body">
            <div class="project__list">
                <div class="d-flex align-items-center" v-for="(link) in linksToList">
                    <div class="content__col content__col-head">
                        <div class="link-info">

                            <span class="referral-title">{{link.title}}</span>
                            <span class="referral-link"> YOUR LINK: <a target="_blank"
                                                                       :href="link.landing_page_url+'?ref=' + user.id + '&lid=' + link.id">{{link.landing_page_url}} ?ref={{user.id}}&lid={{link.id}}</a></span>
                        </div>
                    </div>
                    <div class="content__col content__col-head clicks">
                        <div class="">{{link.total_clicks !== null ? link.total_clicks : 0}}</div>
                    </div>
                    <div class="content__col content__col-head signups">
                        <div class="">
                            {{link.total_signups !== null ? link.total_signups : 0}}
                        </div>
                    </div>
                    <div class="content__col content__col-head upgraded">
                        <div class="">{{link.total_upgraded !== null ? link.total_upgraded : 0}}</div>
                    </div>
                    <div class="content__col content__col-head">
                        <div class="">
                            <a :href="link.promo_detail_url" taget="_blank" class="btn btn-primary m-left-2 promo-detail">
                                See Promo Tools & Details
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="promo-state-tables">-->
<!--            <div class="header-title">-->
<!--                <span>Promo Stats Table</span>-->
<!--                <div class="add-new-referral">-->
<!--                    <button class="btn btn-primary see-all-referrals" @click="showYourReferrals = true">See All Referrals-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--          <div class="table-view">-->
<!--              <header class="component__header">-->
<!--                  <div class="d-flex align-items-center">-->
<!--                      <div class="content__col content__col-head month">-->
<!--                          Month-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head clicks">-->
<!--                          CLICKS-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head signups">-->
<!--                          SIGH UPS-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head upgraded">-->
<!--                          UPGRADED-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head earnings text-green">-->
<!--                          EARNINGS-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head recur">-->
<!--                          RECUR-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head clawbacks text-red">-->
<!--                          CLAWBACKS-->
<!--                      </div>-->
<!--                  </div>-->
<!--              </header>-->
<!--              <div class="component__body">-->
<!--                  <div class="project__list">-->
<!--                      <div class="d-flex align-items-center" v-for="(state, index) in promoStats">-->
<!--                          <div class="content__col content__col-head month">-->
<!--                              <div class="">-->
<!--                                  {{changeDateFormate(index)}}-->
<!--                              </div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head clicks">-->
<!--                              <div class="">{{state.clicks}}</div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head signups">-->
<!--                              <div class="">-->
<!--                                  {{state.signups}}-->
<!--                              </div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head upgraded">-->
<!--                              <div class="">{{state.upgraded}}</div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head earnings text-green">-->
<!--                              <div class="">-->
<!--                                  ${{state.earnings}}-->
<!--                              </div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head recur">-->
<!--                              <div class="">-->
<!--                                  34534545-->
<!--                              </div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head clawbacks text-red">-->
<!--                              <div class="">-->
<!--                                  34534545-->
<!--                              </div>-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </div>-->
<!--              </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="payout-tables">-->
<!--            <div class="header-title">-->
<!--                <span>Payout Table</span>-->
<!--            </div>-->
<!--          <div class="table-view">-->
<!--              <header class="component__header">-->
<!--                  <div class="d-flex align-items-center">-->
<!--                      <div class="content__col content__col-head month">-->
<!--                          Month-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head clicks">-->
<!--                          RELEASE DATE-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head signups">-->
<!--                          MODE-->
<!--                      </div>-->
<!--                      <div class="content__col content__col-head upgraded text-red">-->
<!--                          PAYOUT-->
<!--                      </div>-->

<!--                  </div>-->
<!--              </header>-->
<!--              <div class="component__body">-->
<!--                  <div class="project__list">-->
<!--                      <div class="d-flex align-items-center" v-for="(payout, index) in payouts">-->
<!--                          <div class="content__col content__col-head month">-->
<!--                              <div class="">-->
<!--                                  {{changeDateFormate(payout.created_at)}}-->
<!--                              </div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head clicks">-->
<!--                              <div class="">{{changeDateFormate(payout.created_at)}}</div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head signups">-->
<!--                              <div class="">-->
<!--                                  {{payout.type}}-->
<!--                              </div>-->
<!--                          </div>-->
<!--                          <div class="content__col content__col-head upgraded text-red">-->
<!--                              <div class="">${{payout.amount}}</div>-->
<!--                          </div>-->

<!--                      </div>-->
<!--                  </div>-->
<!--              </div>-->
<!--          </div>-->
<!--        </div>-->



<!--        <el-dialog-->
<!--                title="YOUR REFERRALS"-->
<!--                center-->
<!--                :width=" `40%`"-->
<!--                class="el-dialog&#45;&#45;huge dialog_edit dialog_border_radius "-->
<!--                :visible.sync="showYourReferrals"-->
<!--                :modalAppendToBody="false"-->
<!--        >-->

<!--            <div class="relative">-->

<!--                <div class="your-referral-detail" id="">-->

<!--                        <el-input class="filter__search" placeholder="Search Videos">-->
<!--                        <template slot="prepend">-->
<!--                            <svg xmlns="http://www.w3.org/2000/svg" width="16.6" height="16.6"-->
<!--                                 viewBox="0 0 16.6 16.6">-->
<!--                                <path id="icon_search-2"-->
<!--                                      d="M12.7,11.23a6.777,6.777,0,0,0,1.4-4.174A7.02,7.02,0,0,0,7.1,0,7.105,7.105,0,0,0,0,7.056a7.105,7.105,0,0,0,7.1,7.056,6.667,6.667,0,0,0,4.2-1.391l3,2.981a.971.971,0,0,0,1.4,0,.957.957,0,0,0,0-1.391Zm-5.6.8A5.022,5.022,0,0,1,2,7.056a5.1,5.1,0,1,1,10.2,0,5.022,5.022,0,0,1-5.1,4.969Z"-->
<!--                                      transform="translate(0.3 0.3)" fill="#00acdc" stroke="#fff"-->
<!--                                      stroke-width="0.6"/>-->
<!--                            </svg>-->
<!--                        </template>-->
<!--                    </el-input>-->

<!--                    <div class="m-top-3">-->
<!--                        <header class="component__header">-->
<!--                            <div class="d-flex align-items-center">-->

<!--                                <div class="content__col content__col-head">-->
<!--                                    USER-->
<!--                                </div>-->
<!--                                <div class="content__col content__col-head ">-->
<!--                                    PLAN-->
<!--                                </div>-->
<!--                                <div class="content__col content__col-head ">-->
<!--                                    EARNED-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </header>-->
<!--                        <div class="component__body">-->
<!--                            <div class="project__list">-->
<!--                                <div class="d-flex align-items-center" v-for="(referral) in referrals">-->

<!--                                    <div class="content__col content__col-head referral-user-logo">-->
<!--                                        <div>-->

<!--                                            <el-avatar :size="60" :src="referral.photo_url"></el-avatar>-->
<!--                                        </div>-->
<!--                                        <div class="referral-info">-->
<!--                                            <div class="w-100 text-bold">{{ referral.name }}</div>-->
<!--                                            <div class="w-100 small text-grey">{{ referral.email }}</div>-->

<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="content__col content__col-head ">-->
<!--                                        <div class="">-->
<!--                                                {{referral.current_plan.name}}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="content__col content__col-head ">-->
<!--                                        <div class=""></div>-->
<!--                                    </div>-->

<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </div>-->
<!--            <div slot="footer" class="dialog-footer">-->
<!--            </div>-->

<!--        </el-dialog>-->


    </div>

</template>

<script>

    export default {
        name: "ReferralLinks",
        components: {},
        props: {
            user: Object,
            linksToList: Object,
            promoStats: Object,
            referrals: Object,
            payouts: Object,
        },
        data: () => ({
            // linksToList: [],
            // promoStats: [],
            // referrals: [],
            // payouts: [],
            showYourReferrals: false,
            searchQuery: '',

        }),
        mounted() {
            // this.getLinkss()
        },
        methods: {
            changeDateFormate(date){
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                var parts = date.split('-');
                var mydate = new Date(parts[0] +'-01-'+parts[1]);
                return monthNames[mydate.getMonth()] + ', ' + parts[1]
            },
            // getLinkss() {
            //     var $this = this;
            //     this.axios({
            //         method: 'get',
            //         url: '/user-links',
            //         data: {
            //             data: true
            //         },
            //     }).then(response => {
            //         $this.linksToList = response.data.links;
            //         $this.promoStats = response.data.promoStats;
            //         $this.referrals = response.data.referrals;
            //         $this.payouts = response.data.payouts;
            //         console.log(response.data, 'linksToList 123  1');
            //
            //     });
            //
            // },

        }
    };
</script>

<style lang="less">
    .referral-list-component {
        font-size: 13px;
        margin: 0 0;

        .your-referral-detail{
            .el-input-group__prepend{
                border-right: none;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
                background-color: #fff;
            }
            .el-input__inner{
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                border-left: none !important;
            }
            .el-input__inner:focus {
                outline: none;
                border-color: rgb(191, 204, 217);
            }
            .referral-user-logo{
                display: flex;
                img{
                    height: 50px;
                    border-radius: 50%;
                }
                .referral-info{
                    margin: 5px 15px;
                    .text-bold{
                        font-size: 17px;
                    }
                    .text-grey{
                        color: #788899a1;
                    }
                }
            }
        }
.see-all-referrals{
    font-size: 11px !important;
    padding: 5px 15px !important;
}
        .promo-state-tables{
            .component__body{
                padding: 15px 30px !important;
            }
            .content__col-head{
                min-width: 100px ;
            }
        }

        .project__list .align-items-center {
            margin: 15px 0;
        }

        .content__col-head.clicks, .content__col-head.signups, .content__col-head.upgraded,
        .content__col-head.month, .content__col-head.earnings, .content__col-head.recur, .content__col-head.clawbacks {
            text-align: center !important;
        }

        .component__body {

            padding: 15px 0 !important;

            .promo-detail {
                font-size: 11px !important;
                padding: 5px 15px !important;
            }


        }

        .link-info {
            display: flex;
            flex-direction: column;

            .referral-title {
                font-size: 16px;
            }
        }

        .component__header {
            background-color: rgba(11, 172, 219, .02);
            padding: 15px 30px;
            line-height: 15px;
            font-size: 13px;
            font-weight: 500;

        }

        .content__col-head {
            flex-basis: 400px;
            min-width: 150px
        }

        .header-title {
            margin: 30px 0;

            > span {
                font-size: 28px;
            }

            .add-new-referral {
                float: right;
            }

            .add-new-btn {
                font-size: 15px;
            }
        }

        .btn-sm {
            font-size: 10px;
        }

    }

</style>


<style lang="less">


</style>
