<template>

    <div class="referral-list-component">



        <div class="promo-state-tables">
            <div class="header-title">
                <span>Promo Stats Table</span>
                <div class="add-new-referral">
                    <button class="btn btn-primary see-all-referrals" @click="showYourReferrals = true">See All Referrals
                    </button>
                </div>
            </div>
          <div class="table-view">
              <header class="component__header">
                  <div class="d-flex align-items-center">
                      <div class="content__col content__col-head month">
                          Month
                      </div>
                      <div class="content__col content__col-head clicks">
                          CLICKS
                      </div>
                      <div class="content__col content__col-head signups">
                          SIGH UPS
                      </div>
                      <div class="content__col content__col-head upgraded">
                          UPGRADED
                      </div>
                      <div class="content__col content__col-head earnings text-green">
                          EARNINGS
                      </div>
                      <div class="content__col content__col-head recur">
                          RECUR
                      </div>
                      <div class="content__col content__col-head clawbacks text-red">
                          CLAWBACKS
                      </div>
                  </div>
              </header>
              <div class="component__body">
                  <div class="project__list">
                      <div class="d-flex align-items-center" v-for="(state, index) in promoStats">
                          <div class="content__col content__col-head month">
                              <div class="">
                                  {{changeDateFormate(index)}}
                              </div>
                          </div>
                          <div class="content__col content__col-head clicks">
                              <div class="">{{state.clicks}}</div>
                          </div>
                          <div class="content__col content__col-head signups">
                              <div class="">
                                  {{state.signups}}
                              </div>
                          </div>
                          <div class="content__col content__col-head upgraded">
                              <div class="">{{state.upgraded}}</div>
                          </div>
                          <div class="content__col content__col-head earnings text-green">
                              <div class="">
                                  ${{state.earnings}}
                              </div>
                          </div>
                          <div class="content__col content__col-head recur">
                              <div class="">
                                  ${{state.recur}}
                              </div>
                          </div>
                          <div class="content__col content__col-head clawbacks text-red">
                              <div class="">
                                  ${{state.clawback}}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
        </div>




        <el-dialog
                title="YOUR REFERRALS"
                center
                :width=" `40%`"
                class="el-dialog--huge dialog_edit dialog_border_radius "
                :visible.sync="showYourReferrals"
                :modalAppendToBody="false"
        >

            <div class="relative">

                <div class="your-referral-detail" id="">

<!--                        <el-input class="filter__search" placeholder="Search Videos">-->
<!--                        <template slot="prepend">-->
<!--                            <svg xmlns="http://www.w3.org/2000/svg" width="16.6" height="16.6"-->
<!--                                 viewBox="0 0 16.6 16.6">-->
<!--                                <path id="icon_search-2"-->
<!--                                      d="M12.7,11.23a6.777,6.777,0,0,0,1.4-4.174A7.02,7.02,0,0,0,7.1,0,7.105,7.105,0,0,0,0,7.056a7.105,7.105,0,0,0,7.1,7.056,6.667,6.667,0,0,0,4.2-1.391l3,2.981a.971.971,0,0,0,1.4,0,.957.957,0,0,0,0-1.391Zm-5.6.8A5.022,5.022,0,0,1,2,7.056a5.1,5.1,0,1,1,10.2,0,5.022,5.022,0,0,1-5.1,4.969Z"-->
<!--                                      transform="translate(0.3 0.3)" fill="#00acdc" stroke="#fff"-->
<!--                                      stroke-width="0.6"/>-->
<!--                            </svg>-->
<!--                        </template>-->
<!--                    </el-input>-->
                    <SearchQuery :noDesktop='false' @query='filterSearch' onKey='Enter' tip='Press Enter to search' />
                    <div class="m-top-3">
                        <header class="component__header">
                            <div class="d-flex align-items-center">

                                <div class="content__col content__col-head">
                                    USER
                                </div>
                                <div class="content__col content__col-head ">
                                    PLAN
                                </div>
                                <div class="content__col content__col-head ">
                                    EARNED
                                </div>

                            </div>
                        </header>
                        <div class="component__body">
                            <div class="project__list">
                                <div class="d-flex align-items-center" v-for="(referral) in referrals">

                                    <div class="content__col content__col-head referral-user-logo">
                                        <div>

                                            <el-avatar :size="60" :src="referral.photo_url"></el-avatar>
                                        </div>
                                        <div class="referral-info">
                                            <div class="w-100 text-bold">{{ referral.name }}</div>
                                            <div class="w-100 small text-grey">{{ referral.email }}</div>

                                        </div>
                                    </div>
                                    <div class="content__col content__col-head ">
                                        <div class="" v-if="referral.current_plan !== null">
                                                {{referral.current_plan.name}}
                                        </div>
                                        <div class="" v-else>
                                                -
                                        </div>
                                    </div>
                                    <div class="content__col content__col-head ">
                                        <div class="">{{referral.earned}}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class='pagination d-flex justify-content-between' v-if='ref_count > 10' >
                            <div class='pagination-left'>
                                <div class='pagination-left-title'>View</div>
                                <div class='pagination-left-count'>
                                    <select @change='paginateView($event)'>
                                        <option :selected='item == paginate.view' v-for='(item, index) in paginate.count' :key='index'>{{ item }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class='pagination-right d-flex justify-content-between'>
                                <div @click='paginatePrev' class='pagination-right-prev' :class='paginate.begin == 0 ? "disabled" : ""'>
                                    <ArrowRightIcon></ArrowRightIcon>
                                </div>
                                <div class='pagination-right-count'>
                                    {{ paginate.begin + 1 }} - {{ ref_count > paginate.end ? paginate.end : ref_count }} of {{ ref_count }}
                                </div>
                                <div @click='paginateNext' class='pagination-right-next' :class='paginate.end >= ref_count ? "disabled" : ""'>
                                    <ArrowRightIcon></ArrowRightIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div slot="footer" class="dialog-footer">
            </div>

        </el-dialog>


    </div>

</template>

<script>

    import SearchQuery from './../../Project/AllProjects/Components/SearchQuery';
    import ArrowRightIcon from "./../../Admin/SvgComponents/ArrowRightIcon";

    export default {
        name: "PromoStats",
        components: {SearchQuery, ArrowRightIcon},
        props: {
            user: Object,
            promoStats: Object,
            referrals: Object,
            ref_count: 0
        },
        data: () => ({
            showYourReferrals : false,
            paginate: {
                begin: 0,
                end: 10,
                count: [10, 25, 50, 100],
                view: 10,
            },

        }),
        mounted() {
            console.log('referrals', this.referrals, '===');
            // this.getLinkss()
        },
        methods: {
            paginateNext()
            {
                console.log('lk');
                if(this.paginate.begin + (this.paginate.end - this.paginate.begin) < this.ref_count) {
                    var $this = this;
                    var lastId=0;

                    this.referrals.forEach(function (link, index) {
                        if ((index + 1) == $this.referrals.length) {
                            lastId = link.id;
                        }
                    });

                    this.paginate.end = parseInt(this.paginate.end) + parseInt(this.paginate.view);
                    this.paginate.begin = parseInt(this.paginate.begin) + parseInt(this.paginate.view);
                    this.getSearchReferral('>', lastId);
                }
            },
            paginatePrev()
            {
                if(this.paginate.end - this.paginate.begin >= 0) {
                    var $this = this;
                    var lastId=0;

                    this.referrals.forEach(function (link, index) {
                        if (index  == 0) {
                            lastId = link.id;
                        }
                    });
                    this.paginate.end = parseInt(this.paginate.end) - parseInt(this.paginate.view);
                    this.paginate.begin = parseInt(this.paginate.begin) - parseInt(this.paginate.view);
                        this.getSearchReferral('<', lastId);
                }
            },
            paginateView(event)
            {
                this.paginate.end = event.target.value;
                this.paginate.begin = 0;
                this.paginate.view = event.target.value;

                this.getSearchReferral();
            },
            getSearchReferral(direction = 'increase', begin = 0, keywords=null){
                const queryParams = `?begin=${begin}&limit=${this.paginate.view}&direction=${direction}&view=${this.paginate.view}&keywords=${keywords}`
                var $this = this;
                this.axios({
                    method: 'get',
                    url: `/referrals/serch/${queryParams}`,
                }).then(function (response) {

                    $this.referrals = response.data.referrals;
                    if(direction == '<'){
                        $this.referrals = $this.referrals.reverse();

                    }
                    $this.ref_count = response.data.ref_count;
                    // console.log('789988 66454 3111');
                })["catch"](function (error) {

                });
            },
            filterSearch(data)
            {

                this.getSearchReferral('increase', 0, data);
            },
            changeDateFormate(date){
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                var part = date.split('-');
                var parts = part[1] +'-' + part[0] + '-01';
                var mydate = new Date(parts);
                return monthNames[mydate.getMonth()] + ', ' + part[1];
            },

        }
    };
</script>

<style lang="less">
    .referral-list-component {
        font-size: 13px;
        margin: 0 0;
        .pagination {
            padding: 15px 0px;
            .pagination-left {
                width: 123px;
                height: 31px;
                display: flex;
                flex-direction: row;
                border: 1px solid #C7E9F2;
                justify-content: space-between;
                border-radius: 20px;
                .pagination-left-title {
                    color: var(--color-content);
                    padding: 5px 15px;
                    border-right: 1px solid #C7E9F2;
                }
                .pagination-left-count {
                    color: var(--color-content);
                    padding: 5px 15px 5px 0px;
                    select {
                        background: transparent !important;
                        border: transparent !important;
                        outline: none !important;
                    }
                }
            }
            .pagination-right {
                width: 157px;
                height: 31px;
                border-radius: 20px;
                border: 1px solid #C7E9F2;
                padding: 0px 15px;
                .pagination-right-count {
                    padding: 5px 10px;
                    border-left: 1px solid #C7E9F2;
                    border-right: 1px solid #C7E9F2;
                }
                .pagination-right-next,
                .pagination-right-prev {
                    padding: 5px 0px;
                }
                .pagination-right-prev svg {
                    transform: rotate(180deg);
                }
            }
            .disabled {
                opacity: 0.4;
            }
        }
        .your-referral-detail{
            .el-input-group__prepend{
                border-right: none;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
                background-color: #fff;
            }
            .el-input__inner{
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                border-left: none !important;
            }
            .el-input__inner:focus {
                outline: none;
                border-color: rgb(191, 204, 217);
            }
            .referral-user-logo{
                display: flex;
                img{
                    height: 50px;
                    border-radius: 50%;
                }
                .referral-info{
                    margin: 5px 15px;
                    .text-bold{
                        font-size: 17px;
                    }
                    .text-grey{
                        color: #788899a1;
                    }
                }
            }
        }
.see-all-referrals{
    font-size: 11px !important;
    padding: 5px 15px !important;
}
        .promo-state-tables{
            .component__body{
                padding: 15px 30px !important;
            }
            .content__col-head{
                min-width: 100px ;
            }
        }

        .project__list .align-items-center {
            margin: 15px 0;
        }

        .content__col-head.clicks, .content__col-head.signups, .content__col-head.upgraded,
        .content__col-head.month, .content__col-head.earnings, .content__col-head.recur, .content__col-head.clawbacks {
            text-align: center !important;
        }

        .component__body {

            padding: 15px 0 !important;

            .promo-detail {
                font-size: 11px !important;
                padding: 5px 15px !important;
            }


        }

        .link-info {
            display: flex;
            flex-direction: column;

            .referral-title {
                font-size: 16px;
            }
        }

        .component__header {
            background-color: rgba(11, 172, 219, .02);
            padding: 15px 30px;
            line-height: 15px;
            font-size: 13px;
            font-weight: 500;

        }

        .content__col-head {
            flex-basis: 400px;
            min-width: 150px
        }

        .header-title {
            margin: 30px 0;

            > span {
                font-size: 28px;
            }

            .add-new-referral {
                float: right;
            }

            .add-new-btn {
                font-size: 15px;
            }
        }

        .btn-sm {
            font-size: 10px;
        }

    }

</style>


<style lang="less">


</style>
