<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14.75" height="10.51" viewBox="0 0 14.75 10.51">
        <path
            id="Path_9819"
            data-name="Path 9819"
            d="M14.534,68.207a.754.754,0,0,0-1.043,0L4.655,76.78l-3.4-3.3a.754.754,0,0,0-1.043,0,.7.7,0,0,0,0,1.012l3.918,3.8a.754.754,0,0,0,1.043,0l9.358-9.078A.7.7,0,0,0,14.534,68.207Z"
            transform="translate(0 -67.997)"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: "CheckMark",
    props: {
        color: {
            type: String,
            default: "#00acdc"
        }
    }
};
</script>

<style lang="less" scoped>
</style>

