import moment from 'moment';
import SortingFilter from '../Common/SortingFilter';
import MultipleSelectPopover from '../Admin/UsersTab/MultipleSelectPopover';
import FilterIcon from '../Admin/SvgComponents/FilterIcon';
import FilterOptionItems from '../Project/AllProjects/ProjectVideoFilterOptionItems';

export default {
    props: ['videos', 'trigger', 'multiple', 'title'],
    data: function (){
        return {
            canBeClosed: false,
            video_error: '',
            selected_id: false,
            selected_ids: [],
            searchInput: '',
            videoList: this.$props.videos,
            visibility: {
                filter: false
            },
            filter: false,
            downgradeForever: false,
            hoveredVideo: null,
        }
    },
    components: {
        SortingFilter,
        MultipleSelectPopover,
        FilterIcon,
        FilterOptionItems
    },
    methods: {
        hideVideosModal()
        {
            this.$emit('video_modal', false);
        },
        format_date(value){
            return moment(String(value)).format('YYYY-MM-DD')
        },
        format_seconds(seconds)
        {
            return new Date(seconds * 1000).toISOString().substr(11, 8);
        },
        searchVideos()
        {
            let data = this.videoList,
                input = this.searchInput ? this.searchInput.toLowerCase() : '';
            if (input)
                this.videoList = data.filter(video => video.title.toLowerCase().indexOf(input) > -1);
            else
                this.videoList = this.$props.videos;
        },
        reloadVideoList(data)
        {
            this.videoList = data;
        },
        onChangeFilter(data)
        {
            this.filter = data;
            this.loadVideoList(true);
        },
        loadVideoList(filter) {
            this.axios({
                method: 'get',
                url: 'project/' + this.$route.params.id + '/videos',
                params: {filter: this.filter}
            }).then((response) => {
                
                if (filter) {
                    this.videoList = response.data;
                } else {
                }
            });
        },
        selectVideos()
        {
            if (this.multiple && !this.downgradeForever) {
                this.video_error = true;
                setTimeout(() => {
                    this.video_error = false;
                }, 1000);
                return false;
            }
            if (this.multiple) this.$emit('selected', this.selected_ids);
            else this.$emit('selected', this.selected_id);
        },
        highlightVideo(id, longerThan15 = false)
        {
            if (this.multiple) {
                if (longerThan15) return false;
                let index = this.selected_ids.indexOf(id);
                if (index > -1) {
                    this.selected_ids.splice(index, 1);
                } else {
                    if (this.selected_ids.length != 10)
                    this.selected_ids.push(id);
                }
            } else {
                this.selected_id = id;
            }
        },
        highLightClass(video)
        {
            if (!this.multiple) {
                if (video.id === this.selected_id) {
                    return 'highlighted';
                }
            } else {
                if (video.duration >= 900) {
                    return 'greyed-out';
                }
            }
            return '';
        }
    },
    watch : { 
        videos: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.videoList = newValue;
            }
        }
    },
}