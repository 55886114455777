<template>
<span></span>
    <!-- <button
            class="button"
            :class='!mobile ? "btn btn-default btn-primary-outline" : "text-only-button"'
            type="button"
            v-if="!isLoading"
            :disabled="changePaymentDisabled"
            @click="$emit('openDialog', true)"
        >Change Payment Method
    </button>
    <div
            v-else
            v-bind:class="`text-center loading-space loading-space-users ${
            isLoading ? 'overlay' : ''
        }`"
    >
        <i class="el-icon-loading"></i>
    </div> -->
</template>

<script>
export default {
    props: ['isLoading', 'changePaymentDisabled', 'mobile']
}
</script>

<style scoped>
.text-only-button {
    border: none;
    background: transparent;
    width: max-content;
    padding: 0px;
    color: #019EDB;
    margin-top: 15px;
}
</style>