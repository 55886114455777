<template>
    <el-row :gutter="40" class="m-top-2">
        <el-col :span="12" :xs="24">
            <div class="form-group m-bottom-1">
                <label class="control-label">Africa</label>
                <ejs-multiselect id='multiselect'
                                 :dataSource='Africa'
                                 placeholder="No country restricted"
                                 mode="CheckBox"
                                 :fields='fields'
                                 :showSelectAll='showSelectAll'
                                 selectAllText="Select All"
                                 v-model="user.settings.restricted_countries['Africa']"
                                 unSelectAllText="unSelect All"
                                    @change="saveGeoPrivacy('Africa')"
                ></ejs-multiselect>

            </div>
        </el-col>

        <el-col :span="12" :xs="24">
            <div class="form-group m-bottom-1">
                <label class="control-label">Asia</label>

                <ejs-multiselect id='multiselect'
                                 :dataSource='Asian'
                                 placeholder="No country restricted"
                                 mode="CheckBox"
                                 :fields='fields'
                                 :showSelectAll='showSelectAll'
                                 selectAllText="Select All"
                                 v-model="user.settings.restricted_countries['Asian']"
                                 unSelectAllText="unSelect All" @change="saveGeoPrivacy('Asian')"></ejs-multiselect>

            </div>

        </el-col>
        <el-col :span="12" :xs="24">
            <div class="form-group m-bottom-1">
                <label class="control-label">Australia/Oceania</label>

                <ejs-multiselect id='multiselect'
                                 :dataSource='Australian'
                                 placeholder="No country restricted"
                                 mode="CheckBox"
                                 :fields='fields'
                                 :showSelectAll='showSelectAll'
                                 selectAllText="Select All"
                                 unSelectAllText="unSelect All" @change="saveGeoPrivacy('Australian')"  v-model="user.settings.restricted_countries['Australian']"></ejs-multiselect>

            </div>

        </el-col>
        <el-col :span="12" :xs="24">
            <div class="form-group m-bottom-1">
                <label class="control-label">Europe</label>

                <ejs-multiselect id='multiselect'
                                 :dataSource='Europian'
                                 placeholder="No country restricted"
                                 mode="CheckBox"
                                 :fields='fields'
                                 :showSelectAll='showSelectAll'
                                 selectAllText="Select All"
                                 unSelectAllText="unSelect All" @change="saveGeoPrivacy('Europian')"  v-model="user.settings.restricted_countries['Europian']"></ejs-multiselect>

            </div>

        </el-col>

        <el-col :span="12" :xs="24">
            <div class="form-group m-bottom-1">
                <label class="control-label">North America</label>

                <ejs-multiselect id='multiselect'
                                 :dataSource='NorthAmerica'
                                 placeholder="No country restricted"
                                 mode="CheckBox"
                                 :fields='fields'
                                 :showSelectAll='showSelectAll'
                                 selectAllText="Select All"
                                 unSelectAllText="unSelect All" @change="saveGeoPrivacy('NorthAmerica')"  v-model="user.settings.restricted_countries['NorthAmerica']"></ejs-multiselect>

            </div>

        </el-col>

        <el-col :span="12" :xs="24">
            <div class="form-group m-bottom-1">
                <label class="control-label">South America</label>

                <ejs-multiselect id='multiselect'
                                 :dataSource='SouthAmerica'
                                 placeholder="No country restricted"
                                 mode="CheckBox"
                                 :fields='fields'
                                 :showSelectAll='showSelectAll'
                                 selectAllText="Select All"
                                 unSelectAllText="unSelect All" @change="saveGeoPrivacy('SouthAmerica')"  v-model="user.settings.restricted_countries['SouthAmerica']"></ejs-multiselect>

            </div>

        </el-col>
    </el-row>

</template>

<script>

   import Vue from 'vue';

    import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
    import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';

    MultiSelect.Inject(CheckBoxSelection);
    Vue.use(MultiSelectPlugin);

    export default {
        name: "GeolocationSetting",
        components: {MultiSelectPlugin, MultiSelect, CheckBoxSelection},
        props: {
            user: Object,

        },
        data: () => ({

            Africa: [
                { Id: 'NG', Name: 'Nigeria' },
                { Id: 'ET', Name: 'Ethiopia' },
                { Id: 'EG', Name: 'Egypt' },
                { Id: 'TZ', Name: 'Tanzania' },
                { Id: 'ZA', Name: 'South Africa' },
                { Id: 'KE', Name: 'Kenya' },
                { Id: 'UG', Name: 'Uganda' },
                { Id: 'DZ', Name: 'Algeria' },
                { Id: 'SD', Name: 'Sudan' },
                { Id: 'MA', Name: 'Morocco' },
                { Id: 'AO', Name: 'Angola' },
                { Id: 'MZ', Name: 'Mozambique' },
                { Id: 'GH', Name: 'Ghana' },
                { Id: 'MG', Name: 'Madagascar' },
                { Id: 'CM', Name: 'Cameroon' },
                { Id: 'CR', Name: 'Côte d\'Ivoire' },
                { Id: 'NE', Name: 'Niger' },
                { Id: 'BF', Name: 'Burkina Faso' },
                { Id: 'ML', Name: 'Mali' },
                { Id: 'MW', Name: 'Malawi' },
                { Id: 'ZM', Name: 'Zambia' },
                { Id: 'SN', Name: 'Senegal' },
                { Id: 'TD', Name: 'Chad' },
                { Id: 'SO', Name: 'Somalia' },
                { Id: 'ZW', Name: 'Zimbabwe' },
                { Id: 'GN', Name: 'Guinea' },
                { Id: 'RW', Name: 'Rwanda' },
                { Id: 'BJ', Name: 'Benin' },
                { Id: 'BI', Name: 'Burundi' },
                { Id: 'TN', Name: 'Tunisia' },
                { Id: 'TG', Name: 'Togo' },
                { Id: 'SL', Name: 'Sierra Leone' },
                { Id: 'CG', Name: 'Congo' },
                { Id: 'LR', Name: 'Liberia' },
                { Id: 'CF', Name: 'Central African Republic' },
                { Id: 'MR', Name: 'Mauritania' },
                { Id: 'ER', Name: 'Eritrea' },
                { Id: 'NA', Name: 'Namibia' },
                { Id: 'GM', Name: 'Gambia' },
                { Id: 'BW', Name: 'Botswana' },
                { Id: 'GA', Name: 'Gabon' },
                { Id: 'LS', Name: 'Lesotho' },
                { Id: 'GW', Name: 'Guinea-Bissau' },
                { Id: 'GQ', Name: 'Equatorial Guinea' },
                { Id: 'MU', Name: 'Mauritius' },
                { Id: 'DJ', Name: 'Djibouti' },
                { Id: 'KM', Name: 'Comoros' },
                { Id: 'SC', Name: 'Seychelles' },
                { Id: 'CV', Name: 'Cape Verde' },
                { Id: 'CD', Name: 'Congo, the Democratic Republic of the' },
                { Id: 'LY', Name: 'Libyan Arab Jamahiriya' },
                { Id: 'RE', Name: 'Réunion' },
                { Id: 'ST', Name: 'Sao Tome and Principe' },
                { Id: 'SZ', Name: 'Swaziland' },
                { Id: 'EH', Name: 'Western Sahara' },
            ],
            Asian: [
                { Id: 'CN', Name: 'China' },
                { Id: 'IN', Name: 'India' },
                { Id: 'ID', Name: 'Indonesia' },
                { Id: 'PK', Name: 'Pakistan' },
                { Id: 'BD', Name: 'Bangladesh' },
                { Id: 'JP', Name: 'Japan' },
                { Id: 'PH', Name: 'Philippines' },
                { Id: 'VN', Name: 'Vietnam' },
                { Id: 'TR', Name: 'Turkey' },
                { Id: 'IR', Name: 'Iran' },
                { Id: 'TH', Name: 'Thailand' },
                { Id: 'MM', Name: 'Myanmar' },
                { Id: 'IQ', Name: 'Iraq' },
                { Id: 'AF', Name: 'Afghanistan' },
                { Id: 'SA', Name: 'Saudi Arabia' },
                { Id: 'UZ', Name: 'Uzbekistan' },
                { Id: 'MY', Name: 'Malaysia' },
                { Id: 'YE', Name: 'Yemen' },
                { Id: 'NP', Name: 'Nepal' },
                { Id: 'LK', Name: 'Sri Lanka' },
                { Id: 'KZ', Name: 'Kazakhstan' },
                { Id: 'KH', Name: 'Cambodia' },
                { Id: 'JO', Name: 'Jordan' },
                { Id: 'AZ', Name: 'Azerbaijan' },
                { Id: 'AE', Name: 'United Arab Emirates' },
                { Id: 'TJ', Name: 'Tajikistan' },
                { Id: 'IL', Name: 'Israel' },
                { Id: 'LB', Name: 'Lebanon' },
                { Id: 'KG', Name: 'Kyrgyzstan' },
                { Id: 'TM', Name: 'Turkmenistan' },
                { Id: 'SG', Name: 'Singapore' },
                { Id: 'OM', Name: 'Oman' },
                { Id: 'KW', Name: 'Kuwait' },
                { Id: 'GE', Name: 'Georgia' },
                { Id: 'MN', Name: 'Mongolia' },
                { Id: 'AM', Name: 'Armenia' },
                { Id: 'QA', Name: 'Qatar' },
                { Id: 'BH', Name: 'Bahrain' },
                { Id: 'TL', Name: 'Timor-Leste' },
                { Id: 'CY', Name: 'Cyprus' },
                { Id: 'BT', Name: 'Bhutan' },
                { Id: 'MV', Name: 'Maldives' },
                { Id: 'IO', Name: 'British Indian Ocean Territory' },
                { Id: 'BN', Name: 'Brunei Darussalam' },
                { Id: 'CX', Name: 'Christmas Island' },
                { Id: 'CC', Name: 'Cocos (Keeling) Islands' },
                { Id: 'HK', Name: 'Hong Kong' },
                { Id: 'IM', Name: 'Isle of Man' },
                { Id: 'KP', Name: 'Korea, Democratic People\'s Republic of' },
                { Id: 'KR', Name: 'Korea, Republic of' },
                { Id: 'LA', Name: 'Lao People\'s Democratic Republic' },
                { Id: 'MO', Name: 'Macao' },
                { Id: 'SY', Name: 'Syrian Arab Republic' },
                { Id: 'TW', Name: 'Taiwan, Province of China' },

            ],
            Australian: [
                { Id: 'AU', Name: 'Australia' },
                { Id: 'AS', Name: 'American Samoa' },
                { Id: 'FJ', Name: 'Fiji' },
                { Id: 'KI', Name: 'Kiribati' },
                { Id: 'MH', Name: 'Marshall Islands' },
                { Id: 'FM', Name: 'Micronesia' },
                { Id: 'NR', Name: 'Nauru' },
                { Id: 'NZ', Name: 'New Zealand' },
                { Id: 'PW', Name: 'Palau' },
                { Id: 'PG', Name: 'Papua New Guinea' },
                { Id: 'WS', Name: 'Samoa' },
                { Id: 'SB', Name: 'Solomon Islands' },
                { Id: 'TO', Name: 'Tonga' },
                { Id: 'TV', Name: 'Tuvalu' },
                { Id: 'VU', Name: 'Vanuatu' },
                { Id: 'CK', Name: 'Cook Islands' },
                { Id: 'PF', Name: 'French Polynesia' },
                { Id: 'HM', Name: 'Heard Island and McDonald Islands' },
                { Id: 'NC', Name: 'New Caledonia' },
                { Id: 'NU', Name: 'Niue' },
                { Id: 'NF', Name: 'Norfolk Island' },
                { Id: 'MP', Name: 'Northern Mariana Islands' },
                { Id: 'PN', Name: 'Pitcairn' },
                { Id: 'WF', Name: 'Wallis and Futuna' },
            ],

            Europian: [

                { Id: 'DE', Name: 'Germany' },
                { Id: 'AX', Name: 'Åland Islands' },
                { Id: 'GB', Name: 'United Kingdom' },
                { Id: 'FR', Name: 'France' },
                { Id: 'IT', Name: 'Italy' },
                { Id: 'ES', Name: 'Spain' },
                { Id: 'UA', Name: 'Ukraine' },
                { Id: 'PL', Name: 'Poland' },
                { Id: 'RO', Name: 'Romania' },
                { Id: 'NL', Name: 'Netherlands' },
                { Id: 'BE', Name: 'Belgium' },
                { Id: 'CZ', Name: 'Czech Republic (Czechia)' },
                { Id: 'GR', Name: 'Greece' },
                { Id: 'PT', Name: 'Portugal' },
                { Id: 'SE', Name: 'Sweden' },
                { Id: 'HU', Name: 'Hungary' },
                { Id: 'BY', Name: 'Belarus' },
                { Id: 'AT', Name: 'Austria' },
                { Id: 'RS', Name: 'Serbia' },
                { Id: 'CH', Name: 'Switzerland' },
                { Id: 'BG', Name: 'Bulgaria' },
                { Id: 'DK', Name: 'Denmark' },
                { Id: 'FI', Name: 'Finland' },
                { Id: 'SK', Name: 'Slovakia' },
                { Id: 'NO', Name: 'Norway' },
                { Id: 'IE', Name: 'Ireland' },
                { Id: 'HR', Name: 'Croatia' },
                { Id: 'MD', Name: 'Moldova' },
                { Id: 'BA', Name: 'Bosnia and Herzegovina' },
                { Id: 'AL', Name: 'Albania' },
                { Id: 'LT', Name: 'Lithuania' },
                { Id: 'SI', Name: 'Slovenia' },
                { Id: 'LV', Name: 'Latvia' },
                { Id: 'EE', Name: 'Estonia' },
                { Id: 'ME', Name: 'Montenegro' },
                { Id: 'LU', Name: 'Luxembourg' },
                { Id: 'MT', Name: 'Malta' },
                { Id: 'IS', Name: 'Iceland' },
                { Id: 'AD', Name: 'Andorra' },
                { Id: 'MC', Name: 'Monaco' },
                { Id: 'LI', Name: 'Liechtenstein' },
                { Id: 'SM', Name: 'San Marino' },
                { Id: 'VA', Name: 'Holy See' },
                { Id: 'FO', Name: 'Faroe Islands' },
                { Id: 'GI', Name: 'Gibraltar' },
                { Id: 'JE', Name: 'Jersey' },
                { Id: 'SJ', Name: 'Svalbard and Jan Mayen' },

            ],
            NorthAmerica: [
                { Id: 'AI', Name: 'Anguilla' },
                { Id: 'AG', Name: 'Antigua and Barbuda' },
                { Id: 'AW', Name: 'Aruba' },
                { Id: 'BS', Name: 'Bahamas' },
                { Id: 'BB', Name: 'Barbados' },
                { Id: 'BZ', Name: 'Belize' },
                { Id: 'BM', Name: 'Bermuda' },
                { Id: 'VG', Name: 'British Virgin Islands' },
                { Id: 'CA', Name: 'Canada' },
                { Id: 'KY', Name: 'Cayman Islands' },
                { Id: 'CR', Name: 'Costa Rica' },
                { Id: 'CU', Name: 'Cuba' },
                { Id: 'DM', Name: 'Dominica' },
                { Id: 'DO', Name: 'Dominican Republic' },
                { Id: 'SV', Name: 'El Salvador' },
                { Id: 'GL', Name: 'Greenland' },
                { Id: 'GP', Name: 'Guadeloupe' },
                { Id: 'GT', Name: 'Guatemala' },
                { Id: 'HT', Name: 'Haiti' },
                { Id: 'HN', Name: 'Honduras' },
                { Id: 'JM', Name: 'Jamaica' },
                { Id: 'MQ', Name: 'Martinique' },
                { Id: 'MX', Name: 'Mexico' },
                { Id: 'MS', Name: 'Montserrat' },
                { Id: 'NI', Name: 'Nicaragua' },
                { Id: 'PA', Name: 'Panama' },
                { Id: 'PR', Name: 'Puerto Rico' },
                { Id: 'BL', Name: 'Saint Barthélemy' },
                { Id: 'KN', Name: 'Saint Kitts and Nevis' },
                { Id: 'LC', Name: 'Saint Lucia' },
                { Id: 'MF', Name: 'Saint Martin' },
                { Id: 'PM', Name: 'Saint Pierre and Miquelon' },
                { Id: 'VC', Name: 'Saint Vincent and the Grenadines' },
                { Id: 'TT', Name: 'Trinidad and Tobago' },
                { Id: 'TC', Name: 'Turks and Caicos Islands' },
                { Id: 'US', Name: 'United States' },
                { Id: 'GD', Name: 'Grenada' },
                { Id: 'UM', Name: 'United States Minor Outlying Islands' },
                { Id: 'VI', Name: 'Virgin Islands, U.S' },

            ],
            SouthAmerica: [
                { Id: 'AR', Name: 'Argentina' },
                { Id: 'BO', Name: 'Bolivia, Plurinational State of' },
                { Id: 'BV', Name: 'Bouvet Island' },
                { Id: 'BR', Name: 'Brazil' },
                { Id: 'CL', Name: 'Chile' },
                { Id: 'CO', Name: 'Colombia' },
                { Id: 'EC', Name: 'Ecuador' },
                { Id: 'FK', Name: 'Falkland Islands' },
                { Id: 'GF', Name: 'French Guiana' },
                { Id: 'GY', Name: 'Guyana' },
                { Id: 'PY', Name: 'Paraguay' },
                { Id: 'PE', Name: 'Peru' },
                { Id: 'GS', Name: 'South Georgia and the South Sandwich Islands' },
                { Id: 'SR', Name: 'Suriname' },
                { Id: 'UY', Name: 'Uruguay' },
                { Id: 'VE', Name: 'Venezuela' },

            ],


            fields : { text: 'Name', value: 'Id' },
            showSelectAll : true
        }),

        methods: {
            saveGeoPrivacy(type) {
               this.user.settings.restricted_countries[type] = this.user.settings.restricted_countries[type];


                console.log(this.user.settings.restricted_countries['type']);
                let post_data = {
                    data: {
                        restricted_countries: this.user.settings.restricted_countries[type],
                        country_type: type,
                    },
                    url: "/settings/set-geo-privacy"
                };

                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        if (response.data.result === "success") {
                            window.vEvent.fire("settings-common-success", {
                                title: 'Successfully updated',
                                message: 'Successfully updated',
                                close: false
                            });
                        }
                    })
                    .catch(error => {
                    });
            },
        }
    };
</script>


<style lang="less">

    .e-multi-select-wrapper{
        border-radius: 4px;
        border: 2px solid #e2e5ed;
        color: var(--color-content);
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 1;
        outline: none;
        padding: 3px 10px;
    }

    .e-multi-select-wrapper .e-delim-values{
        font-weight: 500 !important;
        color: var(--color-content) !important;
        font-size: 13px;
        line-height: 40px;
        padding-left: 10px;
    }
    .e-multi-select-wrapper input[type='text'] {
        height: 40px;
        padding-left: 10px;
    }
    .e-chips-close.e-close-hooker{
        display: block !important;
    }
    .e-popup.e-multi-select-list-wrapper {

        overflow: initial;
        border: 1px solid #0bacdb;
        --select-dropdown-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
    }
    .e-popup.e-multi-select-list-wrapper .e-filter-parent span.e-control-wrapper{
        border: 2px solid #32aad945 !important;
        margin: 20px 10px;
        border-radius: 23px;
        width: 95%;
    }
    .e-popup.e-multi-select-list-wrapper .e-filter-parent {
        text-align: center;
        box-shadow: none;
    }
    .e-checkbox-wrapper:hover .e-frame.e-check, .e-css.e-checkbox-wrapper:hover .e-frame.e-check{

        background-color: #22465e;
    }
    .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
        background-color: #22465e;
    }
    .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
    .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
    .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
    .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after {
        width: 0;
    }
    .e-multi-select-list-wrapper .e-selectall-parent {
        border-bottom: none;
    }
    .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
        border-color: transparent;

    }

    .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left), .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left){
        border-color: transparent;
    }

    .e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left){
        border-color: transparent;
    }

    .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error){
        border-color: transparent;
    }

    .e-multi-select-wrapper .e-chips-close.e-close-hooker {
        margin-top: -2.9em;
    }

    .e-multi-select-wrapper .e-chips-close.e-close-hooker {
        color: #aeb5bf;
    }

    @import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

</style>
