<template>
    <div class='issued-mobile'>
        <table>
            <thead>
                <tr>
                    <th>Issued To</th>
                    <th>Issued By</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span v-if='issuedTo.name'>{{ issuedTo.name }}</span></td>
                    <td><span>BigCommand LLC</span></td>
                </tr>
                <tr>
                    <td><span v-if="issuedTo.company">{{ issuedTo.company }}</span></td>
                    <td><span><span>108 West 13th Street,</span></span></td>
                </tr>
                <tr>
                    <td><span v-if="issuedTo.phone">{{ issuedTo.phone }}</span></td>
                    <td><span>Wilmington, DE</span></td>
                </tr>
                <tr>
                    <td><span v-if="issuedTo.email">{{ issuedTo.email }}</span></td>
                    <td><span>19801</span></td>
                </tr>
                <tr>
                    <td><span v-if='issuedTo.billing_address'>{{ issuedTo.billing_address }}</span></td>
                </tr>
                <tr>
                    <td><span v-if='issuedTo.billing_address_line_2'>{{ issuedTo.billing_address_line_2 }}</span></td>
                </tr>
                <tr>
                    <td><span v-if="issuedTo.billing_city">{{issuedTo.billing_city}}</span></td>
                </tr>
                <tr>
                    <td><span v-if="issuedTo.billing_zip">, {{issuedTo.billing_zip}}</span></td>
                </tr>
                <tr>
                    <td><span v-if="issuedTo.billing_country">{{issuedTo.billing_country}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang='less'>
    .issued-mobile {
        table {
            width: 100%;
        }
    }
</style>

<script>
import $ from 'jquery';
export default {
    props: ['issuedTo', 'issuedBy'],
    mounted() {
        this.adjustEmptyTr()
    },
    methods: {
        adjustEmptyTr()
        {
            var rows = $(".issued-mobile table tr");
            var row, n;
            for (row = 0; row < rows.length - 1; ++row) {
              $(rows[row]).children().each(function(col) {
                var thisCell, cellBelow, text, foundText;

                foundText = true;
                thisCell = $(this);
                while (!thisCell.text() && foundText) {
                  // This cell has no text, shuffle the column up
                  foundText = false;
                  for (n = row; n < rows.length - 1; ++n) {
                    cellBelow = $($(rows[n + 1]).children()[col]);
                    text = cellBelow.text();
                    if (text) {
                      foundText = true;
                    }
                    $($(rows[n]).children()[col]).text(text);
                    cellBelow.text('');
                  }
                }
              });
            }
        }
    }
}
</script>