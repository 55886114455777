<template>
    <el-container>
        <el-card v-if="estimates && estimates.main">
            <el-row class="title">
                <span>Here's you monthly estimate.</span>
                <br>
                <small>Note: This estimate does not include taxes.</small>
            </el-row>
            <div class="div-hr title-hr-margin"></div>

            <row-billing-estimate
                :rowText="estimates.main.name"
                :amount="`$ ${estimates.main.amount}`"
                :spanLeftCol="19"
                :offset="2"
            />
            <div class="div-hr hr-margin" v-if='estimates && estimates.metered_plans && estimates.metered_plans.length'></div>

            <row-billing-estimate
                :rowText="plan.name"
                :amount="`$ ${plan.amount}`"
                :spanLeftCol="19"
                :offset="2"
                v-for='(plan, index) in estimates.metered_plans'
                :key='index'
            />
            <div class="div-hr half-top-hr-margin"></div>

            <el-col :span="12" :offset="12">
                <row-billing-estimate
                    :rowText="`Total`"
                    :amount="`$${estimates.total}`"
                    :spanLeftCol="16"
                    :offset="2"
                    :halfCardClass="true"
                />
                <div class="div-hr hr-margin"></div>

                <row-billing-estimate
                    :rowText="`Estimated total due on ${estimates.due_date}`"
                    :amount="`$${estimates.total}`"
                    :spanLeftCol="16"
                    :offset="2"
                    :halfCardClass="true"
                />
                <div class="div-hr bottom-hr-margin"></div>
            </el-col>

            <el-row class='issued-details-desktop'>
                <issued-to-billing :issuedTo="estimates.issued_to" />
                <issued-by-billing :issuedBy="estimates.issued_by" />
            </el-row>
        </el-card>
        <div class="issued-details" v-if='estimates && estimates.issued_to'>
            <IssuedMobile
                :issuedTo='estimates.issued_to'
                :issuedBy="estimates.issued_by"
            >
            </IssuedMobile>
        </div>
    </el-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import RowBillingEstimate from "./Shared/RowBillingEstimate";
import IssuedToBilling from "./Shared/IssuedToBilling";
import IssuedByBilling from "./Shared/IssuedByBilling";
import IssuedMobile from "./Shared/IssuedMobile";

export default {
    name: "BillingEstimate",
    components: {
        RowBillingEstimate,
        IssuedToBilling,
        IssuedByBilling,
        IssuedMobile
    },
    data: function() {
        return {
            dueToDate: null,
            estimates: null,
        };
    },
    beforeDestroy() {},
    mounted() {
        this.getBillingEstimate();
    },
    created() {
        this.dueToDate = moment().format("ll");
    },
    methods: {
        async getBillingEstimate() {
            await this.axios.get('payments/subscription/bill/estimate')
                .then(response => {
                    this.estimates = response.data;
                }).catch(error => console.log(error));
        }
    }
};
</script>

<style lang="less" scoped>
.title {
    font-size: 20px;
    font-weight: 400;
}
.div-hr {
    height: 1px;
    border-bottom: 1px solid #e8e8ea;
}

.title-hr-margin {
    margin-top: 25px;
    margin-bottom: 10px;
}

.hr-margin {
    margin: 10px 0;
}

.half-top-hr-margin {
    margin-top: 10px;
    margin-bottom: 20px;
}

.bottom-hr-margin {
    margin-top: 10px;
    margin-bottom: 35px;
}

.link-w9 {
    margin-left: 84%;
}

.el-card {
    margin-bottom: 15px;
    box-shadow: none !important;
}

@media (max-width: 960px) {
    .issued-details-desktop {
        display: none;
    }
    .issued-details {
        display: flex !important;
        flex-direction: column;
    }
}
.issued-details {
    display: none;
}
</style>

