<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
        <g id="Group_12688" data-name="Group 12688" transform="translate(0.174)">
            <circle
                id="Ellipse_4307"
                data-name="Ellipse 4307"
                cx="10.5"
                cy="10.5"
                r="10.5"
                transform="translate(-0.174)"
                fill="#21455e"
            />
            <g id="Good_checkmark" data-name="Good checkmark" transform="translate(6.081 7.358)">
                <g id="Group_4357" data-name="Group 4357">
                    <path
                        id="Path_4291"
                        data-name="Path 4291"
                        d="M9.12,68.135a.459.459,0,0,0-.655,0L2.921,73.749.79,71.591a.459.459,0,0,0-.655,0,.473.473,0,0,0,0,.663l2.459,2.489a.459.459,0,0,0,.655,0L9.12,68.8A.473.473,0,0,0,9.12,68.135Z"
                        transform="translate(0 -67.997)"
                        fill="#fff"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "GoodBlackSmallCheckMark"
};
</script>

<style lang="less" scoped>
</style>

