<template>
    <el-col :span="8">
        <el-row class="title">
            <span>Issued By</span>
        </el-row>

        <!--<el-row v-if="issuedBy.vendor" class="name">-->
            <!--<span>{{issuedBy.vendor}}</span>-->
        <!--</el-row>-->

        <!--<el-row v-if="issuedBy.street" class="text">-->
            <!--<span>{{issuedBy.street}}</span>-->
        <!--</el-row>-->

        <!--<el-row v-if="issuedBy.location" class="text">-->
            <!--<span>{{issuedBy.location}}</span>-->
        <!--</el-row>-->

        <!--<el-row v-if="issuedBy.phone" class="text">-->
            <!--<span>{{issuedBy.phone}}</span>-->
        <!--</el-row>-->
        <el-row class="name">
            <span>BigCommand LLC</span>
        </el-row>

        <el-row class="text">
            <span>108 West 13th Street,</span>
        </el-row>

        <el-row class="text">
            <span>Wilmington, DE</span>
        </el-row>

        <el-row class="text">
            <span>19801</span>
        </el-row>
    </el-col>
</template>

<script>
export default {
    name: "IssuedByBilling",
    components: {},
    props: {
        issuedBy: Object
    },
    computed: {},
    data: () => ({}),
    beforeDestroy() {},
    mounted() {},
    created() {},
    methods: {}
};
</script>

<style lang="less" scoped>
.title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;
}

.name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.text {
    font-size: 16px;
}
</style>

