<template>
  <div class="dialog-body">
    <div class="contact-info-title">Billing Informatison</div>
    <div class="contact-info">
      <el-col :span="19">
        <issued-to-billing
          :issuedTo="billingInfo"
          :issued-to-view="false"
          :span="24"
          :two-columns="true"
        />
      </el-col>
      <el-col :span="5" class>
        <el-link type="primary" class="edit-link" @click="changeModalToEditBillingInfo">
          <span class="for-desktop">Edit Address</span>
          <span class="for-mobile">
            <i class="fa fa-edit"></i>
          </span>
        </el-link>
      </el-col>
    </div>

    <div class="billing-selection">
      <div
        @click="checkBillingMethodCard"
        :class="{'credit-card-box': true, 'credit-card-box-checked': checkCardBoxCard}"
      >
        <el-col :span="4" :offset="3">
          <card-icon />
        </el-col>
        <el-col :span="14" :offset="2">
          <div class="box-title">
            <span>Credit Card</span>
            <good-checkmark-light-blue
              :class="{'check-mark': true, 'check-mark-unchecked': !checkCardBoxCard}"
            />
          </div>
          <div>
            <span>
              Visa, Mastercard,
              Amex, Discovery
            </span>
          </div>
        </el-col>
      </div>
      <!--<div-->
      <!--@click="checkBillingMethodPaypal"-->
      <!--:class="{'credit-card-box': true, 'credit-card-box-checked': checkCardBoxPaypal}"-->
      <!--&gt;-->
      <!--<el-col :span="4" :offset="3">-->
      <!--<big-paypal-icon />-->
      <!--</el-col>-->
      <!--<el-col :span="14" :offset="2">-->
      <!--<div class="box-title">-->
      <!--<span>PayPal</span>-->
      <!--<good-checkmark-light-blue-->
      <!--:class="{'check-mark': true, 'check-mark-unchecked': !checkCardBoxPaypal}"-->
      <!--/>-->
      <!--</div>-->
      <!--<div>-->
      <!--<span>-->
      <!--Visa, Mastercard,-->
      <!--Amex, Discovery-->
      <!--</span>-->
      <!--</div>-->
      <!--</el-col>-->
      <!--</div>-->
    </div>

    <div
      class="card-change"
      v-if="userInfo && !checkCardBoxPaypal && userInfo.payment_method === 'paypal'"
    >
      <div class="card-number">
        <input
          class="card-input"
          type="text"
          placeholder="Card number"
          @change="changeCardData('card_number')($event)"
        />
      </div>
      <div class="card-data">
        <input
          class="card-input exp-month"
          type="text"
          placeholder="Exp. month"
          @change="changeCardData('exp_month')($event)"
        />
        <input
          class="card-input exp-year"
          type="text"
          placeholder="Exp. year"
          @change="changeCardData('exp_year')($event)"
        />
        <input
          class="card-input"
          type="text"
          placeholder="CVC"
          @change="changeCardData('cvc')($event)"
        />
      </div>
    </div>
 
    <div
      class="billing-info"
      v-if="userInfo && !checkCardBoxPaypal && (userInfo.payment_method === 'stripe' || userInfo.payment_method === 'Stripe' )"
      v-for="card in cards"
      :key="card.id">
   
      <el-col :span="9" class="billing-choice">
        <div v-if="checkCardBoxPaypal" class="billing-type">
          <paypal-icon class="billing-icon" />
          <div>Pay with PayPal</div>
        </div> 
        <div v-if="checkCardBoxCard" class="billing-type"> 
          <visa-icon v-if="card.card_brand=='Visa'" class="billing-icon" />
          <span class="card-icon" v-if="card.card_brand!='Visa'">
            <img
              v-if="card.card_brand=='Jcb'"
              alt="JCB"
              class="img-responsive card-mark jcb"
              src="../../../../../assets/img/auth/card/jcb.svg"
            />
            <img
              alt="Master"
              v-if="card.card_brand=='Master' || card.card_brand=='MasterCard'"
              class="img-responsive card-mark"
              src="../../../../../assets/img/auth/card/mastercard.svg"
            />
            <img
              v-if="card.card_brand=='AMEX'"
              alt="Master"
              class="img-responsive card-mark"
              src="../../../../../assets/img/auth/card/amex.svg"
            />
            <img
              v-if="card.card_brand=='Discover'"
              alt="discover"
              class="img-responsive card-mark"
              src="../../../../../assets/img/auth/card/discover.svg"
            />
            <img
              v-if="card.card_brand=='Diner'"
              alt="Diner"
              class="img-responsive card-mark"
              src="../../../../../assets/img/auth/card/diners.svg"
            />
          </span>
          <div>&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {{card.card_last_four}}</div>
        </div>
        <div
          v-if="checkCardBoxCard"
          class="billing-email"
        >Expires {{`${exp_month || card.exp_month}/${exp_year || card.exp_year}`}}</div>
        <div v-if="checkCardBoxPaypal" class="billing-email">{{card.paypal_email}}</div>
      </el-col>
      <el-col :span="7" class="currency">
        <div class="select-label">Billing Currency</div>
        <el-select v-model="value" placeholder="Select" class="currency-select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-link type="primary mr-2" :class="{'dull':doingAction}" v-if="card.default_card=='no'">
          <span @click="makeCardPrimary(card.id)">Make primary</span>
        </el-link> 
        <el-link type="success mr-2" class="active" v-if="card.default_card=='yes'">Primary Card</el-link>

        <el-link type="warning" :class="{'dull':doingAction}">
          <span @click="removeCard(card.id)">Remove card</span>
        </el-link>
      </el-col>
    </div>
    <el-col :span="9" :offset="8" class="pb-20">
      <el-button
        type="primary"
        v-if="cards.length<5"
        class="toolbar__btn toolbar__create btn mt-2 pay_button"
        @click="changeCard"
      >Add a new payment method</el-button>
      <el-button
        type="primary"
        class="toolbar__btn toolbar__create btn mt-2 pay_button dull"
        v-if="cards.length==5"
      >Add a new payment method</el-button>
    </el-col>
    <div class="billing-info" v-if="userInfo && checkCardBoxPaypal">
      <div
        v-if="isLoading"
        v-bind:class="`text-center loading-space loading-space-users ${
                        isLoading ? 'overlay' : ''
                    }`"
      >
        <i class="el-icon-loading"></i>
      </div>
      <div id="paypal-container" v-if="checkCardBoxPaypal" />
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import axios from "axios";
import IssuedToBilling from "../Shared/IssuedToBilling";
import PaypalIcon from "../../../Icons/PaypalIcon";
import VisaIcon from "../../../Icons/VisaIcon";
import CardIcon from "../../../Icons/CardIcon";
import BigPaypalIcon from "../../../Icons/BigPaypalIcon";
import GoodCheckmarkLightBlue from "../../../Icons/GoodCheckmarkLightBlue";
import createStripeToken from "../../../../../utils/createStripeToken";
import { GET_BRAINTREE_CLIENT_TOKEN_API } from "../../../../../../config/endpoints";

export default {
  name: "CommonBodyBillingInformation",
  components: {
    IssuedToBilling,
    PaypalIcon,
    VisaIcon,
    CardIcon,
    BigPaypalIcon,
    GoodCheckmarkLightBlue
  },
  props: {
    userInfo: Object,
    billingInfo: Object,
    cardData: Object,
    cards: Array
  },
  computed: {
    errorsForm: {
      get() {
        return this.$store.state.errorsForm;
      }
    }
  },
  data: function() {
    return {
      options: [
        {
          value: "USD ($)",
          label: "USD ($)"
        }
      ],
      value: "USD ($)",
      checkCardBoxCard: false,
      checkCardBoxPaypal: false,
      cardNumberLastFour: null,
      isLoading: false,
      doingAction: false,
      exp_month: "",
      exp_year: ""
    };
  },
  watch: {
    checkCardBoxPaypal: function(value) {
      if (
        value &&
        this.checkCardBoxPaypal &&
        this.userInfo.payment_method !== "paypal"
      ) {
        this.initBraintree();
      }
    }
  },
  beforeDestroy() {},
  mounted() {
    if (this.userInfo.payment_method === "stripe" || this.userInfo.payment_method === "Stripe") {
      this.checkCardBoxCard = "stripe";
    } else if (this.userInfo.payment_method === "paypal") {
      this.checkCardBoxPaypal = "paypal";
    }
    if (!isEmpty(this.cardData)) {
      this.cardNumberLastFour = this.cardData.card_number.slice(
        this.cardData.card_number.length - 4,
        this.cardData.card_number.length
      );
      this.exp_month = this.cardData.exp_month;
      this.exp_year = this.cardData.exp_year;
    }
  },
  created() {},
  methods: {
    checkBillingMethodCard() {
      this.checkCardBoxCard = "stripe";
      this.checkCardBoxPaypal = false;
      this.$emit("checked-box", this.checkCardBoxCard, this.checkCardBoxPaypal);
    },

    // checkBillingMethodPaypal() {
    //     this.checkCardBoxCard = false;
    //     this.checkCardBoxPaypal = "paypal";
    //     this.$emit(
    //         "checked-box",
    //         this.checkCardBoxCard,
    //         this.checkCardBoxPaypal
    //     );
    // },

    changeModalToEditBillingInfo() {
      this.$emit("change-modal-to-edit");
    },

    changeCard() {
      this.$emit("change-modal-to-edit-card");
    },

    changeCardData(prop) {
      return $event =>
        this.$emit("change-card", { [prop]: $event.target.value });
    },

    initBraintree() {
      this.setPaypalTokenLoading(true);
      axios
        .post(GET_BRAINTREE_CLIENT_TOKEN_API, {
          payPalEmail: this.userInfo.email
        })
        .then(({ data }) => {
          this.setPaypalTokenLoading(false);
          this.paymentToken = data;
          braintree.setup(data, "custom", {
            paypal: {
              container: "paypal-container"
            },
            currency: "USD",
            onPaymentMethodReceived: ({ nonce }) => {
              this.$emit("get-payment-token", nonce);
            }
          });
        })
        .catch(error => {
          console.warn(error);
          this.setPaypalTokenLoading(false);
        });
    },

    setPaypalTokenLoading(value) {
      this.isLoading = value;
    },
    removeCard(card_id) {
      this.doingAction = true;
      this.$emit("remove-card");
      setTimeout(()=>this.doingAction = false,1000);
    },
    makeCardPrimary(card_id) {
      //this.doingAction = true;
      // axios
      //   .get(`/billing/make_card_primary/${card_id}`)
      //   .then(response => {
      //     console.log(response);
      //     this.doingAction = false;
      //     this.$emit("getCard");
      //     this.$store.disptch('getUserData')
      //   })
      //   .catch(() => {
      //     this.isLoading = false;
      //     this.doingAction = false;
      //     console.warn;
      //   });
      this.$emit("make-card-primary",card_id);
    }
  }
};
</script>

<style lang="less" scoped>
.card-change {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 10px;
  padding-bottom: 10px;

  .card-input {
    font-size: 16px;
    border-radius: 4px;
    height: 38px;
    outline: none;
    width: 100%;
    background-color: none;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    padding-left: 10px;
  }

  .exp-month,
  .exp-year {
    max-width: 150px;
  }
  .cvc {
    max-width: 80px;
  }

  .card-number {
    margin: 5px;
  }

  .card-data {
    display: flex;
    justify-content: space-between;
    .card-input {
      margin: 5px;
    }
  }

  .actions {
    padding-left: 40px;
  }
}
.card-icon {
  width: 38px;
  top: 38px;
  right: 28px;
}
.active {
  color: #0ad688;
}
.pay_button {
  padding: 11px 30px !important;
  margin-top: 20px;
}
.mr-2 {
  margin-right: 21px;
}
.el-link--warning {
  color: #c93e00;
}
.loading-space-users {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  i {
    font-size: 34px;
  }

  &.overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.billing-info {
  // padding: 20px 0px !important;
}
.for-desktop {
  display: block;
}
.for-mobile {
  display: none;
}
.dull {
  opacity: 0.5;
}
.pb-20 {
  padding-bottom: 20px;
}
@media (max-width: 1024px) {
  .for-desktop {
    display: none !important;
  }
  .for-mobile {
    display: block !important;
  }
  .fa-edit {
    font-size: x-large;
  }
}
</style>

