<template>
    <div class="settings-right-area" id="settings-billing">
        <right-header-footer class="header-items m-top-3">
            <template v-slot:title>
                <router-link
                    :to="{name: 'SubscriptionPlan', params: { section: 'billing'}}"
                >Subscription plan</router-link>
            </template>

<!--            <template v-slot:second-title>-->
<!--                <router-link-->
<!--                    :to="{name: 'BillingInformationPage', params: { section: 'billing'}}"-->
<!--                >Billing information</router-link>-->
<!--            </template>-->

            <template v-slot:actions>
                <MobileMenu :isMobile='true' :rightArrow='true' class='mobileMenu'></MobileMenu>
            </template>
        </right-header-footer>

        <el-breadcrumb
            v-if="subscription || billing"
            separator-class="el-icon-arrow-right"
            class="bread-crumbs">
            <el-breadcrumb-item
                v-if="subscription"
                :to="{ name: 'SubscriptionPlan', params: { section: 'billing'}}"
                class="breadcrumbs-item_cursor"
            >Subscription Plan</el-breadcrumb-item>
            <el-breadcrumb-item
                v-if="billing"
                :to="{ name: 'BillingInformationPage', params: { section: 'billing'}}"
                class="breadcrumbs-item_cursor"
            >Billing Information</el-breadcrumb-item>
            <el-breadcrumb-item
                v-if="$route.path === '/settings/billing/change-subscription-plan'"
                class="breadcrumbs-item_cursor"
            >Change Plan</el-breadcrumb-item>
            <el-breadcrumb-item
                v-if="$route.path.includes('/settings/billing/receipt') && $route.params.receiptID"
                class="breadcrumbs-item_cursor"
            >View Receipt BC{{invoiceDetails.original_id}}</el-breadcrumb-item>
            <el-breadcrumb-item
                v-if="$route.path === '/settings/billing/estimate'"
                class="breadcrumbs-item_cursor"
            >Billing Estimate</el-breadcrumb-item>
        </el-breadcrumb>

        <router-view />
    </div>
</template>

<script>
import { mapState } from "vuex";
import RightHeaderFooter from "./Layout/RightHeaderFooter";
import SubscriptionPlan from "./Items/Subscription/SubscriptionPlan";
import ChangeSubscriptionPlan from "./Items/Subscription/ChangeSubscriptionPlan";
import BillingEstimate from "./Items/Billings/BillingEstimate";
import BillingInformationPage from "./Items/Billings/BillingInformationPage";
import BillingReceipt from "../Shared/BillingInformation/BillingReceipt";
import MobileMenu from './Layout/MobileMenu';

export default {
    name: "SubscriptionBilling",
    components: {
        RightHeaderFooter,
        SubscriptionPlan,
        ChangeSubscriptionPlan,
        BillingEstimate,
        BillingInformationPage,
        BillingReceipt,
        MobileMenu
    },
    props: {},
    computed: {
        errorsForm: {
            get() {
                return this.$store.state.errorsForm;
            }
        },
        ...mapState({
            invoiceDetails: state => state.invoiceDetails
        })
    },
    data: () => ({
        subscription: false,
        billing: false
    }),
    watch: {
        "$route.path": function(newVal) {
            this.setBreadcrumbFlag(newVal);
        }
    },
    beforeDestroy() {},
    mounted() {
        this.setBreadcrumbFlag(this.$route.path);
    },
    created() {},
    methods: {
        setBreadcrumbFlag(path) {
            if (path === "/settings/billing/change-subscription-plan") {
                this.subscription = true;
                this.billing = false;
            } else if (
                path === "/settings/billing/estimate" ||
                path.includes("/settings/billing/receipt")
            ) {
                this.billing = true;
                this.subcription = false;
            } else {
                this.billing = false;
                this.subscription = false;
            }
        },
    }
};
</script>

<style lang="less" scoped>
.bread-crumbs {
    margin-bottom: 25px;

    .breadcrumbs-item_cursor {
        cursor: pointer;
    }
}

.mobileMenu {
    display: none;
}

@media (max-width: 1024px) {
    .mobileMenu {
        display: block !important;
    }
}

// @media screen and (max-width: 1600px) {
//     .header-items {
//         width: calc(50% + 40px) !important;
//         padding: 0px 0rem 2rem 6rem !important;
//     }
// }

@media (max-width: 960px) {
    .el-container,
    .is-always-shadow {
        padding: 0px !important;
    }
}
</style>

