<template>
    <svg
        id="Group_4358"
        data-name="Group 4358"
        xmlns="http://www.w3.org/2000/svg"
        width="40.561"
        height="40.561"
        viewBox="0 0 40.561 40.561"
    >
        <rect
            id="Rectangle_1408"
            data-name="Rectangle 1408"
            width="40.561"
            height="40.561"
            rx="20.28"
            fill="#f27500"
        />
        <g
            id="Group_11222"
            data-name="Group 11222"
            transform="translate(22.626 31.131) rotate(180)"
        >
            <circle
                id="Ellipse_4259"
                data-name="Ellipse 4259"
                cx="2.295"
                cy="2.295"
                r="2.295"
                transform="translate(0)"
                fill="#fff"
            />
            <path
                id="Path_8229"
                data-name="Path 8229"
                d="M1.372,0A1.372,1.372,0,0,0,0,1.372V13.034a1.372,1.372,0,1,0,2.744,0V1.372A1.372,1.372,0,0,0,1.372,0Z"
                transform="translate(1.004 8.332)"
                fill="#fff"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "ExclamationMark"
};
</script>

<style lang="less" scoped>
</style>

