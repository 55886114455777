<template>
  <div>
    <div v-if='showRestrictionModal'>
		  <BasePlanRestriction :showModal='showRestrictionModal' @close='showRestrictionModal = false'/>
	  </div>
    <div class="settings-stage settings-right-area" id="settings-stage" v-if="user && !showRestrictionModal">
      <right-header-footer class="header-items m-top-3 d-flex justify-content-between">
        <template v-slot:title>Stage</template>
          <template v-slot:actions>
            <MobileMenu :isMobile='isMobile'></MobileMenu>
            <button @click="saveStage" class="btn btn-primary m-left-2" type="button">
              <i class="far fa-save" v-if="!isMobile"></i> Save
            </button>
          </template>
      </right-header-footer>
      <el-container id="id_settings-stage">
        <el-card>
          <div class="header-title">Stage</div>

          <div class="form-group row-section" style='padding: 25px 10px 0px 10px;'>
            <label class="section-label text-uppercase">Privacy</label>
            <div>
              Your Stage page allows viewers to view and explore your public videos.
              If your Stage is hidden, viewers will still be able to view your videos and playlists only with the directs links.
            </div>
          </div>

          <div class="form-group row-section" style='padding: 25px 10px 0px 10px;'>
            <label class="section-label">Stage Visibility</label>
            <el-row>
              <el-col :span="12" :xs="24">
                <el-select
                  v-if="edit_stage"
                  :value="user.owner.settings.stage_visibility"
                  @change="stageChange"
                  class="w-100 custom-el-select"
                  popper-class="checkbox-select custom_select_pop "
                  placeholder="Select Visibility"
                  v-model="user.owner.settings.stage_visibility"
                >
                  <el-option
                    :key="`show_email_capture_${item.value}`"
                    :label="item.label"
                    :value="item.value"
                    v-for="item in stage_visibility"
                  >
                    <i class="el-icon-check"></i>
                    {{ item.label }}
                  </el-option>
                </el-select>
                <p
                  v-else
                  v-for="item in stage_visibility"
                >{{user.owner.settings.stage_visibility === item.value ? item.label : ''}}</p>
              </el-col>

              <el-col :span="24" :xs="24" v-if="user.owner.settings.stage_visibility === 'publish'">
                <div class="form-group row-section" style='padding: 25px 10px 0px 10px;'>
                  <label class="section-label" for="stage_public_url">Stage Public URL</label>
                  <el-row :gutter="20" class="d-flex align-items-center">
                    <span
                      class="ml-2 mt-1"
                      style="font-size: 14px;"
                      v-if="edit_stage && !user.owner.settings.public_stage_visited"
                    >
                      <label for="stage_public_url">{{ stage_url_default }}</label>
                    </span>
                    <el-col
                      :span="!edit_stage || user.owner.settings.public_stage_visited ? 9 : 5"
                      :xs="!edit_stage || user.owner.settings.public_stage_visited ? 9 : 5"
                      id="stage_public_url_area"
                      :class="!edit_stage || user.owner.settings.public_stage_visited ? 'ml-2 mt-1' : ''"
                    >
                      <el-input
                        v-on:input="checkUrlIsAvailableAndSave('check')"
                        @change="checkUrlIsAvailableAndSave('save')"
                        :disabled="!edit_stage || user.owner.settings.public_stage_visited ? true : false"
                        :readonly="!edit_stage || user.owner.settings.public_stage_visited ? true : false"
                        id="stage_public_url"
                        class="custom-el-input p-left-0"
                        placeholder="create URL to publish your stage"
                        v-model="stage_public_url"
                      ></el-input>
                    </el-col>
                    <el-col :span="2" :xs="2" >
                      <el-button style='padding: 10px !important' type="button" v-on:click="copy_stage_public_url($event)">Copy</el-button>
                    </el-col>
                  </el-row>
                  <div
                    v-if="used_url"
                    style="color: red; font-size: 14px; font-weight: bold;"
                  >This URL is already taken. Please enter another one.</div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="form-group row-section" style='padding: 25px 10px 0px 10px;'>
            <span
            >Email capture forms are shown to viewers on Stage page. This enables you to capture new leads.</span>

            <el-row :gutter="20" class="m-top-4 d-flex">
              <el-col :span="2" :xs="4">
                <el-switch
                  v-if="edit_stage"
                  :active-value="1"
                  :inactive-value="0"
                  @change="stageChange"
                  active-color="#0AD688"
                  active-icon-class="el-icon-check success"
                  v-model="user.owner.settings.show_email_capture"
                ></el-switch>

                <el-popover
                  content="You don't have permission to edit stage options"
                  placement="top"
                  trigger="hover"
                  v-else
                  popper-class="custom_popover pop_top_220x62"
                >
                  <el-switch
                    :active-value="1"
                    :disabled="true"
                    :inactive-value="0"
                    active-color="#0AD688"
                    active-icon-class="el-icon-check success"
                    slot="reference"
                    v-model="user.owner.settings.show_email_capture"
                  ></el-switch>
                </el-popover>
              </el-col>
              <el-col :span="16" :xs="24">Show email capture form on Stage</el-col>
            </el-row>

            <el-row :gutter="20" class="m-top-4 d-flex">
              <el-col :span="2" :xs="4">
                <el-switch
                  v-if="edit_stage"
                  :active-value="1"
                  :inactive-value="0"
                  @change="stageChange"
                  active-color="#0AD688"
                  active-icon-class="el-icon-check success"
                  v-model="user.owner.settings.notify_to_subscribers"
                ></el-switch>

                <el-popover
                  content="You don't have permission to edit stage options"
                  placement="top"
                  trigger="hover"
                  v-else
                  popper-class="custom_popover pop_top_220x62"
                >
                  <el-switch
                    :active-value="1"
                    :disabled="true"
                    :inactive-value="0"
                    active-color="#0AD688"
                    active-icon-class="el-icon-check success"
                    slot="reference"
                    v-model="user.owner.settings.notify_to_subscribers"
                  ></el-switch>
                </el-popover>
              </el-col>
              <el-col :span="16" :xs="24">Notify Stage Subscribers when I upload a new Public video</el-col>
            </el-row>

            <el-row :gutter="20" class="m-top-4 d-flex">
              <el-col :span="2" :xs="4">
                <el-switch
                  v-if="edit_stage"
                  :active-value="1"
                  :inactive-value="0"
                  @change="stageChange"
                  active-color="#0AD688"
                  active-icon-class="el-icon-check success"
                  v-model="user.owner.settings.auto_tag_email_list"
                ></el-switch>

                <el-popover
                  content="You don't have permission to edit stage options"
                  placement="top"
                  trigger="hover"
                  v-else
                  popper-class="custom_popover pop_top_220x62"
                >
                  <el-switch
                    :active-value="1"
                    :disabled="true"
                    :inactive-value="0"
                    active-color="#0AD688"
                    active-icon-class="el-icon-check success"
                    slot="reference"
                    v-model="user.owner.settings.auto_tag_email_list"
                  ></el-switch>
                </el-popover>
              </el-col>
              <el-col :span="16" :xs="24">Automatically Tag and add Subscribers to Email List</el-col>
            </el-row>

            <el-row :gutter="20" class="m-top-4">
              <el-col :span="2">&nbsp;</el-col>
              <el-col :span="13" :xs="24">
                <label class="control-label">Email List-- </label> 
                <el-select
                  :value="user.owner.settings.email_list_id"
                  @change="edit_stage ? stageChange : ''"
                  class="w-100 custom-el-select"
                  popper-class="custom_select_pop"
                  filterable
                  :disabled="!edit_stage"
                  :aria-disabled="!edit_stage"
                  placeholder="Select Email List"
                  v-model="user.owner.settings.email_list_id"
                >
                  <el-option-group
                    v-for="(group, ind) in email_lists"
                    :key="`${group.mailer}_${ind}`"
                    :label="getNiceName(group.mailer)"
                  >
                    <el-option
                      :key="`email_list_id_${item.id}`"
                      :label="item.name"
                      :value="`${group.mailer}_${item.id}`"
                      v-for="item in group.lists"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="m-top-4">
              <el-col :span="2">&nbsp;</el-col>
              <el-col :span="13" :xs="24">
                <label class="control-label">Tags {{ stage_tags.length }}</label>
                <div class="form-group">
                  <div class="inline-flex w-100" v-if="edit_stage">
                    <el-input
                      @blur="handleInputConfirm"
                      @keyup.enter.native="handleInputConfirm"
                      class="input-new-tag custom-el-input"
                      ref="saveTagInput"
                      size="medium"
                      v-model="stage_tag_input"
                    ></el-input>
                    <el-button @click="handleInputConfirm" class="button-new-tag" size="medium">+</el-button>
                  </div>
                  <div class="w-100 m-top-3">
                    <el-tag
                      v-for="(tag, tagInd) in stage_tags"
                      :disable-transitions="false"
                      :key="`stage_tag_${tagInd}`"
                      @close="edit_stage ? handleClose(tag) : ''"
                      :closable="edit_stage"
                    >{{ tag }}</el-tag>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <el-card class="m-top-3">
          <div class="clearfix" slot="header">
            <div class="header-title">Stage Profile</div>
          </div>

          <div class="form-group row-section" style='padding: 25px 10px 0px 10px;'>
            <label class="section-label" for="stage-name">Stage Name</label>
            <el-row :gutter="20">
              <el-col :span="13" :xs="24">
                <el-input
                  v-if="edit_stage"
                  id="stage-name"
                  @blur="stageChange('stage_name')"
                  @keyup.enter.native="stageChange('stage_name')"
                  class="custom-el-input"
                  placeholder="Enter Stage Name"
                  v-model="stage_name"
                ></el-input>
                <div v-else>{{ stage_name }}</div>
              </el-col>
            </el-row>
          </div>

          <el-row :gutter="20" class="m-top-4" v-if="edit_stage" style='padding: 25px 10px 0px 10px;'>
            <el-col :span="3">
            <!--
              <el-upload
                :action="`${backendUrl}api/settings/stage-logo-upload`"
                :before-upload="beforeLogoUpload"
                :headers="headers"
                :on-success="handleLogoSuccess"
                :show-file-list="false"
                class="stage-logo-uploader"
                ref="stage_logo_uploader"
              >
              -->
              <div class='stage-logo-uploader'>
                <input @change="uploadImage($event)" accept="image/*" ref="logo_file" class='hidden' type="file"/>
                <image-cropper
                  :mobileCheck='isMobile'
                  :trigger='cropImage'
                  :imageUrl='image_url'
                  action='settings/stage-logo-upload'
                  @close='cropImage = false'
                  @imageLink='userLogo = $event'
                >
                </image-cropper>
                <img
                  :src="userLogo"
                  class="stage-logo"
                  v-if="userLogo"
                />
                <i class="el-icon-picture-outline stage-logo-uploader-icon" v-else></i>
              </div>
              <!--</el-upload>-->
            </el-col>
            <el-col :span="15" :xs="24">
              <label
                class="section-label"
              >{{userLogo ? 'Edit' : 'Add' }} Your Stage Logo</label>
              <div class="w-100">For best results, upload a high resolution image.</div>
              <div class="w-100 m-top-2">
                <el-button
                  @click="clickLogoUpload"
                  class="btn-info-outline"
                  :loading="logoLoading"
                  round
                >{{userLogo ? 'Edit' : 'Add' }} Logo</el-button>
                <el-button
                  class='btn-info-outline'
                  :loading="logoLoading"
                  @click='removeLogoModal = true'
                  v-if='userLogo'
                  round
                >
                  <span class='text-danger'>Remove Logo</span>
                </el-button>
              </div>
            </el-col>
          </el-row>
          <div class="form-group row-section m-top-4" v-else style='padding: 25px 10px 0px 10px;'>
            <label class="section-label">Stage Logo</label>
            <div class="stage-logo-uploader">
              <div class="el-col-2">
                <img
                  :src="user.owner.settings.logo"
                  class="stage-logo"
                  v-if="user.owner.settings.logo"
                />
              </div>
            </div>
          </div>

          <div style="opacity: 0;">
            <el-input
              type="text"
              id="stage_public_url_copy"
              class="custom-el-input"
              v-model="stage_public_url_copy"
            ></el-input>
          </div>
        </el-card>
      </el-container>
    </div>
    <!-- Modals -->
    <BaseConfirm
      v-if='removeLogoModal'
      head='Are you sure?'
      body='This will remove the logo permanently.'
      @confirm='removeLogo'
      @cancel='removeLogoModal = false'
    />
  </div>
</template>

<script>
import RightHeaderFooter from "./Layout/RightHeaderFooter";
import MobileMenu from './Layout/MobileMenu';
import ImageCropper from '../Common/ImageCropper';

export default {
  name: "SettingsStage",
  components: { RightHeaderFooter, MobileMenu, ImageCropper },
  props: {
    user: {
      type: Object,
      default() {
        return { owner: { stages: {} } };
      }
    }
  },
  data: () => ({
    stage_visibility: [
      { value: "publish", label: "Public (Anyone can visit my Stage)" },
      { value: "hidden", label: "Hide from Bigcommand.com" }
    ],
    email_lists: [],
    stage_tags: [],
    stage_tag_input: "",
    stage_name: "", 
    stage_public_url: "",
    stage_public_url_copy: "",
    used_url: false,
    isMobile: false,
    headers: {
      Authorization: ""
    },
    edit_stage: false,
    logoLoading: false,
    cropImage: false,
    image_url: null,
    userLogo: null,
    showRestrictionModal: true,
    removeLogoModal: false,
  }),
  computed:{
    stage_url_default(){
      return `${this.user.site_domain}/`
    }
  },
  created() {
    this.mobileCheck();
  },
  mounted() {
    this.getEmailLists();
    window.vEvent.listen("stage-common-error", this.commonError);
    this.user.user_permissions.forEach(i => {
      if (i.permission === "edit-stage") {
        this.edit_stage = true;
      }
    });
    this.userLogo = this.$props.user.owner.settings.logo;
    let vm = this;
    if (this.user) {
      if (this.user.settings) {
        this.setUserSetting();
      } else {
        let userInterval = setInterval(function() {
          if (vm.user) {
            vm.setUserSetting();
            clearInterval(userInterval);
          }
        }, 300);
      }
    } else {
      let userInterval = setInterval(function() {
        if (vm.user) {
          vm.setUserSetting();
          clearInterval(userInterval);
        }
      }, 300);
    }

    this.headers.Authorization = `Bearer ${this.$store.state.accessToken}`;
  },
  methods: {
    setUserSetting() {
      if (this.user.owner.settings.stage_tags) {
        this.stage_tags = JSON.parse(this.user.owner.settings.stage_tags);
      }

      this.stage_name = this.user.owner.settings.stage_name
        ? this.user.owner.settings.stage_name
        : "";

      this.stage_public_url = this.user.owner.settings.stage_public_url
        ? !this.edit_stage || this.user.owner.settings.public_stage_visited
          ? this.user.owner.settings.stage_public_url
          : this.getSlug(this.user.owner.settings.stage_public_url)
        : "";
      this.stage_public_url_copy =
        this.stage_url_default + this.stage_public_url;
    },

    /**
     * Stage setting changed
     */
    stageChange(item) {
      if (this.edit_stage) {
        let post_data = {
          url: "/settings/set-stage-data",
          data: {
            stage_visibility: this.user.settings.stage_visibility,
            show_email_capture: this.user.settings.show_email_capture,
            notify_to_subscribers: this.user.settings.notify_to_subscribers,
            auto_tag_email_list: this.user.settings.auto_tag_email_list,
            email_list_id: this.user.owner.settings.email_list_id,
            stage_tags: this.stage_tags,
            stage_name: this.stage_name
          }
        };

        this.$store
          .dispatch("post", { ...post_data })
          .then(response => {
            if (response.data.result === "success") {
              this.user.settings.stage_tags = JSON.stringify(this.stage_tags);
              this.user.settings.stage_name = this.stage_name;
              this.$store.commit("setUserInfo", this.user);
            } else {
            }
          })
          .catch(error => {});
      } else if (typeof item !== typeof undefined) {
        if (item === "stage_name") {
          let post_data = {
            url: "/settings/set-stage-data",
            data: {
              stage_name: this.stage_name
            }
          };

          this.$store.dispatch("post", { ...post_data }).then(response => {
            if (response.data.result === "success") {
              this.user.settings.stage_name = this.stage_name;
            }
          });
        }
      }
    },

    /**
     * Check url availability and save it in the database
     */
    checkUrlIsAvailableAndSave(action) {
      this.stage_public_url_copy =
        this.stage_url_default + this.stage_public_url;
      console.log(this.edit_stage);
      if (this.edit_stage) {
        if (typeof action !== typeof undefined) {
          if (!this.user.settings.public_stage_visited) {
            if (this.stage_public_url.length >= 6) {
              let full_url = this.stage_url_default + this.stage_public_url,
                post_data = {
                  url: "/settings/check-and-save-public-url",
                  data: {
                    url: full_url,
                    action: action
                  }
                };

              this.$store
                .dispatch("post", { ...post_data })
                .then(response => {
                  this.used_url =
                    response.data.result === "fail" ? true : false;
                })
                .catch(error => {
                  console.warn(error);
                });
            } else {
              console.info("Custom url name must be 6 characters or longer.");
            }
          } else {
            window.vEvent.fire("settings-common-error", {
              title:
                "You can't edit stage public url,because it's already visited.",
              close: true
            });
          }
        }
      } else {
        window.vEvent.fire("settings-common-error", {
          title: "You do not have permission to edit the stage public link.",
          close: true
        });
      }
    },

    copy_stage_public_url(e) {
      if (e.target.innerText === "Copy") {
        this.stage_public_url_copy =
          this.stage_url_default + this.stage_public_url;

        let copyTextInput = document.querySelector("#stage_public_url_copy"),
          disabled = false,
          successful = false,
          msg = "Something went wrong.";

        if (!this.edit_stage || this.user.settings.public_stage_visited) {
          copyTextInput = document.querySelector("#stage_public_url");
        }

        if (copyTextInput.hasAttribute("disabled")) {
          copyTextInput.removeAttribute("disabled");
          disabled = true;
        }
        copyTextInput.select();

        try {
          successful = document.execCommand("copy");
          if (successful) {
            msg = "Stage public url copied successful.";
            e.target.innerText = "Copied";
          }
        } catch (e) {
          msg = "Not copying.";
        }

        window.vEvent.fire("settings-common-success", {
          title: msg,
          close: true
        });

        if (disabled) {
          copyTextInput.setAttribute("disabled", "disabled");
        }
        /* unselect the range */
        window.getSelection().removeAllRanges();
      }

      setTimeout(function() {
        e.target.innerText = "Copy";
      }, 3000);
    },

    /**
     * Get Autoresponder lists.
     */
    getEmailLists() {
      let params = {
        url: "/integrations/lists",
        config: {}
      };
      this.$store.dispatch("getData", { ...params }).then(response => {
        this.email_lists = response.data;
      });
    },

    getNiceName(name) {
      if (this.$store.state.integrationLists[name]) {
        return this.$store.state.integrationLists[name]["name"];
      }

      return name;
    },

    /**
     * Tags Input
     */
    handleInputConfirm() {
      if (this.stage_tag_input) {
        this.stage_tags.push(this.stage_tag_input);
        this.stage_tag_input = "";

        this.stageChange();
      }
    },

    /**
     * Tags remove
     */
    handleClose(tag) {
      this.stage_tags.splice(this.stage_tags.indexOf(tag), 1);

      this.stageChange();
    },

    beforeLogoUpload(file) {
      this.logoLoading = true;
      const isJPG = file.type === "image/jpeg";
      const isLt50M = file.size / 1024 / 1024 <= 50;

      if (!isJPG) {
        this.$message.error("Logo picture must be JPG format!");
        this.logoLoading = false;
      }

      if (!isLt50M) {
        this.$message.error("Logo picture size can not exceed 50MB!");
        this.logoLoading = false;
      }

      return isJPG && isLt50M;
    },

    handleLogoSuccess(res, file) {
      this.logoLoading = false;
      if (res.result === "success") {
        this.user.owner.settings.logo = URL.createObjectURL(file.raw);
        this.$store.commit("setUserInfo", this.user);
      } else {
      }
    },

    clickLogoUpload() {
      // this.$refs.stage_logo_uploader.$el.firstChild.click();
      this.$refs.logo_file.click();
    },

    removeLogo() {
      this.logoLoading = true;
      this.removeLogoModal = false;
      this.axios.post('delete-stage-logos', {
        folder: 'stage_logo'
      })
      .then(response => {
        this.logoLoading = false;
        if (response.data.success) {
          this.userLogo = null;
        }
      })
      .catch(error => {
        this.logoLoading = false;
        console.log(error);
      })
    },

    getSlug(str) {
      var n = str.lastIndexOf("/");
      return str.substring(n + 1);
    },
    saveStage() {
      if (this.edit_stage) {
        this.stageChange();
        this.checkUrlIsAvailableAndSave("check");
        this.checkUrlIsAvailableAndSave("save");
        window.vEvent.fire('settings-common-success', {
                            title: 'Stage Information',
                            message: 'Successfully Updated',
                            close: true
                        });
      }else{
          window.vEvent.fire('settings-common-error', {
                            title: 'Unauthorized',
                            message: 'Dont have permission to edit the Stage',
                            close: true
                        });
      }
    },

    mobileCheck() {
      let BC_onMobile = false;
      if (
        window.screen.availWidth <= 414 ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        BC_onMobile = true;
      }

      this.isMobile = BC_onMobile;
    },
    uploadImage(event) {
      let input = event.target;
      if (input.files && input.files[0]) {
          let reader = new FileReader();
          reader.onload = e => {
              this.image_url = e.target.result;
              this.cropImage = true;
          };

        reader.readAsDataURL(input.files[0]);
      }
    },
    commonError(msg) {
      this.$notify({
        type: "error",
        title: "Failure!",
        message: msg,
        position: "top-right",
        customClass: "settings-notify"
      });
    },
  }
};
</script>

<style lang="less">
.settings-stage {
  position: relative;

  .stage-logo-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      padding: 0;
      outline: 0;
      display: flex;
      align-items: center;
      height: 80px;
      width: 80px;

      &:hover {
        border-color: #409eff;
      }
    }

    .stage-logo-uploader-icon {
      width: 100%;
      font-size: 80px;
      color: #ceecf5;
      height: auto;
      text-align: center;
    }

    .stage-logo {
      width: 100%;
      height: auto;
      display: block;
    }

    .el-upload__input {
      display: none;
    }
  }

  #stage_public_url {
    padding-left: 0;
    height: 35px !important;
  }

  #stage_public_url_area {
    padding-left: 1px !important;
    padding-right: 0 !important;
  }
}

    @media (max-width: 960px) {
        .el-container,
        .is-always-shadow {
            padding: 0px !important;
        }
    }

    .hidden {
      display: none;
    }
</style>
