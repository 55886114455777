<template>
  <div class="red-warning">
    <div class="red-warning_sign">
      <red-sign></red-sign>
    </div>
    <div class="red-warning__text">{{ text }}</div>
  </div>
</template>
<script>
import RedSign from "../../../SvgComponents/RedSign";

export default {
  name: "RedWarning",
  props: ["text"],
  components: {
    RedSign
  }
};
</script>
<style lang="less" scoped>
.red-warning {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  &_sign {
    width: 18px;
    height: 18px;
  }
  &__text {
    margin-left: 7px;
    color: #ee5951;
  }
}
</style>