import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=6c6cca65&scoped=true&"
import script from "./Settings.vue?vue&type=script&lang=js&"
export * from "./Settings.vue?vue&type=script&lang=js&"
import style0 from "./Settings.vue?vue&type=style&index=0&lang=less&"
import style1 from "../assets/less/custom/common.less?vue&type=style&index=1&id=6c6cca65&lang=less&scoped=true&"
import style2 from "../assets/less/custom/pages.less?vue&type=style&index=2&lang=less&"
import style3 from "../assets/less/custom/settings.less?vue&type=style&index=3&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c6cca65",
  null
  
)

export default component.exports