<template>
    <div class="dialog-body">
        <div class="contact-info-title">Contact Billing Information</div>
        <div class="contact-info">
            <el-col :span="17">
                <issued-to-billing
                    :issuedTo="billingInfo"
                    :issued-to-view="false"
                    :span="24"
                    :two-columns="true"
                />
            </el-col>
        </div>

        <div class="edit-area">
            <div class="row">
                <el-col class="column" :span="10" :offset="1">
                    <div class="input-label">
                        <div class="main-text">Phone</div>
                        <div></div>
                    </div>
                    <el-input placeholder v-model="newInfo.phone" @input="emitNewInfo"></el-input>
                </el-col>
                <el-col class="column" :span="10" :offset="1">
                    <div class="input-label">
                        <div class="main-text">Billing Address</div>
                        <div class="required-text">required</div>
                    </div>
                    <el-input placeholder v-model="newInfo.billing_address" @input="emitNewInfo"></el-input>
                </el-col>
            </div>
            <div class="row">
                <el-col class="column" :span="10" :offset="1">
                    <div class="input-label">
                        <div class="main-text">Billing Address 2</div>
                        <div></div>
                    </div>
                    <el-input
                        placeholder
                        v-model="newInfo.billing_address_line_2"
                        @input="emitNewInfo"
                    ></el-input>
                </el-col>
                <el-col class="column" :span="10" :offset="1">
                    <div class="input-label">
                        <div class="main-text">Billing Country</div>
                        <div class="required-text">required</div>
                    </div>
                    <el-input placeholder v-model="newInfo.billing_country" @input="emitNewInfo"></el-input>
                </el-col>
            </div>
            <div class="row">
                <el-col class="column" :span="10" :offset="1">
                    <div class="input-label">
                        <div class="main-text">Billing City</div>
                        <div class="required-text">required</div>
                    </div>
                    <el-input placeholder v-model="newInfo.billing_city" @input="emitNewInfo"></el-input>
                </el-col>
                <el-col class="column" :span="10" :offset="1">
                    <div class="input-label">
                        <div class="main-text">Billing Zip</div>
                        <div class="required-text">required</div>
                    </div>
                    <el-input placeholder v-model="newInfo.billing_zip" @input="emitNewInfo"></el-input>
                </el-col>
            </div>
        </div>
    </div>
</template>

<script>
import IssuedToBilling from "../Shared/IssuedToBilling";

export default {
    name: "EditBodyBillingInformation",
    components: {
        IssuedToBilling
    },
    props: {
        billingInfo: Object
    },
    computed: {
        errorsForm: {
            get() {
                return this.$store.state.errorsForm;
            }
        }
    },
    data: function() {
        return {
            newInfo: {
                ...this.billingInfo
            }
        };
    },
    beforeDestroy() {},
    mounted() {
        for (const property in this.newInfo) {
            if (this.newInfo[property] === null) {
                this.newInfo[property] = "";
            }
        }
        this.emitNewInfo();
    },
    created() {},
    methods: {
        emitNewInfo() {
            this.$emit("new-billing-info", this.newInfo);
        }
    }
};
</script>

<style lang="less" scoped>
</style>

