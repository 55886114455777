<template>
    <div class="w-100 mb-5 base-cover">
        <component :is="currentComponent" v-bind="currentProps" @createUser="showUsercreate"
        @userCreated="showUsersTable" @viewUser="showViewUser" @updateSubusers="updateSubusers"></component>
    </div>
</template>

<script>

    import UsersTable from './components/UsersTable.vue'
    import UsersCreate from './components/UserCreate.vue'
    import UserEditView from './components/UserEditView.vue'




    import RightHeaderFooter from "./Layout/RightHeaderFooter";
    import UserList from "./Items/UserList";
    import CreateEditUser from "./Items/CreateEditUser";
    import MobileMenu from '../Settings/Layout/MobileMenu';

    export default {
        name: "TeamUsers",
        components: {
            UsersTable,
            UsersCreate,
            UserEditView,
            
            
            CreateEditUser, UserList, RightHeaderFooter, MobileMenu
        },
        props: {
            user: Object
        },
        data: () => ({
            subusers: [],
            currentComponent: 'UsersTable',
            subuser: {},

            page_type: 1,
            sel_user: {},
            deleteDialog: false,
            freePack: 'NA',
            showRestrictionModal: true,
        }),
        computed: {
            currentProps() {
                // if (this.currentComponent === 'UsersTable') {
                //     console.log('users', this.users)
                //     // return { users: this.users }
                // }
                let data = {}
                if (this.currentComponent === 'UsersCreate') {
                    data =  { user: this.user }
                }
                if (this.currentComponent === 'UserEditView') {
                    data = { subuza: this.subuser }
                }
                if (this.currentComponent === 'UsersTable') {
                    data = { ProjectsHidden: this.user.hide_project_from_users, user: this.user }
                }
                return data;
            }
        },
        methods: {
            showUsercreate() {
                if(this.user.last_selected_plan.subusers_limit > this.subusers.length) {
                    this.currentComponent = 'UsersCreate';
                } else {
                    this.$message.error('You have reached the maximum number of users you can create on this plan');
                }
            },
            showUsersTable() {
                this.currentComponent = 'UsersTable';
            },
            showViewUser(data) {
                this.subuser = data;
                this.currentComponent = 'UserEditView';
            },
            updateSubusers(data) {
                this.subusers = data;
            },





            updateUserPermission(){

                this.axios({
                    method: "post",
                    url: "subuser/permission",
                    data: {
                        hide_project_from_users: this.user.settings.hide_project_from_users
                    }
                })
                    .then(response => {

                    })
                    ["catch"](error => {

                });

                console.log('updateUserPermission', this.user.settings.hide_project_from_users);
            },
            setPageType(type) {
                this.page_type = type;
                document.querySelector("body").scrollTop = 0;
                document.querySelector("#app").scrollTop = 0;
            },
            selUser(user) {
                this.sel_user = user;
            },
            saveUser() {
                window.vEvent.fire("save-sub-user");
            },
            deleteDialogShow() {
                this.deleteDialog = true;
            },
            getPlan()
            {
                this.axios.post('owner-plan').then(r => {
                    console.log(r.data.name);
                    if (r && r.data) {
                        if (r.data.name == 'Free Forever' || r.data.name == 'Starter') {
                            this.freePack = true;
                        } else {
                            this.freePack = false;
                        }
                    }
                });
            },
        }
    };
</script>

<style>
    .base-cover {
        background-color: #fff; 
        padding: 3%;
        min-height: 100vh !important;
    }
    .i-btnpry:hover {
        background-color: #053083 !important ;
        color: #fff !important ;
    }
    @media screen and (max-width: 600px) {
        .base-cover {
            padding: 1% 0;
        }
    }
</style>
<style lang="less" scoped>
    .hide-project {
        @media screen and (max-width: 1000px) {
            order: 2 !important;
        }
    }
</style>
