<template>
    <div
            class="settings-integration settings-right-area"
            id="settings-integration"
            @enter="enter"
    >
        <right-header-footer class="header-items m-top-3">
            <template v-slot:title>Integration {{ mailers.connected.length }}</template>
            <template v-slot:actions>
                <div class='d-flex integrationButtons'>
                    <MobileMenu :isMobile='true' class='mobileMenu'></MobileMenu>
                    <button @click="newIntegration" class="btn btn-primary" type="button">
                        New Integration
                    </button>
                </div>
            </template>
        </right-header-footer>

        <el-container>
            <blank-integration v-if="!mailers.connected.length"></blank-integration>
            <template v-else>
                <el-card v-for="(category, c_ind) in mailerCategory" v-if="mailers[category.key].connected.length"
                         :key="`m_category_card_${c_ind}`" class="integration-list-card">
                    <div class="header-title">
                        {{ category.name }} {{ mailers[category.key].connected.length }}
                    </div>

                    <el-row :gutter="20" class="m-top-3 mx-mob-0">
                        <el-col :span="4">&nbsp;</el-col>
                        <el-col :span="11" :xs="24">INTEGRATION</el-col>
                        <el-col :span="7" :xs="22">LAST ACTIVITY</el-col>
                        <el-col :span="2">&nbsp;</el-col>
                    </el-row>

                    <el-row :gutter="20"
                            :key="`child_list_row_${ch_ind}`"
                            class="integration-list-row mx-mob-0"
                            v-for="(item, ch_ind) in mailers[category.key].connected">
                        <el-col :span="4" class="integration-icon">
                            <img :alt="item.service_name" :src="`${backendUrl}img/Integrations/${getIcon(item.service_key)}`"/>
                        </el-col>
                        <el-col :span="11" :xs="24" class="integration-description">
                            <div>{{ item.service_name }}: {{ item.display_name }} </div>
                            <div>Integrated: {{ item.integrated_at }}</div>
                        </el-col>
                        <el-col :span="7" :xs="18" class="integration-activity">
                            <span v-if="item.created_at === item.updated_at">Never</span>
                            <span v-else>{{ item.last_activity }}</span>
                        </el-col>
                        <el-col :span="2" :xs="6" class="action-column" >
                            <el-dropdown @command="dropdownSelect" class="m-left-2" trigger="click" placement="bottom">
                                <div class="el-dropdown-link btn btn-circle">
                                    <i class="el-icon-more"></i>
                                </div>

                                <el-dropdown-menu slot="dropdown" class="custom_dropdown dropdown_bottom_200">
                                    <el-dropdown-item command="disconnectService">
                                        <div @click="clickDisconnect(item)" class="w-100 text-danger">
                                            Disconnect
                                        </div>
                                        <div v-if='item.service_name == "Zapier"'>
                                            <div title='Add adilo zapier app to your zapier account if not added already.'>
                                                <div>
                                                    <a href="https://zapier.com/developer/public-invite/115527/62eeb615c36636d039a7177f4da0021f/" target='_blank'>Add App</a>
                                                </div>
                                            </div>
                                        </div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-col>
                    </el-row>
                </el-card>
            </template>
        </el-container>

        <new-integration :integration-dialog="integrationDialog" :user="user"></new-integration>
    </div>
</template>

<script>
    import RightHeaderFooter from "./Layout/RightHeaderFooter";
    import BlankIntegration from "./Items/BlankIntegration";
    import NewIntegration from "./Items/NewIntegration";
    import MobileMenu from './Layout/MobileMenu';

    export default {
        name: "SettingsIntegration",
        components: {NewIntegration, BlankIntegration, RightHeaderFooter, MobileMenu},
        props: {
            user: Object,
        },
        data: () => ({
            mailers: {
                connected: [],
                not_connected: [],
                email: {connected: [], not_connected: []},
                webinar: {connected: [], not_connected: []},
                crm: {connected: [], not_connected: []},
                social_media: {connected: [], not_connected: []},
                other: {connected: [], not_connected: []}
            },
            dialogVisible: false,
            authIsForm: false,
            disconnect: false,
            disconnectName: '',
            integrationDialog: {
                show: false,
                step: 1,
                active: "all"
            }
        }),
        computed: {
            mailerCategory: {
                get() {
                    return this.$store.state.mailerCategory;
                }
            },
            integrationLists: {
                get() {
                    return this.$store.state.integrationLists;
                }
            }
        },
        beforeDestroy() {
            window.vEvent.stop("load-integration-list", this.load);
            window.vEvent.stop("load-integration-refresh", this.refreshLists);
        },
        mounted() {
            this.load();
            window.vEvent.listen("load-integration-list", this.load);
            window.vEvent.listen("load-integration-refresh", this.refreshLists);
            this.refreshLists('');
        },
        methods: {
            enter(element) {
                const width = getComputedStyle(element).width;

                element.style.width = width;
                element.style.position = 'absolute';
                element.style.visibility = 'hidden';
                element.style.height = 'auto';

                const height = getComputedStyle(element).height;

                element.style.width = null;
                element.style.position = null;
                element.style.visibility = null;
                element.style.height = 0;

                // Force repaint to make sure the
                // animation is triggered correctly.
                getComputedStyle(element).height;

                // Trigger the animation.
                // We use `setTimeout` because we need
                // to make sure the browser has finished
                // painting after setting the `height`
                // to `0` in the line above.
                setTimeout(() => {
                    element.style.height = height;
                });
            },
            load() {
                this.axios({
                    url: "/integrations"
                })
                .then(response => {
                    this.mailers = response.data;
                })
                .catch(error => {
                    this.$message.error(error.message);
                });
            },

            getName(mailer) {
                return this.$store.state.integrationLists[mailer].name;
            },

            getIcon(mailer) {
                return this.$store.state.integrationLists[mailer].icon;
            },

            newIntegration() {
                this.integrationDialog.step = 1;
                this.integrationDialog.show = true;
            },

            clickDisconnect(item) {
                this.disconnect = item.id;
                this.disconnectName = item.display_name;
            },

            /**
             * Disconnect Service
             * @param command
             */
            dropdownSelect(command) {
                if (command === "disconnectService") {
                    this.$store.dispatch("delete", `/integrations/${this.disconnect}`)
                    .then(response => {
                        if (response.data.result === "success") {
                            window.vEvent.fire('settings-common-success', {
                                title: 'Integration disconnected',
                                message: this.disconnectName,
                                close: false
                            });

                            this.load();
                        }
                    });
                }
            },

            refreshLists(service_key) {
                // Save Mailing Lists
                let post_data = {
                    url: `/integrations/refresh`,
                    data: {}
                };

                if (service_key !== '') {
                    post_data.data['service_key'] = service_key;
                }

                this.$store.dispatch('post', {...post_data}).then(response => {
//                    console.log(response.data);
                });
            }
        }
    };
</script>

<style lang="less" scoped>
    .settings-integration {
        position: relative;

        .integration-list-card {
            margin-top: 3rem;

            &:first-child {
                margin-top: 0;
            }

            .integration-list-row {
                padding: 2rem 0;
                border-bottom: 1px solid #e8e8ea;

                &:last-child {
                    border: 0;
                }

                .action-column {
                    min-height: 60px;
                    align-items: center;
                    display: flex;

                    .btn-circle {
                        border: 0;
                        background-color: transparent;
                        font-size: 16px;

                        &:hover {
                            background-color: #F7FCFD;
                        }
                    }

                    .el-dropdown-link {
                        color: #0BACDB;
                    }
                }

                .integration-icon {
                    height: 70px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 80px;
                        height: 60px;
                    }
                }

                .integration-description {
                    line-height: 32px;
                }

                .integration-activity {
                    line-height: 55px;
                }
            }
        }
        .mx-mob-0{
            @media (max-width: 414px){
                margin-left: 0 !important;
                margin-right: 0 !important
            }
        }
    }
    @media (max-width: 1024px) {
        .mobileMenu {
            display: block !important;
            margin-right: 10px;
        }
    }
    .mobileMenu {
        display: none;
    }

    @media (max-width: 960px) {
        .el-container,
        .is-always-shadow {
            padding: 0px !important;
        }
    }
/*
    @media (max-width: 375px) {
        .integrationButtons {
            position: relative;
            right: 15%s;
        }
    }*/
</style>
