<template>

    <div class="referral-list-component">

        <div class="payout-tables">
            <div class="header-title">
                <span>Payout Table</span>
            </div>
            <div class="table-view">
                <header class="component__header">
                    <div class="d-flex align-items-center">
                        <div class="content__col content__col-head month">
                            Month
                        </div>
                        <div class="content__col content__col-head clicks">
                            RELEASE DATE
                        </div>
                        <div class="content__col content__col-head signups">
                            MODE
                        </div>
                        <div class="content__col content__col-head upgraded text-red">
                            PAYOUT
                        </div>

                    </div>
                </header>
                <div class="component__body">
                    <div class="project__list">
                        <div class="d-flex align-items-center" v-for="(payout, index) in payouts">
                            <div class="content__col content__col-head month">
                                <div class="">
                                    {{changeDateFormate(payout.created_at, 'year')}}
                                </div>
                            </div>
                            <div class="content__col content__col-head clicks">
                                <div class="">{{changeDateFormate(payout.created_at)}}</div>
                            </div>
                            <div class="content__col content__col-head signups">
                                <div class="" v-if="payout.type == 'bank'">
                                    <span class="capital-transform">{{payout.type}} : </span> {{payout.payout_detail.account_number}}
                                </div>
                                <div class="" v-if="payout.type == 'paypal'">
                                    <span class="capital-transform"> {{payout.type}}: </span>{{payout.payout_detail.email}}
                                </div>
                                <div class="" v-if="payout.type == 'cheque'">
                                    <span class="capital-transform"> {{payout.type}}: </span>{{payout.payout_detail.full_name}}
                                </div>
                            </div>
                            <div class="content__col content__col-head upgraded text-red">
                                <div class="">${{payout.amount}}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    export default {
        name: "Payout",
        components: {},
        props: {
            user: Object,
            payouts: Object,
        },
        data: () => ({


        }),
        mounted() {
            // this.getLinkss()
        },
        methods: {
            // changeDateFormate(date){
            //     const monthNames = ["January", "February", "March", "April", "May", "June",
            //         "July", "August", "September", "October", "November", "December"
            //     ];
            //     var parts = date.split('-');
            //     var mydate = new Date(parts[0] +'-01-'+parts[1]);
            //     console.log(date, mydate);
            //     return monthNames[mydate.getMonth()] + ', ' + parts[1]
            // },
            changeDateFormate(date, show = 'month'){
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                var part = date.split(' ');
                var parts = part[0].split('-');
                var mydate = new Date(date);
                return monthNames[mydate.getMonth()] + ', ' + (show == "year" ? parts[0] : parts[2]);
            },


        }
    };
</script>

<style lang="less">
    .referral-list-component {
        font-size: 13px;
        margin: 0 0;

        .your-referral-detail{
            .el-input-group__prepend{
                border-right: none;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
                background-color: #fff;
            }
            .el-input__inner{
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                border-left: none !important;
            }
            .el-input__inner:focus {
                outline: none;
                border-color: rgb(191, 204, 217);
            }
            .referral-user-logo{
                display: flex;
                img{
                    height: 50px;
                    border-radius: 50%;
                }
                .referral-info{
                    margin: 5px 15px;
                    .text-bold{
                        font-size: 17px;
                    }
                    .text-grey{
                        color: #788899a1;
                    }
                }
            }
        }
        .see-all-referrals{
            font-size: 11px !important;
            padding: 5px 15px !important;
        }
        .promo-state-tables{
            .component__body{
                padding: 15px 30px !important;
            }
            .content__col-head{
                min-width: 100px ;
            }
        }

        .project__list .align-items-center {
            margin: 15px 0;
        }

        .content__col-head.clicks, .content__col-head.signups, .content__col-head.upgraded,
        .content__col-head.month, .content__col-head.earnings, .content__col-head.recur, .content__col-head.clawbacks {
            text-align: center !important;
        }

        .component__body {

            padding: 15px 0 !important;

            .promo-detail {
                font-size: 11px !important;
                padding: 5px 15px !important;
            }


        }

        .link-info {
            display: flex;
            flex-direction: column;

            .referral-title {
                font-size: 16px;
            }
        }

        .component__header {
            background-color: rgba(11, 172, 219, .02);
            padding: 15px 30px;
            line-height: 15px;
            font-size: 13px;
            font-weight: 500;

        }

        .content__col-head {
            flex-basis: 400px;
            min-width: 150px
        }

        .header-title {
            margin: 30px 0;

            > span {
                font-size: 28px;
            }

            .add-new-referral {
                float: right;
            }

            .add-new-btn {
                font-size: 15px;
            }
        }

        .btn-sm {
            font-size: 10px;
        }

    }

</style>


<style lang="less">


</style>
