<template>

    <el-row :gutter="40" class="m-top-4">

        <el-col :span="24" :xs="24">

            <div class="form-group m-bottom-1">
                <label class="control-label trend-title">View Trends</label>
            </div>
            <div class="legend-dropdown align-items-center" id="legend-dropdown">
                <el-select
                        :value="checkedLegend[0]"
                        class=" custom-el-select"
                        popper-class="checkbox-select custom_select_pop with_arrow_190"
                        v-model="checkedLegend[0]"
                        @change="changeLegend(0)"
                        placem
                >
                    <el-option
                            v-for="item in command_ary"
                            :key="`check_legend_0_${item.value}`"
                            :label="item.label"
                            :value="item.value"
                            :disabled="checkedLegend[1] === item.value || checkedLegend[2] === item.value"
                    >
                        <div class="check-icon">
                            <i v-if="checkedLegend[0] === item.value" class="el-icon-check"></i>
                        </div>
                        <span class="legend-label">{{ item.label }}</span>
                    </el-option>
                </el-select>
                <el-select
                        :value="checkedLegend[1]"
                        class=" custom-el-select"
                        popper-class="checkbox-select custom_select_pop with_arrow_190"
                        v-model="checkedLegend[1]"
                        @change="changeLegend(1)"
                >
                    <el-option
                            v-for="item in command_ary"
                            :key="`check_legend_1_${item.value}`"
                            :label="item.label"
                            :value="item.value"
                            :disabled="checkedLegend[0] === item.value || checkedLegend[2] === item.value"
                    >
                        <div class="check-icon">
                            <i v-if="checkedLegend[1] === item.value" class="el-icon-check"></i>
                        </div>
                        <span class="legend-label">{{ item.label }}</span>
                    </el-option>
                </el-select>

            </div>
            <div class="trend-chart-area">
                <line-chart :chart-data="chartData" :options="options"></line-chart>
            </div>

        </el-col>
    </el-row>



</template>

<script>

    import LineChart from "../../../utils/Chart/LineChart";
    import { customTooltip } from "../../../utils/Chart/Tooltip";
    // import CustomDateRangePicker from "../../Contacts/Items/CustomDateRangePicker";
    // const cur_date = new Date();


    export default {
        name: "ViewTrend",
        components: {LineChart },
        props: {
            trendData: Object
        },
        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false,
                onResize: (chart)=>{
                    if(window.innerWidth <= 414){
                        chart.scales['x-axis-0'].options.ticks.maxTicksLimit = 2
                    }
                },
                events: ["mousemove"], // this is needed, otherwise onHover is not fired
                onHover: (event, chartElement) => {
                    event.target.style.cursor = chartElement[0]
                        ? "pointer"
                        : "default";
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                    mode: "point",
                    intersect: true,
                    custom: customTooltip
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                drawTicks: false,
                                color: "#EAF0F4",
                                zeroLineColor: "#EAF0F4"
                            },
                            ticks: {
                                beginAtZero: true,
                                padding: 10
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                drawTicks: false,
                                color: "transparent"
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 7,
                                lineHeight: "normal",
                                maxRotation: 0,
                                padding: 10
                            }
                        }
                    ]
                }
            },
            chartData: {
                labels: [],
                datasets: []
            },
            // trendData:[],
            command_ary: [
                { value: 0, label: "clisks", color: "#0072A5" },
                { value: 1, label: "signups", color: "#56D9FE" },
                { value: 2, label: "upgraded", color: "#00d34b" },
                { value: 3, label: "earnings", color: "#AB7DF6" },
                { value: 4, label: "clawbacks", color: "#ae2e91" },
            ],
            checkedLegend: [0, 1]

        }),
        created(){
            // this.fillData('Clicks','Sign Ups', this.trendData.trend.clicks, this.trendData.trend.signups)
            console.log(this.trendData.trend.clicks, this.trendData.trend.signups, 'lk');
            // this.getTrendData();
        },
        methods: {
//             changeLegend(flag) {
//                 let checkedLeg = this.checkedLegend[flag];
//                 let sel_item = this.command_ary[checkedLeg];
//                 if (document.querySelector("#legend-dropdown")) {
//                     let input_div = document.querySelectorAll(
//                         `#legend-dropdown .custom-el-select`
//                     )[flag].childNodes;
//                     // $(this).parents('.legend-dropdown')
//
// var data = [];
//                     this.command_ary.forEach((ele, index) => {
//                         if(this.checkedLegend.includes(index)){
//                             data.push(ele.label)
//                         }
//                         // var clicks = this.trendData.trend.clicks;
//                     })
//
//                     if(data[0] == 'clicks'){
//                         var label1 = 'Clicks';
//                         var data1 = this.trendData.trend.clicks;
//
//                     }
//                 if(data[0] == 'signups'){
//                         var label1 = 'Sign Ups';
//                         var data1 = this.trendData.trend.signups;
//
//                     }
//                 if(data[0] == 'upgraded'){
//                         var label1 = 'Upgraded';
//                         var data1 = this.trendData.trend.upgraded;
//
//                     }
//                 if(data[0] == 'earnings'){
//                         var label1 = 'Earnings';
//                         var data1 = this.trendData.trend.earnings;
//
//                     }
//                 if(data[0] == 'clawbacks'){
//                         var label2 = 'Clawbacks';
//                         var data2 = this.trendData.trend.clawbacks;
//
//                     }
//
//                 if(data[1] == 'clicks'){
//                         var label2 = 'Clicks';
//                         var data2 = this.trendData.trend.clicks;
//
//                     }
//                 if(data[1] == 'signups'){
//                         var label2 = 'Sign Ups';
//                         var data2 = this.trendData.trend.signups;
//
//                     }
//                 if(data[1] == 'upgraded'){
//                         var label2 = 'Upgraded';
//                         var data2 = this.trendData.trend.upgraded;
//
//                     }
//                 if(data[1] == 'earnings'){
//                         var label2 = 'Earnings';
//                         var data2 = this.trendData.trend.earnings;
//
//                     }
//                 if(data[1] == 'clawbacks'){
//                         var label2 = 'Clawbacks';
//                         var data2 = this.trendData.trend.clawbacks;
//
//                     }
//
//
//
//
//                     this.fillData(label1, label2, data1, data2);
//                     // console.log(data, this.checkedLegend, sel_item, 'sel_item');
//
//
//                 }
//
//
//                 // window.vEvent.fire("change-engagement-chart", this.checkedLegend);
//             },
            // getTrendData(type){
            //     var $this = this;
            //     this.axios({
            //         method: 'post',
            //         url: '/trend-data',
            //         // data: {type: type}
            //     }).then(function (response) {
            //         $this.trendData = response.data.trends
            //         $this.fillData('Clicks','Sign Ups', $this.trendData.trend.clicks, $this.trendData.trend.signups);
            //     })
            // },

            // fillData(label1, label2, data1, data2) {
            //     console.log(label1, label2, data1, data2);
            //     if (document.querySelector('#line-chart')) {
            //         let gradient = document.querySelector('#line-chart').getContext("2d").createLinearGradient(0, 0, 0, 300);
            //         gradient.addColorStop(0, 'rgba(90, 45, 219, 1)');
            //         gradient.addColorStop(0.5, 'rgba(90, 45, 219, 0.5)');
            //         gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');
            //
            //         let gradient1 = document.querySelector('#line-chart').getContext("2d").createLinearGradient(0, 0, 0, 300);
            //         gradient1.addColorStop(0, 'rgba(86, 217, 255, 1)');
            //         gradient1.addColorStop(0.5, 'rgba(86, 217, 255, 0.5)');
            //         gradient1.addColorStop(1, 'rgba(255, 255, 255, 0.8)');
            //
            //         this.chartData = {
            //             labels: this.trendData.trend.xLabel,
            //             datasets: [
            //                 {
            //                     label: label1,
            //                     backgroundColor: gradient,
            //                     borderColor: 'rgba(90, 45, 219, 0.7)',
            //                     data: data1,
            //                     lineTension: 0.4,
            //                     fill: false,
            //                     pointHoverRadius: 3,
            //                     pointHoverBackgroundColor: 'rgba(90, 45, 219, 0.7)'
            //                 }, {
            //                     label: label2,
            //                     backgroundColor: gradient1,
            //                     borderColor: 'rgba(86, 217, 255, 0.7)',
            //                     data: data2,
            //                     lineTension: 0.4,
            //                     fill: false,
            //                     pointHoverRadius: 3,
            //                     pointHoverBackgroundColor: 'rgba(86, 217, 255, 0.7)'
            //                 }
            //             ]
            //         }
            //     }
            // },
        },





    };
</script>


<style lang="less">
.condition-content{
    .border-content{
        border: none !important;
    }
    text-align: right;
}
    .trend-title{
        font-size: 15px;
        padding: 20px;
        border-bottom: 1px solid #eaf0f4;
        display: block;
    }
    .legend-dropdown{display: none}

</style>

<style lang="less" scoped src="../../../assets/less/custom/common.less"></style>
<style lang="less" src="../../../assets/less/custom/pages.less"></style>
<style lang="less" src="../../../assets/less/custom/settings.less"></style>
<style lang="less" src="../../../assets/less/custom/analytics.less"></style>
