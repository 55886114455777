<template>
  <div>
    <filter-option
      v-for="item in options"
      :key="'filter_by' + item.id"
      :item="item"
      :type="item.type"
      :title="item.title"
      :checked="selectedOptions.includes(item.id)"
      :on-check="onSelectOption"
      :get-data="getData(item)"
      :on-change="onChangeOption(item)"
      for-list="projects"
    />
  </div>
</template>
<script>
import axios from 'axios';
import { find } from 'lodash';
import FilterOption from '../../UI/FilterOption.vue';

import { PROJECT_VIDEO_FILTERS, FILTER_TYPES, USER_ROLES } from '../../../constants';

import { GET_PLANS_API, GET_LOCATION_API } from '../../../../config/endpoints';

const getPlans = () =>
  axios.get(GET_PLANS_API).then(({ data }) =>
    data.map(({ id, name, interval }) => ({
      value: id,
      title: `${name} (${interval})`
    }))
  );

const getLocation = type =>
  axios.get(`${GET_LOCATION_API}/${type}`).then(({ data }) =>
    data.map(item => ({
      title: item,
      value: item
    }))
  );

const getUserRoles = () => new Promise(resolve => resolve(USER_ROLES));

export default {
  name: 'FilterOptionItems',
  components: {
    FilterOption
  },
  data: () => ({
    options: PROJECT_VIDEO_FILTERS,
    selectedOptions: [],
    filter: {}
  }),
  methods: {
    getData(item) {
      switch (true) {
        case PROJECT_VIDEO_FILTERS[0].id == item.id:
          return getPlans;
        case PROJECT_VIDEO_FILTERS[1].id == item.id:
          return getUserRoles;
        default:
          return () => {};
      }
    },
    onSelectOption(item) {

      const index = this.selectedOptions.indexOf(item.id);

      if (index !== -1) {
        const { title } = find(PROJECT_VIDEO_FILTERS, {
          id: this.selectedOptions[index]
        });
        this.filter = Object.keys(this.filter)
          .filter(prop => prop !== title.toLowerCase())
          .reduce(
            (acc, prop) => ({
              ...acc,
              [prop]: this.filter[prop]
            }),
            {}
          );
        this.selectedOptions = this.selectedOptions.filter(
          id => id !== item.id
        );
        this.$emit('changeFilter', this.filter);

        return;
      }

      this.selectedOptions = [...this.selectedOptions, item.id];
    },
    onChangeOption(item) {
      return (title, value) => {
        const { filter } = this;
        this.filter = {
          ...filter,
          [title]: value
        };
        this.$emit('changeFilter', this.filter);
      };
    }
  }
};
</script>
<style lang="less"></style>
