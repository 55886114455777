<template>
    <svg
        id="Group_10998"
        data-name="Group 10998"
        xmlns="http://www.w3.org/2000/svg"
        width="18.088"
        height="18.088"
        viewBox="0 0 18.088 18.088"
    >
        <circle
            id="Ellipse_4260"
            data-name="Ellipse 4260"
            class="cls-1"
            cx="9.044"
            cy="9.044"
            r="9.044"
        />
        <g id="Group_8569" data-name="Group 8569" transform="translate(10.103 13.812) rotate(180)">
            <circle
                id="Ellipse_4259"
                data-name="Ellipse 4259"
                class="cls-2"
                cx="0.994"
                cy="0.994"
                r="0.994"
                transform="translate(0)"
            />
            <path
                id="Path_8229"
                data-name="Path 8229"
                class="cls-2"
                d="M.595,0A.6.6,0,0,0,0,.595V5.648a.595.595,0,1,0,1.189,0V.595A.6.6,0,0,0,.595,0Z"
                transform="translate(0.435 3.61)"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: "RedSign"
};
</script>
<style lang="less" scoped>
.cls-1 {
    fill: #ee5951;
}

.cls-2 {
    fill: #fff;
}
</style>