<template>
    <svg
        id="Group_4358"
        data-name="Group 4358"
        xmlns="http://www.w3.org/2000/svg"
        width="40.821"
        height="40.821"
        viewBox="0 0 40.821 40.821"
    >
        <rect
            id="Rectangle_1408"
            data-name="Rectangle 1408"
            width="40.821"
            height="40.821"
            rx="20.411"
            fill="#0ad688"
        />
        <g id="Good_checkmark" data-name="Good checkmark" transform="translate(9.071 11.339)">
            <g id="Group_4357" data-name="Group 4357">
                <path
                    id="Path_4291"
                    data-name="Path 4291"
                    d="M22.971,68.339a1.166,1.166,0,0,0-1.648,0L7.357,82.3,1.989,76.936A1.166,1.166,0,0,0,.341,78.585l6.192,6.192a1.166,1.166,0,0,0,1.648,0L22.971,69.987A1.166,1.166,0,0,0,22.971,68.339Z"
                    transform="translate(0 -67.998)"
                    fill="#fff"
                    stroke="#0ad688"
                    stroke-width="0.5"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "GoodCheckMark"
};
</script>

<style lang="less" scoped>
</style>

