import { render, staticRenderFns } from "./WarningMark.vue?vue&type=template&id=2aaf8914&scoped=true&"
import script from "./WarningMark.vue?vue&type=script&lang=js&"
export * from "./WarningMark.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aaf8914",
  null
  
)

export default component.exports