<template>
    <svg
        id="Group_4358"
        data-name="Group 4358"
        xmlns="http://www.w3.org/2000/svg"
        width="40.561"
        height="40.561"
        viewBox="0 0 40.561 40.561"
    >
        <rect
            id="Rectangle_1408"
            data-name="Rectangle 1408"
            width="40.561"
            height="40.561"
            rx="20.28"
            fill="#ee5951"
        />
        <g id="Warning" transform="translate(11 12)">
            <g id="Group_4364" data-name="Group 4364">
                <g id="Group_4363" data-name="Group 4363">
                    <path
                        id="Path_4293"
                        data-name="Path 4293"
                        d="M18.786,49.63,10.979,36.684a1.71,1.71,0,0,0-2.928,0L.245,49.63a1.71,1.71,0,0,0,1.464,2.593H17.321a1.71,1.71,0,0,0,1.464-2.593Zm-1.054,1.114a.471.471,0,0,1-.41.239H1.709a.471.471,0,0,1-.4-.714L9.112,37.324a.471.471,0,0,1,.806,0L17.725,50.27A.471.471,0,0,1,17.731,50.744Z"
                        transform="translate(0 -35.857)"
                        fill="#fff"
                    />
                </g>
            </g>
            <g id="Group_4366" data-name="Group 4366" transform="translate(8.407 4.148)">
                <g id="Group_4365" data-name="Group 4365" transform="translate(0 0)">
                    <path
                        id="Path_4294"
                        data-name="Path 4294"
                        d="M234.694,173.005c-.627,0-1.116.336-1.116.933,0,1.819.214,4.433.214,6.253,0,.474.413.673.9.673.367,0,.887-.2.887-.673,0-1.819.214-4.433.214-6.253A.982.982,0,0,0,234.694,173.005Z"
                        transform="translate(-233.578 -173.005)"
                        fill="#fff"
                    />
                </g>
            </g>
            <g id="Group_4368" data-name="Group 4368" transform="translate(8.357 12.328)">
                <g id="Group_4367" data-name="Group 4367" transform="translate(0 0)">
                    <path
                        id="Path_4295"
                        data-name="Path 4295"
                        d="M233.828,353.306a1.177,1.177,0,1,0,0,2.354,1.177,1.177,0,0,0,0-2.354Z"
                        transform="translate(-232.651 -353.306)"
                        fill="#fff"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "WarningMark"
};
</script>

<style lang="less" scoped>
</style>

