<template>
    <div>
        <div v-if='showRestrictionModal'>
            <BasePlanRestriction :showModal='showRestrictionModal' @close='showRestrictionModal = false'/>
        </div>
        <div class="settings-stage settings-right-area" id="settings-stage" v-if="user && !showRestrictionModal">
            <right-header-footer class="header-items m-top-3">
                <template v-slot:title>Global video settings</template>


                <template v-slot:actions>
                    <MobileMenu :isMobile='isMobile'></MobileMenu>
                    <button @click="StoreGeneralData" class="btn btn-primary m-left-2" type="button">
                        <i class="far fa-save" v-if="!isMobile"></i> Save
                    </button>
                </template>

            </right-header-footer>
            <el-container id="id_settings-stage">
                <el-card>
                    <div class="header-title">Geolocation privacy</div>

                    <div class="form-group row-section">
                        <div>
                            You can block specific countries and locations from streaming your videos. Select the countries
                            you want blocked below.
                        </div>
                    </div>
                    <geolocation-setting
                            :user="user"
                            :top-country="user.settings.country"
                            class-name="w-100"
                    ></geolocation-setting>
                    <!--                v-on:input="changedCountry"-->
                </el-card>
            </el-container>

            <el-container id="id_settings-stage" class="generalVideoSetting" style="margin-top: 100px">
                <el-card>
                    <div class="header-title">General</div>

              <div>
                <div class="form-group inner-heading">
                  Global Video Color
                </div>
                <div class="inner-head-data">
                  Temporarily store session's watch data of your viewers in their browsers so that next time they
                  view your video, they can resume watching it from where they stopped last time.


                </div>
                <div class="inner-head-data row">
                  <div class="row-space-5 col-sm-4">
                    <color-picker :control="activeLevel2" :color="user.settings.global_player_color"
                                  v-model="user.settings.global_player_color"></color-picker>

                  </div>
                </div>
              </div>
<!--                <div>-->
<!--                    <div class="form-group inner-heading">-->
<!--                        RESUME PLAY-->
<!--                    </div>-->
<!--                    <div class="inner-head-data">-->
<!--                        Temporarily store session's watch data of your viewers in their browsers so that next time they-->
<!--                        view your video, they can resume watching it from where they stopped last time.-->



<!--                        </div>-->
<!--                        <div class="inner-head-data">-->

<!--                            <label class="row-space-top-2 row-space-3 block">-->
<!--                                <el-switch active-color="#0AD688" v-model="user.settings.resume_player"-->
<!--                                        class="row-space-right-1"-->
<!--                                        active-icon-class="el-icon-check success"-->
<!--                                ></el-switch>-->
<!--                                Resume-->
<!--                            </label>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div>
                        <div class="form-group inner-heading">
                            PAUSE ON SCROLL
                        </div>
                        <div class="inner-head-data">
                            Automatically pause your videos if your viewers scroll away from the section or browser over to
                            a new tab and resume the video automatically once they return.


                        </div>
                        <div class="inner-head-data">

                            <label class="row-space-top-2 row-space-3 block">
                                <el-switch active-color="#0AD688" v-model="user.settings.pause_player"
                                        class="row-space-right-1"
                                        active-icon-class="el-icon-check success"
                                           @change="pausePlayer(user.settings.pause_player, 'pause')"
                                ></el-switch>
                                Pause
                            </label>
                        </div>
                        <div class="inner-head-data" style="height: 70px;" v-if="user.settings.pause_player">
                            <div class="row col-sm-5 mb-2">
                                <el-input v-model="message"></el-input>
                            </div>
                            <div class="col-sm-4 mb-2">
                                <button class="btn btn-primary m-left-2" type="button" v-clipboard:copy="message"
                                        v-clipboard:success="onCopy"
                                        v-clipboard:error="onError">
                                    <i class="far fa-copy"></i> Copy
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-group inner-heading">
                            STICKY PLAYER
                        </div>
                        <div class="inner-head-data">
                            On browser scroll away, minimize your active video to the right side and continue playing keeping the viewer engaged.
                        </div>
                        <div class="inner-head-data">

                            <label class="row-space-top-2 row-space-3 block">
                                <el-switch active-color="#0AD688" v-model="user.settings.sticky_player"
                                        class="row-space-right-1"
                                        active-icon-class="el-icon-check success"
                                           @change="pausePlayer(user.settings.sticky_player, 'sticky')"
                                ></el-switch>
                                Sticky
                            </label>
                        </div>
                        <div class="inner-head-data" style="height: 70px;" v-if="user.settings.sticky_player">
                            <div class="row col-sm-5 mb-2">
                                <el-input v-model="message"></el-input>
                            </div>
                            <div class="col-sm-4 mb-2">
                                <button class="btn btn-primary m-left-2" type="button" v-clipboard:copy="message"
                                        v-clipboard:success="onCopy"
                                        v-clipboard:error="onError">
                                    <i class="far fa-copy"></i> Copy
                                </button>
                            </div>
                        </div>
                    </div>
    <!--                <div>-->
    <!--                    <div class="form-group inner-heading">-->
    <!--                        DATA SAVER-->
    <!--                    </div>-->
    <!--                    <div class="inner-head-data">-->
    <!--                        You can minimise you bandwidth usage and get more plays on every cycle. Disable autoplay auto-->
    <!--                        stream on all videos in your account so that bandwidth is only consumed when user clicks the-->
    <!--                        play button and also you can force all videos in your account to a default resolution regardless-->
    <!--                        of viewer's internet speed.-->

    <!--                    </div>-->
    <!--                    <div class="inner-head-data">-->

    <!--                        <label class="row-space-top-2 row-space-3 block">-->
    <!--                            <el-switch active-color="#0AD688" v-model="user.settings.autoplay"-->
    <!--                                       class="row-space-right-1" @change="StoreGeneralData"-->
    <!--                                       active-icon-class="el-icon-check success"-->
    <!--                            ></el-switch>-->
    <!--                           Didable All Autoplay & Auto Stream Load (Warning: this may cause buffering)-->
    <!--                        </label>-->
    <!--                    </div>-->
    <!--                    <div class="inner-head-data">-->
    <!--                        Choose Default Resolution (warning: viewers can still change resolution manually)-->
    <!--                    </div>-->
    <!--                    <div class="inner-head-data">-->
    <!--                        <el-select-->
    <!--                                popper-class="custom_select_pop with_arrow_190"-->
    <!--                                placeholder="Select"-->
    <!--                                v-model="user.settings.default_resolution"  @change="StoreGeneralData">-->
    <!--                            <el-option v-for="item in [{value: '420p',label: '420p'}, {-->
    <!--                                                value: '144p',label: '144p'}, {-->
    <!--                                                value: '360p',label: '360p'}, {-->
    <!--                                                value: '720p',label: '720p'}]" :key="item.value"-->
    <!--                                       :label="item.label" :value="item.value"></el-option>-->
    <!--                        </el-select>-->
    <!--                    </div>-->
    <!--                </div>-->

                </el-card>
            </el-container>
        </div>
    </div>
</template>

<script>
    import RightHeaderFooter from "./Layout/RightHeaderFooter";
    import GeolocationSetting from './Items/GeolocationSetting';
    import MobileMenu from './Layout/MobileMenu';
    import VueClipboard from 'vue-clipboard2'
    import ColorPicker from './../Editor/ColorPicker.vue';

    export default {
        name: "SettingsPrivacy",
        components: {RightHeaderFooter, GeolocationSetting, MobileMenu, VueClipboard, ColorPicker: ColorPicker,},
        props: {
            user: Object,

        },
        data: () => ({

            isMobile: false,
            message: '',
            activeLevel2: 'global_video_color',
            showRestrictionModal: true,

        }),
        mounted(){


        },
        created(){
           this.message = "<script src='"+process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_sticky_BaseScript+"'>\<\/script>"
        },
        methods: {
            pausePlayer(val, type) {

                if (type == "sticky") {
                    if (val) {
                        this.user.settings.pause_player = false;
                    }
                }
                if (type == "pause") {
                    if (val) {
                        this.user.settings.sticky_player = false;
                    }
                }

            },
            onCopy: function (e) {
                window.vEvent.fire("settings-common-success", {
                    title: 'Copied',
                    message: e.text,
                    close: false
                });
            },
            onError: function (e) {
                window.vEvent.fire("settings-common-success", {
                    title: 'Copied',
                    message: 'Failed to copy texts',
                    close: false
                });
            },
            StoreGeneralData(){

                let post_data = {
                    data: {
                        resume_player: this.user.settings.resume_player,
                        pause_player: this.user.settings.pause_player,
                        sticky_player: this.user.settings.sticky_player,
                        autoplay: this.user.settings.autoplay,
                        default_resolution: this.user.settings.default_resolution,
                      global_player_color: this.user.settings.global_player_color,
                    },
                    url: "/settings/store-generat-setting"
                };

                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        if (response.data.result === "success") {
                            window.vEvent.fire("settings-common-success", {
                                title: 'Global video settings',
                                message: 'Successfully updated',
                                close: false
                            });
                        }
                    })
                    .catch(error => {
                    });


            }
        }
    };
</script>

<style lang="less">
    #id_settings-stage.generalVideoSetting {
        .el-card {
            padding: 0 !important;
        }

        .el-card__body {
            padding: 0;
        }

        .header-title {
            padding: 20px 40px;
        }

        .inner-heading {
            padding: 10px 40px;
            background: #f9fbfb;
        }

        .inner-head-data {
            padding: 10px 40px;
        }
    }

    .settings-stage {
        position: relative;

        .stage-logo-uploader {
            .el-upload {
                border: 1px dashed #d9d9d9;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                padding: 0;
                outline: 0;
                display: flex;
                align-items: center;
                height: 80px;
                width: 80px;

                &:hover {
                    border-color: #409eff;
                }
            }

            .stage-logo-uploader-icon {
                width: 100%;
                font-size: 80px;
                color: #ceecf5;
                height: auto;
                text-align: center;
            }

            .stage-logo {
                width: 100%;
                height: auto;
                display: block;
            }

            .el-upload__input {
                display: none;
            }

        }

        #stage_public_url {
            padding-left: 0;
            height: 35px !important;
        }

        #stage_public_url_area {
            padding-left: 1px !important;
            padding-right: 0 !important;
        }
    }

    @media (max-width: 960px) {
        .el-container,
        .is-always-shadow {
            padding: 0px !important;
        }
    }
</style>


