<template>
    <el-row>
        <el-col :span="spanLeftCol">
            <span :class="{ 'text-bold': halfCardClass }">{{rowText}}</span>
        </el-col>
        <el-col :span="2" :offset="offset">
            <span :class="{ 'amount-bold': true, 'amount-font-size': halfCardClass } ">{{amount}}</span>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "RowBillingEstimate",
    props: {
        rowText: String,
        amount: String,
        spanLeftCol: Number,
        offset: {
            type: Number,
            default: 0
        },
        halfCardClass: {
            type: Boolean,
            default: false
        }
    },
    computed: {},
    data: () => ({}),
    beforeDestroy() {},
    mounted() {},
    created() {},
    methods: {}
};
</script>

<style lang="less" scoped>
.amount-bold {
    font-weight: 500;
}

.text-bold {
    font-weight: 500;
}

.amount-font-size {
    font-size: 20px;
}
</style>

